import React, { Component } from 'react';

import { FormControl, FormGroup, FormControlLabel, Checkbox, FormLabel } from '@mui/material';

class EmployeeAddtionalCentres extends Component { 

    constructor(props) {
        super();
        this.state = {

            additional_centres: []
        }
    }

    componentDidMount = () => {

        if(this.props.user){

            var additional_centres = this.props.user.additional_centres;

            var _additional_centres = [];

            if(additional_centres.length > 0){

                for(var i in additional_centres){

                    _additional_centres.push(parseInt(additional_centres[i]));
                }
            }

            this.setState({additional_centres: _additional_centres});
        }
    }

    render () {

        return (
            <FormControl margin="normal" component="fieldset">
                                                            
                <FormLabel component="legend">Select Centre</FormLabel>
                <FormGroup aria-label="source" name="additional_centre">

                    {this.props.centers.map((_key) => {

                        return (
                            <FormControlLabel key={"remind_" + _key.id} control={<Checkbox
                                disabled={this.props.exclude_centre && this.props.exclude_centre == _key.id ? true : false}
                                value={_key.id}
                                name="additional_centres"
                                checked={(this.state.additional_centres.indexOf(_key.id) > -1) ? true : false}
                                onChange={(e) => {
                        
                                    var additional_centres = this.state.additional_centres;

                                    if(e.target.checked){
                                    
                                        if(additional_centres.indexOf(_key.id) > -1){

                                        }else{
                                        
                                            additional_centres.push(_key.id);
                                        }
                                        
                                    }else{

                                        if(additional_centres.indexOf(_key.id) > -1){

                                            additional_centres.splice(additional_centres.indexOf(_key.id), 1);
                                        }
                                        
                                    }

                                    this.setState({additional_centres: additional_centres});
                                    this.props.onUpdate(additional_centres)
                                }}
                                size="small" />} label={_key.name} />
                        )
                    })}
                </FormGroup>
            </FormControl>
        )
    }
}

export default EmployeeAddtionalCentres;