import React, { Component } from 'react';

import { Grid, Button } from '@mui/material';

import Api from 'api/Api';

import { format, subMonths } from 'date-fns'

import { VictoryChart, VictoryGroup, VictoryArea, VictoryAxis, VictoryPortal, VictoryLabel, VictoryScatter } from 'victory';

class CoversionPeriodWise extends Component { 
    constructor(props) {
        super();
        this.state = {

            loader: false,

            source_wise_data: [],
            source_wise_data_colors: [],
            no_source_wise_data: false,

            color_codes: [],

            sources: [],

            total_leads: 0,

            end_date: new Date(),
            start_date: subMonths(new Date(), 1),

            period_end_date: new Date(),
            period_start_date: subMonths(new Date(), 1),

            period_wise_data_tmp: [],
            period_wise_data: [],
            
            period_tick_values: [],

            hidden_indexes: []
        }
    }

    componentDidMount = () => {

        this.loadPeriodWise();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(
            this.props.start_date !== prevProps.start_date
            ||
            this.props.end_date !== prevProps.end_date
        ){

            this.loadPeriodWise();
        }
    }

    values = (source) => {

        let source_wise_data = this.state.source_wise_data;

        const value = source_wise_data.find(node => node.c == source.key);

        if(value){
        
            return <div><strong>{value.y}</strong> <span className="ml-3">({value.p}%)</span></div>;
        }else{

            return <div style={{height:23, lineHeight:1}}><strong style={{opacity:.4, fontSize:20}}>0</strong></div>;;
        }
    }

    render () {

        return (

            <>
                {/* <div className="box-header" style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}>

                    <div className="align-start">
                    
                        <h2 className="mr-5">Source Wise Leads</h2>

                        <DateRangeComponent
                            update={(start_date, end_date) => {

                                this.setState({start_date: start_date, end_date: end_date}, () => {

                                    this.loadData(false);
                                });
                            }}
                            start_date={this.state.start_date}
                            end_date={this.state.end_date}
                        />
                    </div>
                </div> */}

                <Grid container spacing={0}>

                    <Grid item xs={12} sm={12}>

                        <ul className="flex-start">
                            {this.props.sources.map((_source) => {

                                return (

                                    <li key={"period_data_" + _source.key} style={{marginRight:10}}>

                                        <Button className="flex align-start" onClick={() => {

                                            const period_wise_data = this.state.period_wise_data;
                                            var _tmp_data = [...period_wise_data];
                                            let hidden_indexes = this.state.hidden_indexes;

                                            const _index_pos = hidden_indexes.indexOf(_source.key);

                                            if(_index_pos > -1){

                                                hidden_indexes.splice(_index_pos, 1);
                                            }else{

                                                hidden_indexes.push(_source.key);
                                            }

                                            hidden_indexes.forEach((code) => {

                                                let _pos = _tmp_data.findIndex(data => data.code === code);

                                                _tmp_data.splice(_pos, 1);
                                            })

                                            this.setState({period_wise_data_tmp: _tmp_data, hidden_indexes: hidden_indexes});
                                        }}>
                                            <span style={{backgroundColor:_source.color, width:15, height:15, display:'block', borderRadius:30, opacity:this.state.hidden_indexes.indexOf(_source.key) > -1 ? .1 : 1}}></span>
                                            <strong className="fs-12 cg-5 ml-5" style={{opacity:this.state.hidden_indexes.indexOf(_source.key) > -1 ? .3 : 1}}>{_source.value.toUpperCase()}</strong>
                                        </Button>
                                    </li>
                                )
                            })}
                        </ul>
                    </Grid>

                    <Grid item xs={12} sm={12}>

                        <VictoryChart width={800} height={350}>

                            {this.state.period_wise_data_tmp.map((_data) => {

                                return (

                                    <VictoryGroup
                                        key={"chart_" + _data.code}
                                        data={_data.data}
                                        labelComponent={<VictoryLabel dy={30} />}
                                        style={{
                                            labels: {
                                                fontSize: 15,
                                            }
                                        }}
                                    >
                                        <VictoryArea
                                            interpolation="cardinal"
                                            style={{
                                                data: { fill: this.colorCode(_data.code), fillOpacity: '.3', padding:50 },
                                                labels: {
                                                    fontSize: 15,
                                                }
                                            }}
                                            animate={true}
                                        />
                                        <VictoryPortal>
                                            <VictoryScatter
                                                style={{ data: { fill: "black", fillOpacity:'.3' } }}
                                            />
                                        </VictoryPortal>
                                    </VictoryGroup>
                                )
                            })}

                            <VictoryAxis
                                dependentAxis 
                                style={{ 
                                    grid: {
                                        stroke: '#000',
                                        strokeDasharray: '0',
                                        fillOpacity: '.5',
                                        opacity: '.08'
                                    }
                                }}
                                axisLabelComponent={<VictoryLabel angle={90} />}
                                tickValues={this.state.period_tick_values}
                            />
                            <VictoryAxis
                                style={{ 
                                    tickLabels: { fill: "#333", fontSize:12, angle:45, offsetY:150, padding:20},
                                    grid: {
                                        stroke: '#000',
                                        strokeDasharray: '0',
                                        fillOpacity: '.3',
                                        opacity: '.08'
                                    }
                                }}
                                width={600}
                                height={300}
                                // tickCount={5}
                                // tickValues={[10, 12, 14, 16, 18, 20, 22, 24]}
                                // tickValues={[{10: '10k'}, {12: '12k'}, {14: '14k'}, {16: '16k'}, {18: '18k'}, {20: '20k'}, {22: '22k'}, {24: '24k'}]}
                                orientation="bottom"
                            />
                        </VictoryChart>

                    </Grid>
                </Grid>
            </>
        )
    }

    colorCode = (code) => {

        let sources = this.props.sources;

        const color_code = sources.find(source => source.key === code);

        if(color_code){
        
            return color_code.color;
        }

        return "#FFCE00";
    }

    loadPeriodWise = () => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('start_date', format(this.props.start_date, "yyyy-MM-dd"));
        formData.append('end_date', format(this.props.end_date, "yyyy-MM-dd"));

        this.setState({loader: true});

        Api.post('reports/conversion_period_wise', formData, function(data){

            that.setState({loader: false});

            if(data.status){
            
                that.setState({period_tick_values: data.tick_values});

                if(data.final_data.length <= 0){

                    that.setState({no_source_wise_data: true, period_wise_data: [], period_wise_data_tmp: []});
                }else{

                    that.setState({no_source_wise_data: false, period_wise_data: data.final_data, period_wise_data_tmp: data.final_data});
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default CoversionPeriodWise;
