import React, { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";

import { FormControl, TextField, Button } from '@mui/material';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Loader from 'components/Loader';
import Api from 'api/Api';

import logo from 'assets/images/logo.png';

function Signin (){

    let _timer;

    let otp_one = React.createRef();
    let otp_two = React.createRef();
    let otp_three = React.createRef();
    let otp_four = React.createRef();
    let otp_five = React.createRef();
    let otp_six = React.createRef();

    const [mobile_number, setMobileNumber] = useState('');
    const [show_otp_inputs, setShowOtpInputs] = useState(false);

    const [otp_text_1, setOtpTextOne] = useState('');
    const [otp_text_2, setOtpTextTwo] = useState('');
    const [otp_text_3, setOtpTextThree] = useState('');
    const [otp_text_4, setOtpTextFour] = useState('');
    const [otp_text_5, setOtpTextFive] = useState('');
    const [otp_text_6, setOtpTextSix] = useState('');

    const [login_option, setLoginOption] = useState('email');

    const [can_resend, setCanResend] = useState(false);
    const [resend_otp_counter, setResendOtpCounter] = useState('');
    const [timer, setTimer] = useState(60);
    const [otp_error, setOtpError] = useState(false);

    const [login, setLogin] = useState(0);
    const [loading, setLoading] = useState(false);
    
    const [error_message, setErrorMessage] = useState('');
    const [success_message, setSuccessMessage] = useState('');

    const [login_type, setLoginType] = useState('');

    useEffect(() => {
        
        var account_token = localStorage.getItem('account_token');
        
        if(account_token){
            
            setLogin(true);
        }

        return () => {

            if(_timer !== false){
            
                clearInterval(_timer);
            }
        }
    }, [])

    const countDownTimer = () => {

        var sec = timer;
        var _sec = 0;

        _timer = window.setInterval(() => {

            if(sec == 0){

                clearInterval(_timer);
                setCanResend(true);
                setTimer(60);
            }else{

                _sec = (--sec % 60);

                var _total_time = _sec + 's';

                setResendOtpCounter(_total_time);
                setTimer(_sec);
            }    

        }, 1000);
    }

    const validationSchema = yup.object({
        username: yup.string().required(),
        password: yup.string().min(6).required(),
    });

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            var formData = new FormData();
            Object.keys(values).forEach(key => formData.append(key, values[key]))
            
            setLoading(true);

            Api.post('auth/login', formData, function(data){

                if(data.status == true){
                
                    localStorage.setItem('account_token', data.account_token);
                    localStorage.setItem('user', JSON.stringify(data.user));

                    setLogin(true);

                }else{

                    setLoading(false);
                    setErrorMessage(data.message);

                    if(data.action && data.action === 'login_with_otp'){

                        setLoginOption('mobile')
                    }
                }
            });
        },
    });

    const sendOtpValidationSchema = yup.object({
        mobile_number: yup.number().min(10).required(),
    });

    const sendOtp_formik = useFormik({
        initialValues: {
            mobile_number: '',
        },
        validationSchema: sendOtpValidationSchema,
        onSubmit: (values) => {

            setMobileNumber(values['mobile_number'])

            var formData = new FormData();
            Object.keys(values).forEach(key => formData.append(key, values[key]))
            
            setLoading(true);

            Api.post('auth/send_otp', formData, function(data){

                if(data.status){
                
                    setLoading(false);
                    countDownTimer();
                    setShowOtpInputs(true)
                    setSuccessMessage(data.message)

                }else{

                    setLoading(false);
                    setErrorMessage(data.message);
                }
            });
        },
    });

    const resendOtp = () => {
        
        var formData = new FormData();

        formData.append('mobile_number', mobile_number);
        formData.append('type', 'resend');
        
        setLoading(true);
        emptyOtps();
        
        Api.post('auth/send_otp', formData, function(data){

            if(data.status){

                setTimer(60);

                window.setTimeout(() => {

                    countDownTimer();
                    setLoading(false);
                    setCanResend(false);

                    setSuccessMessage(data.message)
                }, 200);

            }else{

                setLoading(false);
                setErrorMessage(data.message);
            }
        });
    }
    
    const handleCloseModal = () => {
        emptyOtps();

        setShowOtpInputs(false);
    };

    const emptyOtps = () => {

        setOtpTextOne('');
        setOtpTextTwo('');
        setOtpTextThree('');
        setOtpTextFour('');
        setOtpTextFive('');
        setOtpTextSix('');
    };

    const verifyOtp = (otp) => {
        
        if(otp != ''){
            setOtpError(false);

            var formData = new FormData();

            formData.append('mobile_number', mobile_number);
            formData.append('otp', otp);
            
            setLoading(true);

            setSuccessMessage('');
            
            Api.post('auth/verify_otp', formData, function(data){

                if(data.status){
                
                    localStorage.setItem('account_token', data.account_token);
                    localStorage.setItem('user', JSON.stringify(data.user));

                    setLogin(true);

                }else{

                    setLoading(false);
                    setErrorMessage(data.message);
                    emptyOtps();
                }
            });
        }else{
            setOtpError(true);
        }
    };

    if(login){

        return <Navigate to='/dashboard' />;
    }

    const otp_inputs = () => {

        return (

            <>
                <div className="otp-inputs space-evenly mb-30">
                                    
                    <input ref={otp_one} type="text" className="otp-input" value={otp_text_1} pattern="[0-9]*" onChange={(e) => {

                        const num = e.target.value;

                        if(isNaN(num)){

                            setOtpTextOne('');
                        }else{

                            if(num.length > 1){

                                setOtpTextOne('');
                            }else{
                            
                                setOtpTextOne(num);
                                otp_two.current.focus();
                            }
                        }
                    }} />

                    <input ref={otp_two} type="text" className="otp-input" value={otp_text_2} pattern="[0-9]*" onChange={(e) => {

                        const num = e.target.value;

                        if(isNaN(num)){

                            setOtpTextTwo('');
                        }else{

                            if(num.length > 1){

                                setOtpTextTwo('');
                            }else{
                            
                                setOtpTextTwo(num);
                                otp_three.current.focus();
                            }
                        }
                    }} />

                    <input ref={otp_three} type="text" className="otp-input" value={otp_text_3} pattern="[0-9]*" onChange={(e) => {

                        const num = e.target.value;

                        if(isNaN(num)){

                            setOtpTextThree('');
                        }else{

                            if(num.length > 1){

                                setOtpTextThree('');
                            }else{
                            
                                setOtpTextThree(num);
                                otp_four.current.focus();
                            }
                        }
                    }} />

                    <input ref={otp_four} type="text" className="otp-input" value={otp_text_4} pattern="[0-9]*" onChange={(e) => {

                        const num = e.target.value;

                        if(isNaN(num)){

                            setOtpTextFour('');
                        }else{

                            if(num.length > 1){

                                setOtpTextFour('');
                            }else{
                            
                                setOtpTextFour(num);
                                otp_five.current.focus();
                            }
                        }
                    }} />

                    <input ref={otp_five} type="text" className="otp-input" value={otp_text_5} pattern="[0-9]*" onChange={(e) => {

                        const num = e.target.value;

                        if(isNaN(num)){

                            setOtpTextFive('');
                        }else{

                            if(num.length > 1){

                                setOtpTextFive('');
                            }else{
                            
                                setOtpTextFive(num);
                                otp_six.current.focus();
                            }
                        }
                    }} />

                    <input ref={otp_six} type="text" className="otp-input" value={otp_text_6} pattern="[0-9]*" onChange={(e) => {

                        const num = e.target.value;

                        if(isNaN(num)){

                            setOtpTextSix('');
                        }else{

                            if(num.length > 1){

                                setOtpTextSix('');
                            }else{
                            
                                setOtpTextSix(num);
                                otp_six.current.blur();
                            }
                        }
                    }} />
                </div>

                {otp_error
                    ?
                        <div className="mb-10">
                            <span className="fs-14 red-txt">Please enter valid otp</span>
                        </div>
                    :
                        null
                }

                <div className="space-between">
                    
                    {can_resend
                        ?
                            <Button color="secondary" onClick={() => {

                                resendOtp()
                            }}>
                                <span className="c_b fw-7 fs-12 p-20">Resend OTP</span>
                            </Button>
                        :
                            resend_otp_counter != ''
                                ?
                                    <span className="fs-15 c_p fw-7">Resend OTP in <span className="fs-15 fw-7 c_b op-7">{resend_otp_counter}</span></span>
                                :
                                    <span></span>

                    }

                    <Button type="submit" variant="contained" color="primary" onClick={() => {

                        if(otp_text_1 == '' || otp_text_2 == '' || otp_text_3 == '' || otp_text_4 == '' || otp_text_5 == '' || otp_text_6 == ''){
                            setOtpError(true);
                        }else{
                            var otp = otp_text_1 + '' + otp_text_2 + '' + otp_text_3 + '' + otp_text_4 + '' + otp_text_5 + '' + otp_text_6;
                            setOtpError(false);
                            verifyOtp(otp);
                        }
                    }}>Verify</Button>
                </div>
            </>
        )
    }

    return (

        <>
            <div page="login" className="login-main-page">
                <div className="container">
                    <div className="login-page d-flex items-end">
                        <div className="right half-container self-right auth-block" style={{position:'relative'}}>

                            {error_message != '' &&

                                <Alert className="mb-30" severity="error" onClose={() => {setErrorMessage('')}}>
                                    <AlertTitle>Error</AlertTitle>
                                    {error_message}
                                </Alert>
                            }

                            {success_message != '' &&

                                <Alert className="mb-30" severity="success" onClose={() => {setSuccessMessage('')}}>
                                    <AlertTitle>Success</AlertTitle>
                                    {success_message}
                                </Alert>
                            }

                            <img src={logo} className="mb-30" />

                            {/* <div className='flex' style={{width: '100%'}}>
                                <div>
                                    <FormControl margin="normal" component="fieldset" error={false}>
                                                        
                                        <FormLabel component="legend" style={{fontSize: 16, fontWeight: 'bold'}}>Login As</FormLabel>
                                        <RadioGroup row aria-label="status" name="status" value={login_type} onChange={(e) => {

                                            setLoginType(e.target.value)
                                        }}>

                                            <FormControlLabel value="admin" control={<Radio size="large" />} label="Admin" />
                                            <FormControlLabel value="user" control={<Radio size="large" />} label="User" />
                                        </RadioGroup>
                                    </FormControl>

                                    {login_type === '' &&
                                    
                                        <div style={{color: 'rgba(0,0,0,.6)'}}>Please choose one option to login.</div>
                                    }
                                </div>
                            </div> */}

                            {/* {login_type === 'admin' &&
                            
                                <>
                                    {show_otp_inputs

                                        ?
                                            <div>

                                                <div style={{marginBottom:10}}>Enter OTP here</div>
                                            
                                                {otp_inputs()}
                                            </div>
                                        :

                                            <form className="form" name="send_otp_form" onSubmit={sendOtp_formik.handleSubmit}>
                                                
                                                <FormControl className="form-control">
                                                    <TextField error={sendOtp_formik.touched.mobile_number && Boolean(sendOtp_formik.errors.mobile_number)} value={sendOtp_formik.values.mobile_number} name="mobile_number" id="outlined-mobile_number" label="Mobile Number" variant="outlined" helperText={sendOtp_formik.touched.mobile_number && sendOtp_formik.errors.mobile_number} onChange={sendOtp_formik.handleChange} />
                                                </FormControl>

                                                <Button className="btn btn-red" type="submit" variant="contained">Send OTP</Button>
                                            </form>
                                    }
                                </>
                            } */}

                            {/* {login_type === 'user' &&

                                <> */}

                                    {login_option === 'mobile' &&

                                        <>

                                            {show_otp_inputs

                                                ?
                                                    <div>

                                                        <div style={{marginBottom:10}}>Enter OTP here</div>
                                                    
                                                        {otp_inputs()}
                                                    </div>
                                                :

                                                    <form className="form" name="send_otp_form" onSubmit={sendOtp_formik.handleSubmit}>
                                                        
                                                        <FormControl className="form-control">
                                                            <TextField error={sendOtp_formik.touched.mobile_number && Boolean(sendOtp_formik.errors.mobile_number)} value={sendOtp_formik.values.mobile_number} name="mobile_number" id="outlined-mobile_number" label="Mobile Number" variant="outlined" helperText={sendOtp_formik.touched.mobile_number && sendOtp_formik.errors.mobile_number} onChange={sendOtp_formik.handleChange} />
                                                        </FormControl>

                                                        <Button className="btn btn-red" type="submit" variant="contained">Send OTP</Button>

                                                        <div className="mb-20 fw-6 text-center">OR</div>

                                                        <Button color="primary" onClick={() => {

                                                            setErrorMessage('');
                                                            setLoginOption('email');
                                                            emptyOtps();
                                                            sendOtp_formik.resetForm()

                                                        }}>Login with Email</Button>
                                                    </form>
                                            }
                                        </>
                                    }

                                    {login_option === 'email' &&

                                        <form className="form" name="login_form" onSubmit={formik.handleSubmit}>
                                            
                                            <FormControl className="form-control">
                                                <TextField error={formik.touched.username && Boolean(formik.errors.username)} value={formik.values.username} name="username" id="outlined-username" label="Username" variant="outlined" helperText={formik.touched.username && formik.errors.username} onChange={formik.handleChange} />
                                            </FormControl>

                                            <FormControl className="form-control">
                                                <TextField name="password" id="outlined-password" label="Password" type="password" variant="outlined" error={formik.touched.password && Boolean(formik.errors.password)} value={formik.values.password} helperText={formik.touched.password && formik.errors.password} onChange={formik.handleChange} />
                                            </FormControl>
                                            
                                            {/* <FormControl className="form-control">
                                                <FormGroup className="d-flex remember-forgot">
                                                    
                                                    <Link to="/forgot-password">Forgot Password</Link>
                                                </FormGroup>
                                            </FormControl> */}

                                            <Button className="btn btn-red" type="submit" variant="contained">Login</Button>

                                            <div className="mb-20 fw-6 text-center">OR</div>

                                            <Button color="primary" onClick={() => {

                                                setErrorMessage('');
                                                setLoginOption('mobile');
                                                formik.resetForm()
                                            }}>Login with OTP</Button>
                                        </form>
                                    }
                                {/* </>
                            } */}

                            <Loader loading={loading} />
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signin;