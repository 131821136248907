import React, { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";

import { Grid, FormLabel, FormControl, RadioGroup, Radio, IconButton, TextField, FormHelperText, FormControlLabel, Button, Select, InputLabel, MenuItem, Drawer, Switch } from '@mui/material';

import { Clear } from '@mui/icons-material';

import { useFormik, Field } from 'formik';
import * as yup from 'yup';

import Loader from 'components/Loader';
import Api from 'api/Api';

import EmployeeAddtionalCentres from './EmployeeAddtionalCentres';

function CreateEmployee (props){ 

    const [roles, setRoles] = useState([]);
    const [centers, setCenters] = useState([]);
    const [agents, setAgents] = useState([]);
    
    const [id, setId] = useState(false);
    const [user, setUser] = useState(false);

    const [data_loaded, setDataLoaded] = useState(false);

    const [login, setLogin] = useState(0);
    const [account_token, setAccountToken] = useState('');

    const [loading, setLoading] = useState(false);
    const [lead_created, setLeadCreated] = useState(false);

    const [error_message, setErrorMessage] = useState('');
    const [success_message, setSuccessMessage] = useState('');

    const [back, setBack] = useState(false);

    const [additionalCentres, setAdditionalCentres] = useState(false);
    const [selectedAdditionalCentres, setSelectedAdditionalCentres] = useState([]);

    useEffect(() => {
        
        var account_token = localStorage.getItem('account_token');
        var id = props.employee_id;

        if(account_token){
            
            setAccountToken(account_token);

            if(!data_loaded){
            
                setDataLoaded(true);
                
                loadData(account_token);

                if(id){
                
                    loadEmployee(id, account_token);
                }
            }
        }
    });

    if(!user){
    
        var validationSchema = yup.object({
            first_name: yup.string().required('First name is required!'),
            last_name: yup.string().required('Last name is required!'),
            username: yup.string().required('Username is required!'),
            contact_number: yup.number('Enter valid mobile number').min(1000000000, 'Enter valid mobile number!').required('Mobile number is required!'),
            useremail: yup.string().email('Enter valid useremail address').required('useremail is required'),
            password: yup.string().required('Password is required!').min(6),
            job_profile: yup.string().required('Job Profile is required!'),
            status: yup.string().required()
        });
    }else{

        var validationSchema = yup.object({
            first_name: yup.string().required('First name is required!'),
            last_name: yup.string().required('Last name is required!'),
            contact_number: yup.number('Enter valid mobile number').min(1000000000, 'Enter valid mobile number!').required('Mobile number is required!'),
            job_profile: yup.string().required('Job Profile is required!'),
            status: yup.string().required()
        });
    }

    const formik = useFormik({
        initialValues: {
            first_name: user ? user.first_name : '',
            last_name: user ? user.last_name : '',
            contact_number: user ? user.contact_number : '',
            useremail: user ? user.useremail : '',
            username: user ? user.username : '',
            password: '',
            job_profile: user ? user.job_profile : '',
            exam_centre_id: user ? user.exam_centre_id : '',
            knowlarity_agent: user ? user.knowlarity_agent : '',
            status: user ? user.status : '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            var formData = new FormData();

            Object.keys(values).forEach(key => formData.append(key, values[key]))
            formData.append('account_token', account_token);

            if(props.employee_id){

                formData.append('id', props.employee_id);
            }

            if(selectedAdditionalCentres.length > 0){

                formData.append('additional_centres', selectedAdditionalCentres);
            }

            setLoading(true);

            Api.post('users/create', formData, function(data){

                if(data.status == true){
                
                    setSuccessMessage(data.message);
                    setLoading(false)
                    props.onSuccess(data.message);
                    props.close();
                    
                }else{

                    setLoading(false);

                    if(data.code && data.code == 'login_error'){

                        window.location = Api.server_url + 'logout';
                    }else{

                        setErrorMessage(data.message);
                        props.onError(data.message)
                    }
                    
                }
            });
        },
    });

    if(back){

        return <Navigate to="/employees" />
    }

    return (

        <Drawer
            anchor="right"
            variant="temporary"
            open={props.open}
        >

            <div className="drawer-container">
                <div className="bg-primary drawer-heading">
                    <h2>{user ? "Update - " + user.first_name + ' ' + user.last_name : ''}</h2>
                    <IconButton size="small" onClick={() => {
                        
                        props.close()
                    }}>
                        <Clear style={{color:'rgba(0,0,0,.5)'}} />
                    </IconButton>
                </div>

                <div className="drawer-body">

                    <div className="drawer-content">
                        <div className="center-form">

                            <form className="form" name="login_form" onSubmit={formik.handleSubmit}>

                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true} error={formik.touched.job_profile && Boolean(formik.errors.job_profile)}>
                                                    
                                            <InputLabel id="job_profile_field">Role *</InputLabel>
                                            <Select
                                                name="job_profile"
                                                error={formik.touched.job_profile && Boolean(formik.errors.job_profile)}
                                                labelId="job_profile_field"
                                                value={formik.values.job_profile}
                                                onChange={formik.handleChange}
                                            >
                                                <MenuItem value=''>Select Role</MenuItem>
                                                {roles && roles.length > 0
                                                    ?
                                                        roles.map((_role) => {

                                                            return <MenuItem key={_role.id} value={_role.role_key}>{_role.role_title}</MenuItem>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                                value={formik.values.first_name}
                                                name="first_name"
                                                label="First Name *"
                                                helperText={formik.touched.first_name && formik.errors.first_name}
                                                onChange={formik.handleChange}
                                                fullWidth={true}
                                                autoComplete="off"
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth
                                                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                                value={formik.values.last_name}
                                                name="last_name"
                                                label="Last Name *"
                                                helperText={formik.touched.last_name && formik.errors.last_name}
                                                onChange={formik.handleChange}
                                                autoComplete="off"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true}>

                                            {user
                                                ?
                                                    <div className="input-view">
                                                        <InputLabel>Username</InputLabel>
                                                        <span>{user ? user.username : ''}</span>
                                                    </div>
                                                :
                                                    <TextField
                                                        fullWidth
                                                        error={formik.touched.username && Boolean(formik.errors.username)}
                                                        value={formik.values.username}
                                                        name="username"
                                                        label="Username *"
                                                        helperText={formik.touched.username && formik.errors.username}
                                                        onChange={formik.handleChange}
                                                        autoComplete="off"
                                                    />
                                            }
                                        </FormControl>
                                    </Grid>

                                    {!user &&
                                        <Grid item xs={12} sm={6}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    type="password"
                                                    fullWidth
                                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                                    value={formik.values.password}
                                                    name="password"
                                                    label="Password *"
                                                    helperText={formik.touched.password && formik.errors.password}
                                                    onChange={formik.handleChange}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth
                                                error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                                                value={formik.values.contact_number}
                                                name="contact_number"
                                                label="Mobile Number *"
                                                helperText={formik.touched.contact_number && formik.errors.contact_number}
                                                onChange={formik.handleChange}
                                                autoComplete="off"
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true}>

                                            {user
                                                ?
                                                    <div className="input-view">
                                                        <InputLabel>Email</InputLabel>
                                                        <span>{user ? user.useremail : ''}</span>
                                                    </div>
                                                :
                                                    <TextField
                                                        fullWidth
                                                        error={formik.touched.useremail && Boolean(formik.errors.useremail)}
                                                        value={formik.values.useremail}
                                                        name="useremail"
                                                        label="Email *"
                                                        helperText={formik.touched.useremail && formik.errors.useremail}
                                                        onChange={formik.handleChange}
                                                        autoComplete="off"
                                                    />
                                            }
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true} error={formik.touched.exam_centre_id && Boolean(formik.errors.exam_centre_id)}>
                                                    
                                            <InputLabel id="exam_centre_id_field">Exam Center</InputLabel>
                                            <Select
                                                name="exam_centre_id"
                                                error={formik.touched.exam_centre_id && Boolean(formik.errors.exam_centre_id)}
                                                labelId="exam_centre_id_field"
                                                value={formik.values.exam_centre_id}
                                                onChange={formik.handleChange}
                                            >
                                                <MenuItem value=''>Select Center</MenuItem>
                                                {centers && centers.length > 0
                                                    ?
                                                    centers.map((_center) => {

                                                            return <MenuItem key={_center.id} value={_center.id}>{_center.name}</MenuItem>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {formik.values.job_profile == 'center_admin'
                                    ?
                                        <>

                                            <Grid container spacing={5}>

                                                <Grid item xs={12} sm={12}>

                                                    <FormControlLabel control={<Switch color="primary" checked={additionalCentres} onChange={(e) => {

                                                        setAdditionalCentres(e.target.checked);
                                                    }} name="assign_additional_centres" />} label="Assign Additional Centres" />
                                                </Grid>
                                            </Grid>

                                            {additionalCentres &&
                                                <Grid container spacing={0}>

                                                    <Grid item xs={12} sm={12}>

                                                        <EmployeeAddtionalCentres
                                                            user={user}
                                                            centers={centers}
                                                            selectedAdditionalCentres={selectedAdditionalCentres}
                                                            exclude_centre={formik.values.exam_centre_id}
                                                            onUpdate={(values) => {

                                                                setSelectedAdditionalCentres(values)
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>
                                    :
                                        null
                                }

                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true} error={formik.touched.knowlarity_agent && Boolean(formik.errors.knowlarity_agent)}>
                                                    
                                            <InputLabel id="knowlarity_agent_field">Knowlarity Agent</InputLabel>
                                            <Select
                                                name="knowlarity_agent"
                                                labelId="knowlarity_agent_field"
                                                value={formik.values.knowlarity_agent}
                                                onChange={formik.handleChange}
                                            >
                                                <MenuItem value=''>Select Agent</MenuItem>
                                                {agents && agents.length > 0
                                                    ?
                                                    agents.map((_agent) => {

                                                            return <MenuItem key={_agent.row_id} value={_agent.agent_id}>{_agent.first_name} {_agent.last_name}</MenuItem>
                                                        })
                                                    :
                                                        null
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={12}>

                                        <FormControl margin="normal" component="fieldset" error={formik.touched.status && Boolean(formik.errors.status)}>
                                                        
                                            <FormLabel component="legend">Status *</FormLabel>
                                            <RadioGroup row aria-label="status" name="status" value={formik.values.status} onChange={formik.handleChange}>

                                                <FormControlLabel value="0" control={<Radio size="small" />} label="Inactive" />
                                                <FormControlLabel value="1" control={<Radio size="small" />} label="Active" />
                                            </RadioGroup>
                                            <FormHelperText>{formik.touched.status && Boolean(formik.errors.status) ? 'Please choose one option' : ''}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={12}>

                                        <div className="action-sm">
                                        
                                            <Button onClick={() => {

                                                props.close()
                                            }} className="btn">Cancel</Button>
                                    
                                            <Button color="primary" variant="contained" type="submit">Update</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>

                            <Loader loading={loading} />
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )

    function loadEmployee(id, account_token){

        var formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('id', id);

        Api.post('users/single', formData, function(data){

            if(data.status){
            
                if(data.user){

                    setUser(data.user);

                    if(data.user.additional_centres.length > 0){

                        setAdditionalCentres(true);
                    }
                }else{

                    setErrorMessage(data.message);
                    window.setTimeout(() => {

                        setErrorMessage('');
                        setBack(true);
                    }, 3000);
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }

    function loadData(account_token){
    
        var formData = new FormData();
        formData.append('account_token', account_token);

        Api.post('general/users_init', formData, function(data){

            if(data.status){
            
                setRoles(data.roles);
                setCenters(data.centers);
                setAgents(data.agents);

                setDataLoaded(true);
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }
}

export default CreateEmployee;