import React, { Component } from 'react';

import Main from 'pages/sections/Main';
import Api from 'api/Api';

import DataTable from 'components/blocks/data_table/DataTable';

class InteraktTemplates extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: '',

            error_message: '',
            success_message: '',

            types: [
                {key: '', value: 'None'},
                {key: 'document', value: 'Document'},
                {key: 'image', value: 'Image'},
                {key: 'text', value: 'Text'},
                {key: 'video', value: 'Video'}
            ],

            statuses: [{key: 0, value: 'New'}, {key: 1, value: 'Approved'}, {key: 2, value: 'Unapproved'}]
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token && user){

            user = JSON.parse(user);

            this.setState({account_token: account_token, user: user});
        }else{

            window.location = Api.server_url + 'logout';
        }
    }

    render () {

        return (

            <Main page="interakt" active_link="system" title="Interakt Templates" error_message={this.state.error_message} success_message={this.state.success_message}>
                
                {this.state.account_token &&
                
                    <DataTable
                        label="Agents"

                        columns={[
                            {name: 'Code', column: 'code'},
                            {name: 'Title', column: 'title', sortable: true},
                            {name: 'Description', column: 'description', sortable: true, renderer: (_data) => {

                                return <span>{_data.description}</span>
                            }},
                            {name: 'Type', column: 'type', sortable: true, search_input: 'dropdown', search_data: this.state.types, renderer: (_data) => {

                                let types = this.state.types;
                                let type = types.find(row => row.key === _data.type);

                                return <span>{type ? type.value : _data.type}</span>
                            }},
                            {name: 'Status', column: 'status', sortable: true, search_input: 'dropdown', search_data: this.state.statuses, renderer: (_data) => {

                                let statuses = this.state.statuses;
                                let status = statuses.find(row => row.key == _data.status);

                                return <span>{status ? status.value : _data.status}</span>
                            }},
                        ]}

                        default_sort_by="id"
                        default_sort_dir="asc"

                        api_url="interakt/list"

                        account_token={this.state.account_token}
                        
                        // row_id="row_id"
                        // view_url="leads/view"
                    />
                }
            </Main>
        )
    }
}

export default InteraktTemplates;