import React, { useEffect, useState } from 'react';

import { Box, Button } from '@mui/material';

import NoData from 'components/blocks/NoData';

import documents from '../../../assets/images/documents.svg';
import success from '../../../assets/images/success.svg';

import CourseForm from '../../students/blocks/CourseForm';

function LeadConversion (props){ 

    const [reminder, setReminder] = useState(false);
    const [loading, setLoading] = useState(false);

    const [lead_form, showLeadForm] = useState(false);

    const [remind_by, setRemindBy] = useState([]);

    var lead = props.lead;

    if(lead){

        return (

            <Box component="div" whiteSpace="normal">

                {lead_form &&
                
                    <div className="center-form-container">
                    
                        <div className="center-form medium">

                            <h3 className="heading">Convert Lead to Student</h3>

                            <CourseForm
                                user={props.user}
                                account_token={props.account_token}
                                lead={lead}
                                showLeadForm={(action) => {

                                    showLeadForm(action)
                                }}
                                updateLead={(lead, lead_history) => {

                                    props.updateLead(lead, lead_history);
                                }}
                                success_message={(message) => {

                                    props.success_message(message);
                                }}
                                error_message={(message) => {

                                    props.error_message(message);
                                }}
                            />
                            
                        </div>
                    </div>
                }

                {(props.lead && props.lead.lead_status == 'converted')
                    ?
                        <NoData message="Lead Converted & Course assigned to student" size="medium" src={success}></NoData>
                    :

                        !lead_form &&
                            <NoData message="Assign Course to Student" size="small" src={documents}>
                                <Button size="small" color="secondary" variant="contained" onClick={() => {

                                    showLeadForm(true)
                                }}>Assign Course</Button>
                            </NoData>
                }
            </Box>
        )
    }else{

        return null
    }
}

export default LeadConversion;