import React, { Component } from 'react';

import { Popover, List, ListSubheader, ListItem } from '@mui/material';

class LeadDuplicates extends Component { 
    constructor(props) {
        super();
        this.state = {

        }
    }

    render () {

        return (

            <Popover
                onClose={() => {
                    this.props.hide()
                }}
                open={this.props.anchorEl ? true : false}
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="popover-confirm" style={{width:450}}>

                    <div className="header">
                        <strong>Duplicate Leads ({this.props.row ? this.props.row.duplicates_count : ''})</strong>
                    </div>
                    <div className="content" style={{maxHeight:250, minHeight:150}}>

                        {this.props.row && this.props.row._duplicates_history && this.props.row._duplicates_history.length > 0
                            ?
                                <List subheader={<li />}>

                                    {this.props.row._duplicates_history.map((history, index) => {
                                        
                                        return (
                                            <li key={`history-heading-${index}`}>
                                        
                                                <ul>
                                                    <ListSubheader>{history.date}</ListSubheader>

                                                    <ListItem key={`history-row-${index}`}>

                                                        <div className="info-card-body" style={{minHeight:150, justifyContent:'flex-start'}}>
                                                            <ul className="info-list">

                                                                <li>
                                                                    <label>Name:</label>
                                                                    <span>
                                                                        {history.first_name + ' ' + history.last_name}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <label>Email:</label>
                                                                    <span>
                                                                        {history.email}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <label>Source:</label>
                                                                    <span style={{flexDirection:'column'}}>
                                                                        <span>{history.lead_source_label}</span>
                                                                        {history.query_source_label != '' &&
                                                                        
                                                                            <span className="cg-5">{history.query_source_label}</span>
                                                                        }
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <label>Message:</label>
                                                                    <span>
                                                                        {history.message}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </ListItem>
                                                </ul>
                                            </li>
                                        )
                                    })}
                                </List>
                            :
                                null
                        }
                        {/* {this.state.users && this.state.users.length > 0
                            ?
                                <List subheader={<li />}>
                                    {this.state.users.map((users) => (

                                        <li key={`users-heading-${users.job_profile}`}>
                                        
                                            <ul>
                                                <ListSubheader>{users.profile_title}</ListSubheader>

                                                {users.users.map((user) => {
                                                    
                                                    var labelId = `checkbox-${users.job_profile}-${user.id}`;
                                                    return (
                                                        <ListItem button onClick={() => {

                                                            this.setState({selected_user: user.id})
                                                        }} key={`users-${users.job_profile}-${user.id}`}>
                                                            <ListItemIcon style={{minWidth:40}}>
                                                                <Radio
                                                                    edge="start"
                                                                    checked={this.state.selected_user == user.id ? true : false}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} primary={user.first_name + ' ' + user.last_name} />
                                                        </ListItem>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                    ))}
                                </List>
                            :
                                null
                        } */}
                    </div>
                </div>
            </Popover>
        )
    }
}

export default LeadDuplicates;