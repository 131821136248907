import React, { useEffect, useState } from 'react';

import { Grid, FormLabel, FormControl, RadioGroup, Radio, TextField, FormHelperText, FormControlLabel, Button, Select, InputLabel, MenuItem } from '@mui/material';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Loader from 'components/Loader';
import Api from 'api/Api';

function LeadForm (props){ 

    const [lead_sources, setLeadSources] = useState([]);
    const [sources_loaded, setSourcesLoaded] = useState(false);

    const [courses, setCourses] = useState([]);

    const [centers, setCenters] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
        if(!sources_loaded){
            
            loadSources();
        }
    });

    const validationSchema = yup.object({
        first_name: yup.string().required('First name is required!'),
        last_name: yup.string().required('Last name is required!'),
        mobile_number: yup.number('Enter valid mobile number').min(1000000000, 'Enter valid mobile number!').required('Mobile number is required!'),
        email: props.lead ? yup.string().email('Enter valid email address') : yup.string().email('Enter valid email address').required('Email is required'),
        lead_source: yup.string().required(),
        branch: yup.string().required()
    });

    const formik = useFormik({
        initialValues: {
            first_name: props.lead ? props.lead.first_name : '',
            last_name: props.lead ? props.lead.last_name : '',
            mobile_number: props.lead ? props.lead.mobile_number : '',
            email: props.lead ? props.lead.email : '',
            address: props.lead ? props.lead.address : '',
            lead_source: props.lead ? props.lead.lead_source : '',
            branch: props.lead ? (props.lead.branch != 0 ? props.lead.branch : '') : '',
            course: props.lead ? props.lead.course : '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            var formData = new FormData();

            Object.keys(values).forEach(key => formData.append(key, values[key]))
            formData.append('account_token', props.account_token);

            if(props.lead){

                formData.append('lead', props.lead.row_id);
            }

            setLoading(true);

            Api.post('leads/create', formData, function(data){

                if(data.status == true){
                
                    props.setSuccessMessage(data.message);
                    props.onSuccess(data);
                }else{

                    setLoading(false);

                    if(data.code && data.code == 'no_account'){

                        window.location = Api.server_url + 'logout';
                    }else{

                        props.setErrorMessage(data.message);
                    }
                    
                }
            });
        },
    });

    return (

        <div style={{position:'relative'}}>

            <form className="form" name="login_form" onSubmit={formik.handleSubmit}>

                <Grid container spacing={6}>

                    <Grid item xs={12} sm={6}>
                            
                        <FormControl fullWidth={true}>
                            <TextField
                                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                value={formik.values.first_name}
                                name="first_name"
                                label="First Name *"
                                helperText={formik.touched.first_name && formik.errors.first_name}
                                onChange={formik.handleChange}
                                fullWidth={true}
                                autoComplete="off"
                                variant='standard'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                            
                        <FormControl fullWidth={true}>
                            <TextField
                                fullWidth
                                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                value={formik.values.last_name}
                                name="last_name"
                                label="Last Name *"
                                helperText={formik.touched.last_name && formik.errors.last_name}
                                onChange={formik.handleChange}
                                autoComplete="off"
                                variant='standard'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                            
                        <FormControl fullWidth={true}>
                            <TextField
                                fullWidth
                                error={formik.touched.mobile_number && Boolean(formik.errors.mobile_number)}
                                value={formik.values.mobile_number}
                                name="mobile_number"
                                label="Mobile Number *"
                                helperText={formik.touched.mobile_number && formik.errors.mobile_number}
                                onChange={formik.handleChange}
                                autoComplete="off"
                                variant='standard'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                            
                        <FormControl fullWidth={true}>
                            <TextField
                                fullWidth
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                value={formik.values.email}
                                name="email"
                                label="Email *"
                                helperText={formik.touched.email && formik.errors.email}
                                onChange={formik.handleChange}
                                autoComplete="off"
                                variant='standard'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                            
                        <FormControl fullWidth={true}>
                            <TextField
                                fullWidth
                                value={formik.values.address}
                                name="address"
                                label="Address"
                                onChange={formik.handleChange}
                                autoComplete="off"
                                variant='standard'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                            
                        <FormControl fullWidth={true} error={formik.touched.branch && Boolean(formik.errors.branch)}>
                                    
                            <InputLabel id="center_id">Branch</InputLabel>
                            <Select
                                name="branch"
                                error={formik.touched.branch && Boolean(formik.errors.branch)}
                                labelId="center_id"
                                value={formik.values.branch}
                                onChange={formik.handleChange}
                                variant='standard'
                            >
                                <MenuItem value=''>Select Branch</MenuItem>
                                {centers && centers.length > 0
                                    ?
                                    centers.map((_center) => {

                                            return <MenuItem key={_center.id} value={_center.id}>{_center.name}</MenuItem>
                                        })
                                    :
                                        null
                                }
                            </Select>

                            {Boolean(formik.errors.branch) &&
                            
                                <FormHelperText>This is a required field</FormHelperText>
                            }
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                            
                        <FormControl fullWidth={true} error={formik.touched.course && Boolean(formik.errors.course)}>
                                    
                            <InputLabel id="course_id">Courses</InputLabel>
                            <Select
                                name="course"
                                error={formik.touched.course && Boolean(formik.errors.course)}
                                labelId="course_id"
                                value={formik.values.course}
                                onChange={formik.handleChange}
                                variant='standard'
                            >
                                <MenuItem value=''>Select Course</MenuItem>
                                {courses && courses.length > 0
                                    ?
                                        courses.map((_course) => {

                                            return <MenuItem key={_course.id} value={_course.id}>{_course.title}</MenuItem>
                                        })
                                    :
                                        null
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>

                        <FormControl margin="normal" component="fieldset" error={formik.touched.lead_source && Boolean(formik.errors.lead_source)}>
                                        
                            <FormLabel component="legend">Source *</FormLabel>
                            <RadioGroup row aria-label="source" name="lead_source" value={formik.values.lead_source} onChange={formik.handleChange}>

                                {lead_sources && lead_sources.length > 0 && lead_sources.map((_lead_source) => {

                                    return (

                                        <FormControlLabel key={"task_source_" + _lead_source.key} value={_lead_source.key} control={<Radio size="small" />} label={_lead_source.label} />
                                    )
                                })}
                            </RadioGroup>
                            <FormHelperText>{formik.touched.lead_source && Boolean(formik.errors.lead_source) ? 'Please choose one option' : ''}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>

                        <div className="action-sm">
                        
                            <Button color="primary" size="small" variant="text" type="button" onClick={() => {

                                props.onCancel()
                            }}>Cancel</Button>
                            <Button color="primary" variant="contained" size="small" type="submit">{props.lead ? 'Update' : 'Create'}</Button>
                        </div>
                    </Grid>
                </Grid>
            </form>

            <Loader loading={loading} />
        </div>
    )

    function loadSources(){
    
        setSourcesLoaded(true)

        var formData = new FormData();
            
        Api.post('general/sources', formData, function(data){

            setLeadSources(data.sources);
            setCourses(data.courses);
            setCenters(data.centers);
        });
    }
}

export default LeadForm;