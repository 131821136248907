import React, { Component } from 'react';

import { Button } from '@mui/material';

class LeadCommentRow extends Component {

    constructor(props) {
        super();
        this.state = {

            full: false
        }
    }

    render () {

        return (
            <div>
                {this.props.comment.comment != ''
                    ?
                        this.props.comment.comment.length > 200
                            ?
                                <div>
                                    {this.state.full
                                        ?
                                            <div>
                                                {this.props.comment.comment}<Button color="secondary" size="small" style={{padding:'2px 5px'}} onClick={() => {

                                                    this.setState({full: false})
                                                }}>Hide</Button>
                                            </div>
                                        :
                                            <div>
                                                {this.props.comment.comment.substr(0, 200)}...<Button color="secondary" size="small" style={{padding:'2px 5px'}} onClick={() => {

                                                    this.setState({full: true})
                                                }}>Read More</Button>
                                            </div>
                                    }
                                    
                                </div>
                            :
                                this.props.comment.comment
                    :
                        null
                }
            </div>
        )
    }
}

export default LeadCommentRow;