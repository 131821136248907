import React from "react";
import { useLocation, useParams } from "react-router-dom";

export function withRouter(Children){

    return(props) => {

        const location = useLocation();
        const params = {params: useParams()};

        const pathname = location.pathname;

        params['pathname'] = pathname;

        return <Children {...props} params={params} />
    }
}

class ScrollToTop extends React.Component {
  
    componentDidUpdate(prevProps) {
    
        if(this.props.params.params.pathname !== prevProps.location.pathname){
      
            window.scrollTo(0, 0);
        }
    }

    render() {
    
        return null;
    }
}

export default withRouter(ScrollToTop);