import React, { Component } from 'react';

import DataTable from 'components/blocks/data_table/DataTable';

import Main from 'pages/sections/Main';
import Loader from 'components/Loader';

import LayoutHelper from 'helpers/LayoutHelper';
import Api from 'api/Api';

class EmployeesList extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',
            error_message: '',

            loading: false,

            roles: [],
            centres: [],

            centres_flat: {}
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
            this.init(account_token)
        }else{

            window.location = Api.url + 'logout';
        }
    }

    render () {

        return (

            <Main active_link="employees" error_message={this.state.error_message} page="employees" title={"Employees"} /*title_action={<Link className="btn" to="/employee/add">Add Employee</Link>}*/>

                {this.state.account_token &&
                    <DataTable
                        index="employees"
                        label="Employees"

                        columns={[
                            {name: 'Username', column: 'username', sortable: true},

                            {name: 'Full Name', column: 'first_name', sortable: true, renderer: (_data) => {

                                return <span>{_data.first_name} {_data.last_name}</span>
                            }},
                            {name: 'Email Address', column: 'useremail', sortable: true},
                            {name: 'Mobile Number', column: 'contact_number', sortable: true},
                            
                            {name: 'Role', column: 'job_profile', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.roles, renderer: (_data) => {

                                return <span>{_data.role_title}</span>
                            }},

                            {name: 'Centre', column: 'exam_centre_id', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.centres, renderer: (_data) => {

                                return (
                                    <>
                                        <div>{_data.exam_centre}</div>
                                        {this.additionalCentres(_data)}
                                    </>
                                )
                            }},

                            {name: 'Member Since', column: 'created_at', sortable: true, renderer: (_data) => {

                                return <span>{_data.created_at_formatted}</span>
                            }},
                        ]}

                        default_sort_by="created_at"

                        api_url="users/load"

                        account_token={this.state.account_token}
                        
                        row_id="id"
                        view_url="employees/view"
                    />
                }

                <Loader loading={this.state.loading} />
            </Main>
        )
    }

    additionalCentres = (row) => {

        if(row.additional_centres != ''){

            var additional_centres = row.additional_centres.split(',');

            var centres = [];
            var centres_flat = this.state.centres_flat;

            for(var i in additional_centres){

                if(centres_flat.hasOwnProperty(additional_centres[i])){

                    centres.push(centres_flat[additional_centres[i]]);
                }
            }

            return <div>{centres.join(', ')}</div>;
        }
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('general/users_init', formData, function(data){

            if(data.status){
            
                var roles = that.state.roles;

                var _roles = data.roles;

                for(var r in _roles){

                    roles.push({key: _roles[r]['role_key'], value: _roles[r]['role_title']})
                }

                var centres = that.state.centres;
                var centres_flat = that.state.centres_flat;

                var _centres = data.centers;

                for(var r in _centres){

                    centres.push({key: _centres[r]['id'], value: _centres[r]['name']});
                    
                    centres_flat[_centres[r]['id']] = _centres[r]['name'];
                }

                that.setState({centres: centres, centres_flat: centres_flat});
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    syncAgents = () => {

        var formData = new FormData();
        var that = this;

        this.setState({loading: true})

        Api.post('agents/sync', formData, function(data){

            that.setState({loading: false})

            if(data.status){
            
                that.setState({redirect: true});
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default EmployeesList;
