import React, { Component } from 'react';

import { Grid, Button } from '@mui/material';

import Api from 'api/Api';

import { format } from 'date-fns'

import ViewList from '@mui/icons-material/ViewList';

import Loader from 'components/Loader';

import NoData from 'components/blocks/NoData';
import DataTable from 'components/blocks/data_table/DataTable';

class RevenueEmployeeWiseDetailed extends Component { 
    constructor(props) {
        super();
        this.state = {

            loader: true,
            view_employee: false,

            employees_wise_data: [],

            employee: false,
            employee_wise_data: [],
            do_reload: false,
        }
    }

    componentDidMount = () => {

        this.loadData();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(
            this.props.start_date !== prevProps.start_date
            ||
            this.props.end_date !== prevProps.end_date
        ){

            this.setState({view_employee: true, employee: false})
            this.loadData();
        }
    }

    render () {

        var post_params = [];

        if(this.props.employee_id != ''){

            post_params.push({key:'employee_id', value:this.state.employee.id}, {key:'start_date', value:format(this.props.start_date, "yyyy-MM-dd")}, {key:'end_date', value:format(this.props.end_date, "yyyy-MM-dd")}, {key:'do_reload', value:this.state.do_reload}, {key: 'source', value: 'collections'});
        }

        return (

            <>

                <Grid container spacing={2}>

                    <Grid item xs={2} sm={3}>

                        <Loader loading={this.state.loader} />

                        {!this.state.loader &&
                            
                            <div className='vertical flex-start'>
                                {this.state.employees_wise_data.length > 0 &&
                        
                                    this.state.employees_wise_data.map((_employee, index) => {

                                        return (

                                            <Button color={this.state.employee && this.state.employee.id === _employee.id ? 'secondary' : 'primary'} key={`employee_`+index} className="mb-5" onClick={()=>{

                                                this.setState({employee: _employee, view_employee: false, do_reload: true});
                                            }} size="small">

                                                <span>{_employee.x} <span className='op-7'>({_employee.y})</span></span>
                                            </Button>
                                        )
                                    })
                                }
                            </div>
                        }
                    </Grid>

                    <Grid item xs={10} sm={9}>

                        {!this.state.view_employee && this.state.employee.id
                            ?
                                <>
                                    <h3 style={{margin:0}}>{this.state.employee.x} <span className='op-6'>({this.state.employee.y})</span></h3>
                                    <DataTable
                                        index="student_details"
                                        label="Student Details"

                                        columns={[
                                            {name: 'Student Name', column: 'name', sortable: true},
                                            {name: 'Contact Number', column: 'contact_no', sortable: true},
                                            {name: 'Course', column: 'course_title', sortable: true},
                                            {name: 'Batch', column: 'batch_title', sortable: true},
                                            {name: 'Exam Centre', column: 'exam_centre_name', sortable: true},
                                            {name: 'Total Fees', column: 'total_fee', sortable: true},

                                            {name: 'Enrollment Date', column: 'added_on', sortable: true, renderer: (_data) => {

                                                return <span>{_data.date_formatted}</span>
                                            }},

                                        ]}

                                        default_sort_by="added_on"

                                        api_url="reports/revenue/employee_wise_detailed"

                                        account_token={this.props.account_token}

                                        do_reload={this.state.do_reload}
                                        relaodDone={() => {
                                            
                                            this.setState({do_reload: false})
                                        }}
                                        
                                        post_params={post_params}
                                        
                                        row_id="id"
                                    />
                                </>
                            :
                                <NoData icon={<ViewList style={{fontSize:240, color:'rgba(0,0,0,.04)'}} />} style={{margin:0}} size="small" message="Select any employee from the left" hide_image={true} />
                        }
                    </Grid>
                </Grid>
            </>
        )
    }

    loadData = () => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('start_date', format(this.props.start_date, "yyyy-MM-dd"));
        formData.append('end_date', format(this.props.end_date, "yyyy-MM-dd"));

        this.setState({loader: true});

        Api.post('reports/revenue/employee_wise', formData, function(data){

            that.setState({loader: false});

            if(data.status){
            
                if(data.employees_wise_data.length <= 0){

                    that.setState({employees_wise_data: []});
                }else{

                    that.setState({employees_wise_data: data.employees_wise_data});
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    // afterSetEmployee = () => {

    //     var that = this;

    //     var formData = new FormData();
    //     formData.append('account_token', this.props.account_token);
    //     formData.append('start_date', format(this.props.start_date, "yyyy-MM-dd"));
    //     formData.append('end_date', format(this.props.end_date, "yyyy-MM-dd"));

    //     if(!this.state.employee_id){
    //         return false;
    //     }
    //     formData.append('employee_id', this.state.employee_id);

    //     this.setState({loader: true});

    //     Api.post('reports/revenue/employee_wise_detailed', formData, function(data){

    //         that.setState({loader: false});

    //         if(data.status){
            
    //             if(data.employee_wise_data.length <= 0){

    //                 that.setState({employee_wise_data: []});
    //             }else{

    //                 that.setState({employee_wise_data: data.employee_wise_data});
    //             }
    //         }else{

    //             if(data.code && data.code == 'no_account'){

    //                 window.location = Api.server_url + 'logout';
    //             }
    //         }
    //     });
    // }
}

export default RevenueEmployeeWiseDetailed;
