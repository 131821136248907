import React, { Component } from 'react';

import { FormControl, FormGroup, FormControlLabel, Checkbox, FormLabel } from '@mui/material';

class TaskRemindOptions extends Component { 

    constructor(props) {
        super();
        this.state = {

            remind_by: ['email', 'sms'],
        }
    }

    render () {

        return (
            <FormControl margin="normal" component="fieldset">
                                                            
                <FormLabel component="legend">Remind By</FormLabel>
                <FormGroup row aria-label="source" name="remind_by">

                    {[{key: 'email', value: 'By Email'}, {key: 'sms', value: 'By SMS'}].map((_key) => {

                        return (
                            <FormControlLabel key={"remind_" + _key.key} control={<Checkbox
                                value={_key.key}
                                name="remind_by"
                                checked={(this.state.remind_by.indexOf(_key.key) > -1) ? true : false}
                                onChange={(e) => {
                        
                                    var remind_by = this.state.remind_by;

                                    if(e.target.checked){
                                    
                                        if(remind_by.indexOf(_key.key) > -1){

                                        }else{
                                        
                                            remind_by.push(_key.key);
                                        }
                                        
                                    }else{

                                        if(remind_by.indexOf(_key.key) > -1){

                                            remind_by.splice(remind_by.indexOf(_key.key), 1);
                                        }
                                        
                                    }

                                    this.setState({remind_by: remind_by});
                                    this.props.onUpdate(remind_by)
                                }}
                                size="small" />} label={_key.value} />
                        )
                    })}
                </FormGroup>
            </FormControl>
        )
    }
}

export default TaskRemindOptions;