import React, { Component } from 'react';

import { Grid, Button, IconButton, FormControl, InputLabel, Select, MenuItem, Popover, Modal, Box } from '@mui/material';

import { Add, CardMembership, Clear, Done, CloudDownload, Refresh } from '@mui/icons-material';

import Api from 'api/Api';
import Loader from 'components/Loader';
import NoData from 'components/blocks/NoData';

class StudentCertificates extends Component { 
    constructor(props) {
        super();
        this.state = {

            confirm: false,
            job_profile: '',

            loading: false,
            certificates: [],

            show_single_exam: false,

            loading_single_exam: false,

            selected_sub_exam: false,

            no_certificates: false,

            generate_certificate: false,

            types: [{key: 'cft', value: 'Certified Fitness Trainer'}, {key: 'cpt', value: 'Certified Personal Trainer'}, {key: 'ewms', value: 'EWMS'}, {key: 'fns', value: 'FNS'}, {key: 'cfp', value: 'Certified Fitness Practitioner'}, {key: 'cns', value: 'Certified Nutrition Specialist'}],

            selected_type: '',

            certificates: [],

            selected_certificate: false,

            certificate_modal: false,
            active_certificate: false,
            active_student_id: false,
            active_certificate_type: false,
            certificate_html: '',

            renew_confirm: false
        }
    }

    componentDidMount = () => {

        var user = localStorage.getItem('user');

        if(user){

            let _user = JSON.parse(user);

            this.setState({job_profile: _user.job_profile});

            if(this.props.student){

                var student_id = this.props.student.id;
                this.loadCertificates(student_id);
            }

        }else{

            window.location = Api.url + 'logout';
        }
    }

    certficateTypes = () => {

        const certificates = this.state.certificates;

        let allotted = [];

        if(certificates.length > 0){

            for(var i in certificates){

                allotted.push(certificates[i]['certificate_type']);
            }
        }

        return this.state.types.map((_type) => {

            if(allotted.indexOf(_type.id) < 0){
            
                return <MenuItem key={`type_${_type.key}`} value={_type.key}>{_type.value}</MenuItem>
            }
        })
    }

    render () {

        return (

            <div className="tab-content">

                <div class="box-header">
                    <h2>Certificates <span>({this.state.certificates.length})</span></h2>

                    {/* {( (this.state.job_profile == 'super_admin') && (this.state.certificates.length < 4) ) && */}
                    
                        <Button size="small" color="secondary" startIcon={<Add />} onClick={() => {

                            this.setState({generate_certificate: true})
                        }}>Add Certificate</Button>
                    {/* } */}
                </div>

                <Grid container spacing={2}>
                    
                    {this.state.generate_certificate &&
                        
                        <>
                        
                            <Grid item sm={6}>

                                <FormControl fullWidth>
                                
                                    <InputLabel>Choose Certificate</InputLabel>
                                    <Select
                                        value={this.state.selected_type}
                                        onChange={(e) => {

                                            this.setState({selected_type: e.target.value})
                                        }}
                                    >
                                        {this.certficateTypes()}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12}>

                                <FormControl>
                                    
                                    <div className="space-between">
                                    
                                        <Button size="small" color="secondary" onClick={() => {

                                            this.setState({generate_certificate: false})
                                        }}>Cancel</Button>

                                        {(this.state.job_profile == 'super_admin') && 
                                            <Button color="primary" variant="contained" className="ml-10" size="small" onClick={() => {

                                                this.generateCertificate()
                                            }}>Generate Certificate</Button>
                                        }
                                    </div>
                                </FormControl>
                            </Grid>
                        </>
                    }

                    {this.state.certificates.length > 0
                        ?
                            <>
                            
                                <div className="items-list" style={{width: '100%'}}>
                                    
                                    {this.state.certificates.map((_certificate, index) => {

                                        return (

                                            <div className="items-list-item" key={`cert_${index}`}>

                                                <div className="items-list-content has-hoverable-action">

                                                    <div className="items-list-icon">
                                                        <CardMembership />
                                                    </div>

                                                    <div className="items-list-item-content">
                                                    
                                                        <div>
                                                            <div className="primary">
                                                                <span>{_certificate.title}</span>
                                                            </div>
                                                            <div className="secondary">
                                                                <span className='c-p'>{_certificate.certificate_number}</span>
                                                                <b className="cg-5 ml-3">{_certificate.issued_elapsed}</b>
                                                            </div>

                                                            <div>

                                                                {
                                                                    (
                                                                        _certificate.desktop_viewed == '1'
                                                                        ||
                                                                        _certificate.mobile_viewed == '1'
                                                                        ||
                                                                        _certificate.is_downloaded == '1'
                                                                    )
                                                                &&
                                                                
                                                                    <div className='flex-center'>
                                                                        <strong className='c-r'>Viewed:</strong>

                                                                        {_certificate.desktop_viewed == '1' &&
                                                                        
                                                                            <div className='ml-5 flex-center'>
                                                                                <span title={_certificate.desktop_viewed_on} className="flex-center"><Done className="fs-16 mr-3" /> Desktop</span>
                                                                            </div>
                                                                        }

                                                                        {_certificate.mobile_viewed == '1' &&
                                                                        
                                                                            <div className='ml-5 flex-center'>
                                                                                <span title={_certificate.mobile_viewed_on} className="flex-center"><Done className="fs-16 mr-3" /> Mobile</span>
                                                                            </div>
                                                                        }

                                                                        {_certificate.is_downloaded == '1' &&
                                                                        
                                                                            <div className='ml-5 flex-center'>
                                                                                <span title={_certificate.downloaded_on} className="flex-center"><CloudDownload className="fs-16 mr-3 op-4" /> Downloaded</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>

                                                            {_certificate.last_renewal_formatted !== '' &&
                                                            
                                                                <div>
                                                                    <strong className='c-r'>Last Renewal:</strong>
                                                                    <span className='ml-5'>{_certificate.last_renewal_formatted}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="align-end">
                                                        <span>{_certificate.issued_formatted}</span>
                                                        
                                                        <div className="hoverable-action ml-5">

                                                            <Button color="primary" size="small" onClick={ (e) => {

                                                                this.setState({active_certificate: _certificate.id, active_student_id: _certificate.student_id, active_certificate_type:_certificate.certificate_type, certificate_modal:true}, () => {

                                                                    this.getCertificate();
                                                                });
                                                            }}>View</Button>

                                                            <Button color="secondary" size="small" onClick={(e) => {

                                                                this.setState({confirm: e.currentTarget, selected_certificate: _certificate.id})
                                                            }}>Delete</Button>

                                                            <Button color="primary" size="small" startIcon={<Refresh />} onClick={(e) => {

                                                                this.setState({renew_confirm: e.currentTarget, selected_certificate: _certificate.id})
                                                            }}>Renew</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        :
                            null
                    }

                    <Modal open={this.state.certificate_modal} onClose={() => {
                        this.setState({certificate_modal: false});
                    }} aria-labelledby="certificate-modal-title" aria-describedby="certificate-modal-description">
                        <Box sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', width:'80%', bgcolor:'background.paper', border:'2px solid #000', boxShadow:24, p:4}}>
                            <div className="flex">
                                
                                <IconButton aria-label="delete" size="small" style={{position:'absolute', right:'8px', top:'8px'}} onClick={ (e) => {

                                    this.setState({certificate_modal:false});
                                }}>
                                    <Clear fontSize="inherit" />
                                </IconButton>
                            </div>
                            
                            <div className="certificate_html">
                                {this.state.certificate_html}
                            </div>
                        </Box>
                    </Modal>

                    <Popover
                        onClose={() => {
                            this.setState({confirm: false, selected_certificate: false})
                        }}
                        open={this.state.confirm ? true : false}
                        anchorEl={this.state.confirm}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div className="alert">

                            <div className="alert-content">

                                <h2>Alert!</h2>
                                <p>Do you really want to remove this certificate?</p>
                            </div>

                            <div className="alert-actions">

                                <Button size="small" color="primary" onClick={() => {

                                    this.setState({confirm: false})
                                }}>Cancel</Button>
                                <Button type="button" variant="contained" color="secondary" size="small" onClick={() => {

                                    this.removeCertificate(this.state.selected_certificate);
                                }}>Yes</Button>
                            </div>
                        </div>
                    </Popover>

                    <Popover
                        onClose={() => {
                            this.setState({renew_confirm: false, selected_certificate: false})
                        }}
                        open={this.state.renew_confirm ? true : false}
                        anchorEl={this.state.renew_confirm}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div className="alert">

                            <div className="alert-content">

                                <h2>Alert!</h2>
                                <p>Do you really want to renew this certificate?</p>
                            </div>

                            <div className="alert-actions">

                                <Button size="small" color="primary" onClick={() => {

                                    this.setState({renew_confirm: false})
                                }}>Cancel</Button>
                                <Button type="button" variant="contained" color="secondary" size="small" onClick={() => {

                                    this.renewCertificate(this.state.selected_certificate);
                                }}>Yes</Button>
                            </div>
                        </div>
                    </Popover>
                
                    {(this.state.no_certificates && !this.state.generate_certificate) &&
                    
                        <Grid item xs={12} sm={12}>
                            <NoData size="small" message="Certificates not found!">

                                {(this.state.job_profile == 'super_admin') && 
                                    <Button color="secondary" size="small" onClick={() => {

                                        this.setState({generate_certificate: true})
                                    }}>Generate Certificate</Button>
                                }
                                
                            </NoData>
                        </Grid>
                    }

                    <Loader loading={this.state.loading} />
                </Grid>
            </div>
        )
    }

    renewCertificate = () => {

        this.setState({renew_confirm: false})

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('student_id', this.props.student.id);
        formData.append('certificate', this.state.selected_certificate);

        var that = this;
        this.setState({loading: true});

        Api.post('students/certificates/renew', formData, function(data){

            that.setState({loading: false});

            if(data.status){
            
                that.setState({selected_certificate: false})
                that.loadCertificates(that.props.student.id);
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.error_message(data.message);
                }
            }
        });
    }

    getCertificate = () => {
        
        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('student_id', this.state.active_student_id);
        formData.append('certificate_type', this.state.active_certificate_type);

        var that = this;
        this.setState({loading: true});

        Api.post('students/certificates/get_certificate_html', formData, function(data){

            that.setState({loading: false});

            if(data.status){
            
                that.setState({certificate_html: <iframe title="certificate_html" src={"data:text/html,"+encodeURIComponent(data.html)} height="500px" width="1000px" />});
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.error_message(data.message);
                }
            }
        });
    }

    removeCertificate = (id) => {

        if(id != ''){
        
            var formData = new FormData();

            formData.append('account_token', this.props.account_token);
            formData.append('certificate', id);

            var that = this;
            this.setState({loading: true});

            Api.post('students/certificates/remove', formData, function(data){

                that.setState({loading: false});

                if(data.status){
                
                    that.setState({no_certificates: false, generate_certificate: false, selected_type: '', confirm: false, selected_certificate: false})
                    that.loadCertificates(that.props.student.id);
                    
                }else{

                    if(data.code && data.code == 'no_account'){

                        window.location = Api.url + 'logout';
                    }else{

                        that.props.error_message(data.message);
                    }
                }
            });
        }
    }

    generateCertificate = () => {

        if(this.state.selected_type != ''){
        
            var formData = new FormData();

            formData.append('account_token', this.props.account_token);
            formData.append('student', this.props.student.id);
            formData.append('type', this.state.selected_type);

            var that = this;
            this.setState({loading: true});

            Api.post('students/certificates/add', formData, function(data){

                that.setState({loading: false});

                if(data.status){
                
                    that.setState({no_certificates: false, generate_certificate: false, selected_type: ''})
                    that.loadCertificates(that.props.student.id);
                    
                }else{

                    if(data.code && data.code == 'no_account'){

                        window.location = Api.url + 'logout';
                    }else{

                        that.props.error_message(data.message);
                    }
                }
            });
        }
    }

    loadCertificates = (student_id) => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('student', student_id);
        formData.append('types', 1);

        var that = this;
        this.setState({loading: true});
            
        Api.post('students/certificates/all', formData, function(data){

            that.setState({loading: false});

            if(data.status){

                that.setState({types: data.types});
            
                if(data.certificates && data.certificates.length > 0){

                    that.setState({certificates: data.certificates, no_certificates: false});
                }else{

                    that.setState({certificates: [], no_certificates: true});
                }
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.error_message(data.message);
                }
            }
        });
    }
}

const mapStateToProps = state => {
	return {
        user: state.user.user
	}
}

export default StudentCertificates;
// export default connect(mapStateToProps, { User } )(StudentCertificates);