import React, { Component } from 'react';

import { Grid, FormControl, InputLabel, FormHelperText, TextField, Button, IconButton, Popover, Select, MenuItem } from '@mui/material';

import Loader from 'components/Loader';
import Api from 'api/Api';

import Clear from '@mui/icons-material/Clear';

class InteraktForm extends Component { 
    constructor(props) {
        super();

        this.state = {

            loading: false,

            template: false,

            templates: [],

            template_preview: '',

            inputs: 0,
            input_vars: {},
            input_vars_error: {},

            image: false,
            document: false,

            image_error: false,
            document_error: false,
        }
    }
    
    componentDidMount = () => {

        if(this.props.account_token){

            this.loadTemplates(this.props.account_token)
        }
    }

    loadTemplates = () => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);

        var that = this;

        Api.post('interakt/load', formData, function(data){

            if(data.status){
            
                that.setState({templates: data.templates})
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }

    resetForm = () => {
        
        // setTemplate('');

        // setCourseTitle('');
        // setTiming('');
        
        // setBatchTitle('');
        // setSlotTiming('');
        
        // setDate('');
        // setEmiAmount('');

        // setConferenceInput('');
        // setSessionInput('');
        // setAlternateContact('');
    }

    submit = () => {

        let can_continue = true;

        let template = this.state.template;
        let input_vars = this.state.input_vars;

        let input_vars_error = {};

        if(this.state.inputs > 0){

            for(var i = 1; i <= this.state.inputs; i++){

                let n = i;

                if(
                    !input_vars.hasOwnProperty(n)
                    ||
                    (input_vars.hasOwnProperty(n) && input_vars[n] === '')
                ){

                    input_vars_error[n] = true;
                    can_continue = false;
                }
            }

            this.setState({input_vars_error: input_vars_error})
        }

        if(template.type === 'image'){

            if(!this.state.image){

                this.setState({image_error: true})
                can_continue = false;
            }
        }

        if(template.type === 'document'){

            if(!this.state.document){

                this.setState({document_error: true})
                can_continue = false;
            }
        }

        if(can_continue){

            var formData = new FormData();

            // formData.append('template', template);

            formData.append('account_token', this.props.account_token);
            
            if(this.props.source){

                formData.append('source', this.props.source);
            }

            if(this.props.lead_id){

                formData.append('lead_id', this.props.lead_id);
            }

            if(this.props.student_id){

                formData.append('student_id', this.props.student_id);
            }

            if(this.props.select_all){

                formData.append('select_all', Boolean(this.props.select_all));

                if(Object.keys(this.props.filters).length > 0){

                    formData.append('search', JSON.stringify(this.props.filters));
                }
            }else{

                if(this.props.bulk_mobile_numbers && this.props.bulk_mobile_numbers.length > 0){

                    formData.append('mobiles', JSON.stringify(this.props.bulk_mobile_numbers));
                }else{
    
                    formData.append('mobile', this.props.lead.mobile_number);
                }
            }

            formData.append('template', this.state.template.code);
            formData.append('vars', JSON.stringify(this.state.input_vars));

            if(this.state.image){

                formData.append('image', this.state.image);
            }

            if(this.state.document){

                formData.append('document', this.state.document)
            }

            this.setState({loading: true})

            let that = this;

            Api.post('interakt/send', formData, function(data){

                that.setState({loading: false})

                if(data.status){
                
                    that.resetForm();
                    that.props.setSuccessMessage(data.message);

                    // setLoaded(false);
                    that.props.onSuccess();
                }else{

                    if(data.code && data.code == 'no_account'){

                        window.location = Api.server_url + 'logout';
                    }else{

                        that.props.setErrorMessage(data.message);
                    }
                    
                }
            });
        }
    }

    renderInputs = () => {

        let template = this.state.template
        let inputs = this.state.inputs;

        let _return = [];

        if(inputs > 0){

            for(var i = 1; i <= inputs; i++){

                let n = i;

                _return.push(

                    <Grid container spacing={3} key={`inputs_${n}`}>

                        <Grid item xs={12} sm={12}>
                                
                            <FormControl fullWidth={true}>
                                <TextField
                                    fullWidth
                                    error={(this.state.input_vars_error.hasOwnProperty(n) && this.state.input_vars_error[n] === true) ? true : false}
                                    value={this.state.input_vars.hasOwnProperty(n) ? this.state.input_vars[n] : ''}
                                    name={`input_var_${n}`}
                                    label={`Input ${n}`}
                                    helperText={(this.state.input_vars_error.hasOwnProperty(n) && this.state.input_vars_error[n] === true) ? 'This is required entry!' : false}
                                    onChange={(e) => {

                                        let input_vars = this.state.input_vars;
                                        let template = this.state.template;
                                        let input_vars_error = this.state.input_vars_error;
                                        
                                        let templates = this.state.templates;
                                        let _template = templates.find(row => row.code === template.code);

                                        if(_template){

                                            let text = e.target.value;

                                            if(text !== ''){

                                                if(input_vars_error.hasOwnProperty(n)){

                                                    delete input_vars_error[n];
                                                }

                                                this.setState({input_vars_error: input_vars_error})
                                            }

                                            input_vars[n] = text;

                                            let template_preview = _template.description;

                                            for(var o in input_vars){

                                                let _text = input_vars[o];

                                                if(_text !== ''){

                                                    template_preview = template_preview.replace(`{{${o}}}`, _text)
                                                }
                                            }

                                            this.setState({ template_preview: template_preview, input_vars: input_vars })
                                        }
                                    }}
                                    autoComplete="off"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            }
        }

        if(template && template.type === 'image'){

            _return.push(
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>
                            
                        <FormControl fullWidth={true} error={this.state.image_error}>

                            <label className='mb-5'><strong>Upload Image</strong></label>
                            <input type="file" accept=".jpg,.jpeg,.png" onChange={(e) => {this._handleImageChange(e, 'image')}} />

                            {this.state.image_error &&
                            
                                <FormHelperText>Image is required to upload!</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }

        if(template && template.type === 'document'){

            _return.push(
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>
                            
                        <FormControl fullWidth={true} error={this.state.document_error}>

                            <label className='mb-5'><strong>Upload Document</strong></label>
                            <input type="file" accept=".pdf" onChange={(e) => {this._handleImageChange(e, 'document')}} />

                            {this.state.document_error &&
                            
                                <FormHelperText>Document (pdf) is required to upload!</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                </Grid>
            )
        }

        return _return;
    }

    _handleImageChange = async(e, type) => {

        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.readAsDataURL(file);

        if(type === 'document'){

            this.setState({document_error: false})
            this.setState({document: file});
        }else{
            
            this.setState({image_error: false})
            this.setState({image: file});
        }
    }

    render () {

        return (

            <Popover
                open={this.props.anchorEl ? true : false}
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div className="popover-confirm" style={{width:600}}>

                    <div className="header space-between" style={{width: 'auto'}}>
                        <strong>{this.props.title}</strong>

                        <IconButton size="small" onClick={() => {

                                // setLoaded(false);
                                // resetForm();
                                this.props.onCancel();
                            }}>
                            <Clear />
                        </IconButton>
                    </div>
                    <div className="content" style={{padding:20}}>
            
                        <div style={{position:'relative'}}>

                            <Grid container spacing={3}>
                            
                                <Grid item xs={6}>

                                    <FormControl fullWidth>

                                        <InputLabel>Select Template</InputLabel>

                                        <Select
                                            value={this.state.template.code}
                                            onChange={(e) => {

                                                if(e.target.value !== ''){
                                                
                                                    let templates = this.state.templates;
                                                    let template = templates.find(row => row.code === e.target.value);

                                                    if(template){

                                                        this.setState({template: template, inputs: template.vars, template_preview: template.description})
                                                    }else{

                                                        this.setState({template: ''})
                                                    }
                                                }else{

                                                    this.setState({template: ''})
                                                }

                                                this.setState({ input_vars: {}, input_vars_error: {}, image: false, document: false, image_error: false, document_error: false })
                                            }}
                                            label="Select Template"
                                        >
                                            <MenuItem value=""><em>Choose Template</em></MenuItem>

                                            {this.state.templates && this.state.templates.length > 0
                                                ?
                                                    this.state.templates.map((_template) => {

                                                        return <MenuItem disabled={_template.status == '1' ? false : true} key={'sms_'+_template.code} value={_template.code}>{_template.title} {_template.status != '1' ? <span className='op-6 fs-11'>(Unapproved)</span> : ''}</MenuItem>
                                                    })
                                                :
                                                    null
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                                
                            {this.state.template &&

                                <form className="form">
        
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>

                                            {this.state.template_preview}
                                        </Grid>
                                    </Grid>

                                    {this.renderInputs()}

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>

                                            <div className="action-sm">
                                            
                                                <Button color="primary" size="small" variant="text" type="button" onClick={() => {

                                                    this.props.onCancel()
                                                }}>Cancel</Button>

                                                <Button color="primary" size="small" variant="contained" type="button" onClick={() => {

                                                    this.submit()
                                                }}>Send</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            }

                            <Loader loading={this.state.loading} />
                        </div>
                    </div>
                </div>
            </Popover>
        )
    }
}

export default InteraktForm;