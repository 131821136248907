import React, { Component } from 'react';

import { Button, Popover } from '@mui/material';
import Chip from '@mui/material/Chip';

import { Link } from "react-router-dom";
import { Phone, MailOutline } from '@mui/icons-material';

import Api from 'api/Api';

import Loader from 'components/Loader';
import DateHelper from 'helpers/DateHelper';

class LeadTaskRow extends Component {

    constructor(props) {
        super();
        this.state = {

            show: false,
            loading: false,

            confirm: false
        }
    }

    wrapper = () => {

        if(this.props.link){

            return (

                <Link className="items-list-item" to={"/" + this.props.link}>
                    {this.content()}
                </Link>
            )
        }else{

            return (

                <div className="items-list-item">
                    {this.content()}
                </div>
            )
        }
    }

    content = () => {

        return (

            <div className="items-list-content has-hoverable-action">

                <div className="items-list-icon">
                    {this.props.task.task == 'call' &&

                        <Phone />
                    }

                    {this.props.task.task == 'email' &&

                        <MailOutline />
                    }
                </div>

                <div className="items-list-item-content">

                    <div style={{width:(this.props.page && this.props.page == 'tasks') ? '35%' : '70%'}}>
                        <div className="primary">
                            <span>{this.props.task.task_label}</span>
                            <span className="fs-12 cg-7 ml-5 fw-n mr-3">
                                at
                                <b className="ml-3 mr-3">{this.props.task.task_time_formatted}</b>
                                on
                                <b className="ml-3  mr-3">{this.props.task.task_date_formatted}</b>
                                
                                {this.props.task.first_name != ''
                                    ?
                                        <>by <b className="ml-3 mr-3">{this.props.task.first_name} {this.props.task.last_name}</b></>
                                    :
                                        null
                                }
                            </span>
                        </div>

                        <div className="secondary">

                            {this.props.task.remind_on != '' &&
                            
                                <span>Remind: {this.props.task.remind_on_formatted} by <b>{this.props.task.remind_by_formatted}</b></span>
                            }
                            {(this.checkDateDiff(this.props.task) && this.props.task.status == 'new') &&
                            
                                <Chip
                                    size="small"
                                    label="Not Marked"
                                    color="secondary"
                                />
                            }
                        </div>

                        {this.props.task.details &&

                            <div className="content" style={{cursor:'pointer'}} onClick={() => {

                                this.setState({show: !this.state.show})
                            }}>

                                {!this.state.show
                                    ?
                                        <div className="short-text">{this.props.task.details.substring(0, 55)}...</div>
                                    :
                                        <p>{this.props.task.details}</p>
                                }
                            </div>
                        }
                    </div>

                    {(this.props.page && this.props.page == 'tasks')
                        ?
                            <div style={{width:300}}>
                                <div className="secondary">
                                    <div className="short-text">
                                        <span className="cg-7">{this.props.task.lead_first_name} {this.props.task.lead_last_name}</span>
                                    </div>

                                    <div className="short-text">
                                        <span className="cg-7">{this.props.task.mobile}</span>
                                    </div>

                                    <div className="short-text">
                                        <span className="cg-7">{this.props.task.email}</span>
                                    </div>
                                </div>
                            </div>
                        :
                            null
                    }

                    <div style={{width:200}}>

                        {this.props.task.status == 'new'
                            ?
                            
                                <div>
                                    {this.checkDateDiff(this.props.task) &&
                                    
                                        <div className="hoverable-action vertical">

                                            <Button color="secondary" size="small" onClick={() => {

                                                this.updateStatus(this.props.task.row_id, 'done');
                                            }}>Mark as done</Button>
                                            <Button color="secondary" size="small" onClick={(e) => {

                                                this.setState({confirm: e.currentTarget})
                                            }}>Not done</Button>
                                        </div>
                                    }
                                </div>
                            :
                                <div>
                                    {this.props.task.status == 'done' &&

                                        <span className="badge small success">Done</span>
                                    }

                                    {this.props.task.status == 'not_done' &&

                                        <span className="badge small warning">Not Done</span>
                                    }
                                </div>
                        }
                    </div>
                </div>

                <Loader loading={this.state.loading} />

                <Popover
                    onClose={() => {
                        this.setState({confirm: false})
                    }}
                    open={this.state.confirm ? true : false}
                    anchorEl={this.state.confirm}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <div className="alert">

                        <div className="alert-content">

                            <h2>Alert!</h2>
                            <p>Do you really want to mark this task as "Not Done"?</p>
                        </div>

                        <div className="alert-actions">

                            <Button size="small" color="primary" onClick={() => {

                                // this.setState({status_popup: false, selected_status: false})
                            }}>Cancel</Button>
                            <Button type="button" variant="contained" color="secondary" size="small" onClick={() => {

                                this.updateStatus(this.props.task.row_id, 'not_done');
                                this.setState({confirm: false})
                            }}>Yes</Button>
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }

    checkDateDiff = (task) => {

        const now = new Date();
        let formatted_date = DateHelper.formatData(now);
        
        const date = new Date(task.task_date);

        let time = new Date(formatted_date + ' ' + task.task_time);

        const today = new Date(formatted_date);

        if(
            today.getTime() == date.getTime()
            &&
            time.getTime() < now.getTime()
        ){

            return true;
        }else if(today.getTime() > date.getTime()){

            return true;
        }

        return false;
    }

    render () {
        
        return (

            this.wrapper()
        )
    }

    updateStatus = (row_id, status) => {
    
        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('row_id', row_id);
        formData.append('status', status);

        this.setState({loading: true});

        var that = this;

        Api.post('leads/tasks/update_status', formData, function(data){

            that.setState({loading: false});

            if(data.status){
            
                that.props.onSuccess(data.message);
                that.props.updateTaskStatus(row_id, status);

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }else{

                    that.props.onError(data.message)
                }
            }
        });
    }
}

export default LeadTaskRow;