import React, { Component } from 'react';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import renderHTML from 'react-render-html';

import Api from 'api/Api';

import LeadCommentForm from './LeadCommentForm';

class LeadComments extends Component { 

    constructor(props) {
        super();
        this.state = {

            comments_count: 0,

            comment: '',

            comment_error: false,

            sending: false,

            loading: false,
            comments: [],
        }
    }

    componentDidMount = () => {

        this.loadComments()
    }

    render () {

        return (
            <div>

                <div className="box-header">
                    
                    <h2>Comments <span>({this.state.comments_count})</span></h2>
                </div>

                <LeadCommentForm
                    account_token={this.props.account_token}
                    lead={this.props.lead}
                    onSuccess={(data) => {

                        this.props.success_message(data.message);

                        if(data.comment){

                            var comments = this.state.comments;
        
                            comments.unshift(data.comment);
                            this.setState({comments: comments});
                        }
                    }}
                    onError={(message) => {

                        this.props.error_message(message)
                    }}
                />
                
                <div>
                    {this.state.comments && this.state.comments.length > 0
                        ?

                            <Timeline className="timeline comments-timeline">

                                {this.state.comments.map((_comment) => {

                                    return (

                                        <TimelineItem key={"comment_" + _comment.id}>
                                            <TimelineSeparator>
                                                <TimelineDot>
                                                    <span>{_comment.labels}</span>
                                                </TimelineDot>
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <div className="content-block">
                                                    <div className="content-header">
                                                        {renderHTML(_comment.header)}
                                                        <div className="secondary">
                                                            <span className="label-secondary">{_comment.added_on_formatted}</span>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        {_comment.comment}
                                                    </div>
                                                </div>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )
                                })}
                                
                            </Timeline>
                        :
                            null
                    }
                    
                </div>
            </div>
        )
    }

    loadComments = () => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('lead', this.props.lead.row_id);

        var that = this;
        this.setState({loading: true});
            
        Api.post('leads/comments/load', formData, function(data){
           
            that.setState({loading: false});

            if(data.status){

                that.setState({comments: data.records});
            }else{

                that.props.error_message(data.message);
            }
        });
    }
}

export default LeadComments;