import React, { Component } from 'react';

import { Link, Navigate, useParams } from "react-router-dom";

import { Grid, Tabs, Tab, Box, Button } from '@mui/material';

import { ChevronLeft } from '@mui/icons-material';

import LayoutHelper from 'helpers/LayoutHelper';

import Main from 'pages/sections/Main';
import Api from 'api/Api';

import EmployeeActivities from './EmployeeActivities';
import CreateEmployee from './CreateEmployee';

import EmployeePerformance from './EmployeePerformance';
import CollectionsList from '../reports/collections/CollectionsList';

import EmployeeLeads from './EmployeeLeads';

export function withRouter(Children){

    return(props) => {

        const params = {params: useParams()};

        return <Children {...props} params={params} />
    }
}

class EmployeeView extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',

            error_message: '',
            success_message: '',

            active_tab: 'performance',

            employee: false,

            roles: [],
            centers: [],
            agents: [],

            sidebar: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            var employee_id = this.props.params.params.employee_id;

            if(employee_id){

                this.setState({account_token: account_token, employee_id: employee_id});

                this.loadData(account_token);
                this.loadEmployee(employee_id, account_token);

            }else{

                this.setState({back: true});
            }
        }else{

            window.location = Api.server_url + 'logout';
        }
    }

    render () {

        var employee = this.state.employee;

        if(this.state.back){

            return <Navigate to="/employees" />
        }

        return (

            <Main page="employee_view" active_link="employees" error_message={this.state.error_message} success_message={this.state.success_message}>
                
                <div className="section-header">
                    <Grid container spacing={0}>

                        <Grid item xs={12} sm={4}>

                            <Link to="/employees" className="btn link">
                                <ChevronLeft />
                                <span>Back to employees</span>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={8}>

                            <div className="horizontal-tabs ml-20">
                            
                                <Tabs
                                    variant="scrollable"
                                    value={this.state.active_tab}
                                    onChange={(e, value) => {
                                        this.setState({active_tab: value});

                                        if(value == 'history'){

                                            // this.loadLead(this.state.account_token, this.props.lead_id, 'history');
                                        }
                                    }}
                                    aria-label="Employee view tabs"
                                >
                                    <Tab label="Performance" value="performance" id="vertical-tab-performance" aria-controls="vertical-tabpanel-performance" />
                                    <Tab label="Activities" value="activities" id="vertical-tab-activities" aria-controls="vertical-tabpanel-activities" />
                                    <Tab label="Leads" value="leads" id="vertical-tab-leads" aria-controls="vertical-tabpanel-leads" />
                                    <Tab label="Collections" value="collections" id="vertical-tab-collections" aria-controls="vertical-tabpanel-collections" />
                                </Tabs>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={4}>

                        <div className="info-card-primary">

                            <div className="info-card-body">

                                {employee

                                    ?
                                        <>

                                            <h2>{employee.first_name} {employee.last_name}</h2>
                                            <span className="fs-12 cg-9">Member Since: {employee.created_at_formatted}</span>
                                            <ul className="info-list">

                                                <li>
                                                    <label>Status:</label>
                                                    <span>{employee.status_label}</span>
                                                </li>
                                                <li>
                                                    <label>Job Profile:</label>
                                                    <span>{employee.job_profile}</span>
                                                </li>
                                                <li>
                                                    <label>Phone:</label>
                                                    <span>{employee.contact_number}</span>
                                                </li>
                                                <li>
                                                    <label>Email:</label>
                                                    <span>{employee.useremail}</span>
                                                </li>
                                                <li>
                                                    <label>Exam Center:</label>
                                                    <span>{employee.exam_centre}</span>
                                                </li>
                                                <li>
                                                    <label>Username:</label>
                                                    <span>{employee.username}</span>
                                                </li>
                                            </ul>

                                            <div>
                                                <Button variant="outlined" color="secondary" size="small" onClick={(e) => {

                                                    this.setState({sidebar: true})
                                                }}>Update</Button>
                                            </div>

                                        </>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                    
                        <div className="horizontal-tabs ml-20">
                            <div className="horizontal-tabs-content">
                            
                                <div
                                    value="performance"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'performance'}
                                    id="vertical-tabpanel-performance"
                                    aria-labelledby="vertical-tab-performance"
                                >
                                    <div>
                                        
                                        {(this.state.account_token && this.state.active_tab == 'performance') &&
                                            
                                            <EmployeePerformance
                                                account_token={this.state.account_token}
                                                employee_id={this.state.employee_id}
                                            />
                                        }
                                    </div>
                                </div>
                                <div
                                    value="activities"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'activities'}
                                    id="vertical-tabpanel-activities"
                                    aria-labelledby="vertical-tab-activities"
                                >
                                    <div className="tab-content">
                                        
                                        <Box component="div" whiteSpace="normal">
                                            
                                            {(this.state.account_token && this.state.active_tab == 'activities') &&
                                                
                                                <EmployeeActivities
                                                    account_token={this.state.account_token}
                                                    employee_id={this.state.employee_id}
                                                />
                                            }
                                        </Box>
                                    </div>
                                </div>
                                
                                <div
                                    value="leads"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'leads'}
                                    id="vertical-tabpanel-leads"
                                    aria-labelledby="vertical-tab-leads"
                                >
                                    <div className="tab-content">
                                        
                                        <Box component="div" whiteSpace="normal">
                                            
                                            {this.state.active_tab == 'leads' &&
                                                <EmployeeLeads
                                                    account_token={this.state.account_token}
                                                    employee_id={this.state.employee_id}
                                                />
                                            }
                                        </Box>
                                    </div>
                                </div>

                                <div
                                    value="collections"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'collections'}
                                    id="vertical-tabpanel-collections"
                                    aria-labelledby="vertical-tab-collections"
                                >
                                    <div className="tab-content">
                                        
                                        <Box component="div" whiteSpace="normal">
                                            
                                            {this.state.active_tab == 'collections' &&
                                                
                                                <CollectionsList
                                                    account_token={this.state.account_token}
                                                    employee_id={this.state.employee_id}
                                                />
                                            }
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                {this.state.sidebar &&

                    <CreateEmployee
                        open={this.state.sidebar}
                        close={() => {

                            this.setState({sidebar: false})
                        }}
                        employee_id={this.state.employee_id}
                        onSuccess={(message) => {

                            LayoutHelper.addSuccessMessage(this, message)
                        }}
                        onError={(message) => {

                            LayoutHelper.addErrorMessage(this, message)
                        }}
                    />
                }
                    
            </Main>
        )
    }

    loadEmployee = (id, account_token) => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('id', id);

        Api.post('users/single', formData, function(data){

            if(data.status){
            
                if(data.user){

                    that.setState({employee: data.user});
                    
                }else{

                    // setErrorMessage(data.message);
                    // window.setTimeout(() => {

                    //     setErrorMessage('');
                    //     setBack(true);
                    // }, 3000);
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    loadData = (account_token) => {
    
        var that = this;

        var formData = new FormData();
        formData.append('account_token', account_token);

        Api.post('general/users_init', formData, function(data){

            if(data.status){
            
                that.setState({roles: data.roles, centers: data.centers, agents: data.agents});
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default withRouter(EmployeeView);