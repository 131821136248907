import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { IconButton, Dialog, Popover, List, ListItem, ListItemText, ListItemIcon, MenuItem } from '@mui/material';

import { Edit, Call, RingVolume, MoreVert, Person, Done, PostAdd, CommentOutlined, AssignmentOutlined, MailOutline, TextsmsOutlined, Clear, Email, Forum } from '@mui/icons-material';

import DataTable from 'components/blocks/data_table/DataTable';

import Main from 'pages/sections/Main';

import LayoutHelper from 'helpers/LayoutHelper';

import Api from 'api/Api';

import LeadDuplicates from 'pages/leads//sections/LeadDuplicates';
import LeadUsers from './sections/LeadUsers';
import LeadStatus from './sections/LeadStatus';

import LeadForm from './blocks/LeadForm';

import LeadCommentForm from './blocks/LeadCommentForm';
import LeadTaskForm from './sections/LeadTaskForm';

import EmailForm from '../misc/EmailForm';
import MsgForm from '../misc/MsgForm';
import InteraktForm from '../misc/InteraktForm';

import Loader from 'components/Loader';
import NoData from 'components/blocks/NoData';
import LeadCommentRow from './blocks/LeadCommentRow';

class LeadsList extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: '',

            success_message: '',
            error_message: '',

            leads: [],
            
            do_reload: false,

            anchorEl: false,

            sources: [],
            statuses: [],
            agents: [],
            courses: [],

            lead_row: false,
            calling: false,

            active_row: '',

            agents_popover: false,

            centers: [],
            users: [],

            tasks_options: [],

            duplicate_leads_view: false,

            users_popover: false,
            status_popover: false,

            lead_form_popup: false,

            comment_popover: false,

            task_form: false,

            email_popup: false,
            sms_form: false,
            interakt_form: false,

            selected_bulk_action: false,
            close_bulk_action: false,

            selected_data: [],
            bulk_mobile_numbers: [],

            select_all: false,
            filters: {},

            comments_popup: false,
            comments_loader: false,
            comments: [],
            no_comments: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        if(account_token && user){

            this.setState({account_token: account_token});
            this.setState({user: JSON.parse(user)});
            this.init(account_token)
        }else{

            window.location.href = Api.server_url + 'logout';
        }
    }

    render () {

        var status = this.props.status;

        var post_params = [];
        var label = "Leads";

        if(status){

            post_params.push({key: 'status', value: status});

            if(status == 'open'){

                label = "Leads (Assigned, Hot, Cold)";
            }

            if(status == 'untouched'){

                label = "Untouched Leads";
            }

            if(status == 'unassigned'){

                label = "Unassigned Leads";
            }

            if(status == 'converted'){

                label = "Converted Leads";
            }
        }

        var assigned_to_row = false;
        var centre_row = false;

        if(
            this.state.user
            &&
            (this.state.user.job_profile == 'super_admin' || this.state.user.job_profile == 'center_admin')
        ){

            assigned_to_row = {name: 'Assigned to', column: 'assigned_user', sortable: false, search_type: 'match', search_input: 'dropdown', search_data: this.state.users, renderer: (_data) => {

                return <span>{_data.assigned_first_name} {_data.assigned_last_name}</span>
            }};

            centre_row = {name: 'Centre', column: 'branch', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.centers, renderer: (_data) => {

                return <span>{_data.center_name}</span>
            }};
        }
        
        return (

            <Main active_link="leads" error_message={this.state.error_message} success_message={this.state.success_message} page="leads" title={label} title_action={<Link to="/create-lead" className="btn">Create New Lead</Link>}>

                {(this.state.account_token && this.state.user) &&
                    <DataTable
                        props={{...this.props}}
                        index="leads"
                        label={label}

                        active_row={this.state.active_row}

                        bulk_actions={[
                            <MenuItem key="bulk_assign_user" value="assign_user" onClick={(e) => {

                                if(this.state.selected_data.length > 0){
                                
                                    this.setState({selected_bulk_action: 'assign_user'});
                                    this.setState({users_popover: e.currentTarget});
                                }

                            }}>Assign User</MenuItem>
                            ,
                            <MenuItem key="bulk_sms" value="bulk_sms" onClick={(e) => {

                                if(this.state.bulk_mobile_numbers.length > 0){
                                
                                    this.setState({selected_bulk_action: 'bulk_sms'});
                                    this.setState({sms_form: e.currentTarget});
                                }

                            }}>Bulk SMS</MenuItem>
                            ,
                            <MenuItem key="bulk_interakt" value="bulk_interakt" onClick={(e) => {

                                if(this.state.bulk_mobile_numbers.length > 0){
                                
                                    this.setState({selected_bulk_action: 'bulk_interakt'});
                                    this.setState({interakt_form: e.currentTarget});
                                }

                            }}>Interakt Message</MenuItem>
                        ]}
                        selected_bulk_action={this.state.selected_bulk_action}
                        close_bulk_action={this.state.close_bulk_action}
                        onBulkActionClose={() => {

                            this.setState({selected_bulk_action: false, close_bulk_action: false, bulk_mobile_numbers: []});
                        }}

                        onSelectAll={(select, filters) => {

                            this.setState({select_all: select, filters: filters})
                        }}

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false});
                        }}

                        onRowCheck={(selected_data) => {

                            this.setState({selected_data});

                            let bulk_mobile_numbers = [];

                            if(selected_data.length > 0){

                                selected_data.map((_row) => {

                                    bulk_mobile_numbers.push({mobile: _row.mobile_number, lead_id: _row.row_id});
                                })
                            }
                            
                            this.setState({bulk_mobile_numbers: bulk_mobile_numbers});
                        }}

                        columns={[
                            {name: 'action', column: 'row_id', selectable: true},
                            {name: 'Full Name', column: 'first_name', field: 'leads.first_name', sortable: true, renderer: (_data) => {

                                return (
                                    <span>
                                        {_data.first_name} {_data.last_name}
                                        {_data.duplicates_count > 0 &&
                                        
                                            <span title="Click here to view duplicate leads" onClick={(e) => {

                                                this.setState({duplicate_leads_view: e.currentTarget, client: _data})
                                            }} className="cg-7 ml-3" style={{color:'#2e6287', fontWeight:'bold', cursor: 'pointer'}}>({_data.duplicates_count})</span>
                                        }
                                    </span>
                                )
                            }},
                            {name: 'Email', column: 'email', field: 'leads.email', sortable: true},
                            {name: 'Mobile', column: 'mobile_number', field: 'leads.mobile_number', sortable: true, width: 100},
                            
                            centre_row,

                            {name: 'Source', column: 'lead_source', search_type: 'match', search_input: 'dropdown', search_data: this.state.sources, renderer: (_data) => {

                                return (
                                    <>
                                        <span className="badge bg-green">{_data.lead_source_label}</span>
                                        {_data.query_source_label != '' &&

                                            <>
                                                <br />
                                                <span className="cg-7">{_data.query_source_label}</span>
                                            </>
                                        }
                                    </>
                                )
                            }},

                            assigned_to_row,

                            // {name: 'Call Back', column: 'call_back', sortable: true, renderer: (_data) => {

                            //     if(_data.call_back == ''){

                            //         return <span>--</span>
                            //     }else if(_data.call_back == '1'){

                            //         return <span>Yes</span>
                            //     }else{

                            //         return <span>No</span>
                            //     }
                            // }},
                            {name: 'Course', column: 'course', field: 'leads.course', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.courses, renderer: (_data) => {

                                return <span>{_data.course_title}</span>
                            }},

                            {name: 'Follow Up', column: 'task_date', field: 'leads.task_date', sortable: true, search_input: 'date_range', search_type: 'date_range', renderer: (_data) => {

                                if(_data._next_task_date_formatted != ''){

                                    let type = '';

                                    if(_data._task_info != '' && _data._task_info.hasOwnProperty('t_t')){

                                        type = _data._task_info.t_t;
                                    }

                                    return  <div className='flex'>
                                                {type !== '' &&
                                                    
                                                    type === 'call'
                                                        ?
                                                            <Call className='fs-16 op-7' />
                                                        :
                                                            <Email className='fs-18 op-6' />
                                                }
                                                <div className='badge small' style={{backgroundColor: _data.lable_bg, color:'#fff', padding:'3px 5px', fontSize:10, marginLeft:2}}>
                                                    {_data._next_task_date_formatted}
                                                </div>
                                            </div>
                                }
                            }},

                            {name: 'Date', column: 'added_on', width: 200, search_input: 'date_range', search_type: 'date_range', sortable: true, field: 'leads.added_on', renderer: (_data) => {

                                return <span title={_data.added_on_formatted}>{_data.date_formatted}</span>
                            }},
                            {name: 'Status', column: 'lead_status', search_type: 'match', search_input: 'dropdown', search_data: this.state.statuses, renderer: (_data) => {

                                return <span className="badge bg-green">{_data.lead_status_label}</span>
                            }},
                        ]}
                        default_sort_by="added_on"

                        api_url="leads/load"

                        account_token={this.state.account_token}
                        
                        row_id="row_id"
                        view_url="leads/view"

                        post_params={post_params}

                        updateData={(data) => {

                            this.setState({leads: data.records})
                        }}
                        data={this.state.leads}

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <Link to={"/leads/view/" + row.row_id}>
                                            <IconButton size="small" color="primary">
                                                <Edit />
                                            </IconButton>
                                        </Link>

                                        <IconButton size="small" color="primary" onClick={(e) => {

                                            this.setState({anchorEl: e.currentTarget, client: row, lead_row: row, active_row: row_index})
                                        }}>
                                            <MoreVert />
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        }}
                    />
                }

                <LeadDuplicates
                    anchorEl={this.state.duplicate_leads_view}
                    hide={() => {

                        this.setState({duplicate_leads_view: false})
                    }}
                    row={this.state.client}
                />

                <EmailForm
                    email={this.state.lead_row ? this.state.lead_row.email : ''}
                    lead={this.state.lead_row}
                    account_token={this.state.account_token}
                    anchorEl={this.state.email_popup}
                    onCancel={() => {

                        this.setState({email_popup: false})
                    }}
                    onSuccess={() => {

                        this.setState({close_bulk_action: true, email_popup: false})
                    }}
                    setErrorMessage={(message) => {

                        LayoutHelper.addErrorMessage(this, message)
                    }}
                    setSuccessMessage={(message) => {

                        LayoutHelper.addSuccessMessage(this, message)
                    }}
                />

                {this.state.sms_form &&
                
                    <MsgForm
                        title={this.state.bulk_mobile_numbers.length > 0 ? `Sending message to ${this.state.bulk_mobile_numbers.length} students` : 'Send Message'}
                        account_token={this.state.account_token}
                        anchorEl={this.state.sms_form}
                        onCancel={() => {

                            this.setState({sms_form: false})
                        }}
                        onSuccess={() => {

                            this.setState({close_bulk_action: true, sms_form: false});

                            this.setState({selected_bulk_action: false, bulk_mobile_numbers: []});
                            this.setState({select_all: false, filters: {}})
                        }}
                        setErrorMessage={(message) => {

                            LayoutHelper.addErrorMessage(this, message)
                        }}
                        setSuccessMessage={(message) => {

                            LayoutHelper.addSuccessMessage(this, message)
                        }}
                        bulk_mobile_numbers={this.state.bulk_mobile_numbers}
                        select_all={this.state.select_all}
                        filters={this.state.filters}
                        lead={this.state.lead_row}
                        lead_id={this.state.lead_row.row_id}
                        source="leads"
                    />
                }

                {this.state.interakt_form &&
                
                    <InteraktForm
                        title={this.state.bulk_mobile_numbers.length > 0 ? `Sending message to ${this.state.bulk_mobile_numbers.length} students` : 'Send Message'}
                        account_token={this.state.account_token}
                        anchorEl={this.state.interakt_form}
                        onCancel={() => {

                            this.setState({interakt_form: false})
                        }}
                        onSuccess={() => {

                            this.setState({close_bulk_action: true, interakt_form: false});

                            this.setState({selected_bulk_action: false, bulk_mobile_numbers: []});
                            this.setState({select_all: false, filters: {}})
                        }}
                        setErrorMessage={(message) => {

                            LayoutHelper.addErrorMessage(this, message)
                        }}
                        setSuccessMessage={(message) => {

                            LayoutHelper.addSuccessMessage(this, message)
                        }}
                        bulk_mobile_numbers={this.state.bulk_mobile_numbers}
                        select_all={this.state.select_all}
                        filters={this.state.filters}
                        lead={this.state.lead_row}
                        lead_id={this.state.lead_row.row_id}
                        source="leads"
                    />
                }

                {this.state.users_popover &&
                
                    <LeadUsers
                        anchorEl={this.state.users_popover}
                        close={() => {

                            this.setState({users_popover: false})
                        }}
                        lead={this.state.lead_row}

                        selected_bulk_action={this.state.selected_bulk_action}
                        selected_data={this.state.selected_data}
                        select_all={this.state.select_all}
                        filters={this.state.filters}
                        
                        success_message={(message) => {

                            LayoutHelper.addSuccessMessage(this, message);
                        }}
                        onSuccess={(data) => {

                            if(this.state.selected_bulk_action){

                                this.setState({do_reload: true, close_bulk_action: true, users_popover: false})
                            }else{

                                let leads = this.state.leads;

                                let lead = leads.findIndex(l => l.row_id === data.lead.row_id);

                                if(lead > -1){

                                    leads[lead] = data.lead;

                                    this.setState({leads: leads});
                                }
                                this.setState({users_popover: false})
                            }
                        }}
                    />
                }

                <LeadStatus
                    anchorEl={this.state.status_popover}
                    close={() => {

                        this.setState({status_popover: false})
                    }}
                    status_options={this.state.statuses}
                    lead={this.state.lead_row}
                    success_message={(message) => {

                        LayoutHelper.addSuccessMessage(this, message);
                    }}
                    onSuccess={(data) => {

                        let leads = this.state.leads;

                        let lead = leads.findIndex(l => l.row_id === data.lead.row_id);

                        if(lead > -1){

                            leads[lead] = data.lead;

                            this.setState({leads: leads});
                        }
                        this.setState({status_popover: false})
                    }}
                />

                <Dialog 
                    onClose={() => {
                        // this.setState({calling: false})
                    }}
                    open={this.state.lead_form_popup}
                >
                    <div className="popover-confirm" style={{width:600}}>

                        <div className="header">
                            <strong>Update Lead</strong>
                        </div>
                        <div className="content" style={{padding:20}}>

                            <LeadForm
                                lead={this.state.lead_row}
                                account_token={this.state.account_token}
                                setSuccessMessage={(message) => {

                                    LayoutHelper.addSuccessMessage(this, message)
                                }}
                                onSuccess={(data) => {
            
                                    this.setState({lead_form_popup: false});

                                    let leads = this.state.leads;

                                    let lead = leads.findIndex(l => l.row_id === data.lead.row_id);

                                    if(lead > -1){

                                        leads[lead] = data.lead;

                                        this.setState({leads: leads});
                                    }
                                    this.setState({do_reload: true});
                                    this.setState({anchorEl: false, active_row: ''})
                                }}
                                onCancel={() => {

                                    this.setState({lead_form_popup: false})
                                }}
                                setErrorMessage={(message) => {
            
                                    LayoutHelper.addErrorMessage(this, message)
                                }}
                            />
                        </div>
                    </div>
                </Dialog>

                <Dialog 
                    open={this.state.task_form}
                >
                    <div className="popover-confirm" style={{width:600}}>

                        <div className="header">
                            <strong>Update Lead</strong>
                        </div>
                        <div className="content" style={{padding:20}}>

                            <LeadTaskForm
                                lead={this.state.lead_row}
                                account_token={this.state.account_token}
                                tasks_options={this.state.tasks_options}
                                showTaskForm={() => {

                                    this.setState({task_form: false})
                                }}
                                success_message={(message) => {
        
                                    LayoutHelper.addSuccessMessage(this, message)
                                    this.setState({do_reload: true})
                                }}
                                hide_form={() => {

                                    this.setState({task_form: false})
                                }}
                                error_message={(message) => {
            
                                    LayoutHelper.addErrorMessage(this, message)
                                }}
                            />
                        </div>
                    </div>
                </Dialog>
                
                <Popover
                    onClose={() => {
                        this.setState({comment_popover: false})
                    }}
                    open={this.state.comment_popover ? true : false}
                    anchorEl={this.state.comment_popover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <div className="popover-confirm" style={{width:500}}>

                        <div className="content" style={{width:460, padding:20}}>

                            <LeadCommentForm
                                account_token={this.state.account_token}
                                lead={this.state.lead_row}
                                onSuccess={(data) => {

                                    this.setState({comment_popover: false})
                                    LayoutHelper.addSuccessMessage(this, data.message);
                                }}
                            />
                        </div>
                    </div>
                </Popover>

                <Popover
                    className="button-menu"
                    open={this.state.anchorEl ? true : false}
                    anchorEl={this.state.anchorEl}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={() => {

                        this.setState({anchorEl: false, active_row: ''})
                    }}
                >
                    <div className="popover-confirm" style={{width:200}}>

                        <div className="">
                        
                            <List component="nav" dense={true}>

                                <ListItem button size="small" color="primary" onClick={(e) => {

                                    this.setState({lead_form_popup: e.currentTarget});
                                }}>
                                    <ListItemIcon>
                                        <PostAdd />
                                    </ListItemIcon>
                                    <ListItemText primary="Update" />
                                </ListItem>

                                {
                                    this.state.user.job_profile != 'super_admin'
                                    &&
                                    this.state.user.knowlarity_agent != ''
                                    ?
                                        <ListItem button size="small" color="primary" onClick={(e) => {

                                            this.initCall(this.state.user.knowlarity_agent);
                                        }}>
                                            <ListItemIcon>
                                                <Call />
                                            </ListItemIcon>
                                            <ListItemText primary="Call" />
                                        </ListItem>
                                    :
                                        <ListItem button size="small" color="primary" onClick={(e) => {

                                            this.setState({agents_popover: e.currentTarget});
                                        }}>
                                            <ListItemIcon>
                                                <Call />
                                            </ListItemIcon>
                                            <ListItemText primary="Call" />
                                        </ListItem>
                                }

                                {this.state.user && (this.state.user.job_profile == 'super_admin' || this.state.user.job_profile == 'center_admin')
                                    ?

                                        this.state.lead_row
                                            ?

                                                <ListItem button size="small" color="primary" onClick={(e) => {

                                                    this.setState({users_popover: e.currentTarget});
                                                }}>
                                                    <ListItemIcon>
                                                        <Person />
                                                    </ListItemIcon>
                                                    <ListItemText primary={this.state.lead_row.assigned_user > 0 ? 'Reassign' : 'Assign'} />
                                                </ListItem>
                                            :
                                                null
                                    :
                                        null
                                }

                                {this.state.lead_row && this.state.lead_row.lead_status != 'converted'
                                    ?
                                        <ListItem button size="small" color="primary" onClick={(e) => {

                                            this.setState({status_popover: e.currentTarget});
                                        }}>
                                            <ListItemIcon>
                                                <Done />
                                            </ListItemIcon>
                                            <ListItemText primary="Change Status" />
                                        </ListItem>
                                    :
                                        null
                                }

                                <ListItem button size="small" color="primary" onClick={(e) => {

                                    this.setState({task_form: e.currentTarget});
                                }}>
                                    <ListItemIcon>
                                        <AssignmentOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Add Task" />
                                </ListItem>

                                <ListItem button size="small" color="primary" onClick={(e) => {

                                    this.setState({comment_popover: e.currentTarget});
                                }}>
                                    <ListItemIcon>
                                        <CommentOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Add Comment" />
                                </ListItem>

                                <ListItem button size="small" color="primary" onClick={(e) => {

                                        this.setState({comments_popup: e.currentTarget}, () => {

                                            this.loadComments()
                                        });
                                    }}>
                                    <ListItemIcon>
                                        <Forum />
                                    </ListItemIcon>
                                    <ListItemText primary="View Comments" />
                                </ListItem>

                                <ListItem button size="small" color="primary" onClick={(e) => {

                                    this.setState({email_popup: e.currentTarget});
                                }}>
                                    <ListItemIcon>
                                        <MailOutline />
                                    </ListItemIcon>
                                    <ListItemText primary="Send Email" />
                                </ListItem>

                                <ListItem button size="small" color="primary" onClick={(e) => {

                                    this.setState({sms_form: e.currentTarget});
                                }}>
                                    <ListItemIcon>
                                        <TextsmsOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Send SMS" />
                                </ListItem>

                                <ListItem button size="small" color="primary" onClick={(e) => {

                                        this.setState({interakt_form: e.currentTarget});
                                    }}>
                                    <ListItemIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
                                    </ListItemIcon>
                                    <ListItemText primary="Interakt Message" />
                                </ListItem>
                            </List>
                        </div>
                    </div>
                </Popover>

                <Popover
                    onClose={() => {
                        this.setState({comments_popup: false})
                    }}
                    open={this.state.comments_popup ? true : false}
                    anchorEl={this.state.comments_popup}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <div className="popover-confirm" style={{width: 500}}>

                        <div className="header space-between" style={{width: 'auto'}}>
                            <strong>Comments</strong>

                            <IconButton size="small" onClick={() => {

                                this.setState({comments_popup: false}, () => {

                                    this.setState({comments: []})
                                })
                            }}>
                                <Clear />
                            </IconButton>
                        </div>

                        <div className="content" style={{minHeight:150, position: 'relative'}}>

                            {this.state.no_comments &&
                                
                                <NoData size="small" hide_image={true} icon={<Forum />} message="Comments not found!" />
                            }

                            {this.state.comments && this.state.comments.length > 0
                                ?
                                    <List>
                                        {this.state.comments.map((comment) => {

                                            return (
                                                <ListItem key={`comment-${comment.id}`}>
                                                    <ListItemText primary={<div className='space-between'><strong>{comment.first_name} {comment.last_name}</strong><span className='cg-7' title={comment.added_on_formatted}>{comment.time_elapsed}</span></div>} secondary={<LeadCommentRow comment={comment} />} />
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                :
                                    null
                            }

                            <Loader loading={this.state.comments_loader} />
                        </div>
                    </div>
                </Popover>

                <Popover
                    onClose={() => {
                        this.setState({agents_popover: false})
                    }}
                    open={this.state.agents_popover ? true : false}
                    anchorEl={this.state.agents_popover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <div className="popover-confirm">

                        <div className="content" style={{maxHeight:250, minHeight:150}}>

                            {this.state.agents && this.state.agents.length > 0
                                ?
                                    <List>
                                        {this.state.agents.map((agent) => {

                                            return (
                                                <ListItem button onClick={() => {

                                                    this.initCall(agent.agent_id);

                                                }} key={`agent-${agent.agent_id}`}>
                                                    <ListItemText primary={agent.first_name + ' ' + agent.last_name} />
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                :
                                    null
                            }
                        </div>
                    </div>
                </Popover>

                <Dialog 
                    onClose={() => {
                        // this.setState({calling: false})
                    }}
                    aria-labelledby="simple-dialog-title"
                    open={this.state.calling}
                >
                    <div className="status-dialog-container">
                        <div className="status-dialog">
                            <div className="dialog-icon">
                                <RingVolume />
                            </div>
                            <div className="dialog-text">
                                <h2 className="cg-4" style={{color:'#1cb86a'}}>CALLING</h2>
                                <h4>{this.state.lead_row ? this.state.lead_row.first_name : ''} {this.state.lead_row ? this.state.lead_row.last_name : ''}</h4>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Main>
        )
    }

    initCall = (agent_id) => {

        this.setState({calling: true});

        var formData = new FormData();

        formData.append('account_token', this.state.account_token);
        formData.append('lead', this.state.lead_row.row_id);

        if(agent_id){

            formData.append('agent', agent_id);
        }

        var that = this;

        Api.post('leads/init_call', formData, function(data){

            if(data.status){
            
                window.setTimeout(() => {

                    that.setState({calling: false});
                }, 5000);
                
            }else{

                that.setState({calling: false});

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    LayoutHelper.addErrorMessage(that, data.message);
                }
            }
        });
    }
    
    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('general/leads_init', formData, function(data){

            if(data.status){

                var centers = that.state.centers;

                var _centres = data.centers;

                for(var r in _centres){

                    centers.push({key: _centres[r]['id'], value: _centres[r]['name']});
                }
            
                that.setState({
                    sources: data.sources, 
                    statuses: data.statuses,
                    agents: data.agents,
                    centers: centers,
                    users: data.users,
                    tasks_options: data.tasks_options,
                    courses: data.courses
                });
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }

    loadComments = () => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', this.state.account_token);
        formData.append('lead', this.state.lead_row.row_id);
        formData.append('per_page', 3);

        this.setState({comments_loader: true});

        Api.post('leads/comments/load', formData, function(data){

            that.setState({comments_loader: false});
            
            if(data.records.length > 0){

                that.setState({comments: data.records, no_comments: false})
            }else{

                that.setState({comments: data.records, no_comments: true})
            }
        });
    }
}

export default LeadsList;
