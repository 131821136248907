import React, { Component } from 'react';

import { Grid } from '@mui/material';

import { format } from 'date-fns'

import DataTable from 'components/blocks/data_table/DataTable';

class TodaysComments extends Component { 
    constructor(props) {
        super();
        this.state = {

            loader: false,

            // no_comments: false,

            // total_comments: 0,

            // users: [],

            post_params: [
                {key:'end_date', value: format(new Date(), "yyyy-MM-dd")},
                {key:'start_date', value: format(new Date(), "yyyy-MM-dd")},
            ],
        }
    }

    componentDidMount = () => {

        // this.loadData();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        // if(
        //     this.props.start_date !== prevProps.start_date
        //     ||
        //     this.props.end_date !== prevProps.end_date
        // ){

        //     this.loadData();
        // }
    }

    // values = (source) => {

    //     let calls_data = this.state.calls_data;

    //     const value = calls_data.find(node => node.c == source.key);

    //     if(value){
        
    //         return <div><strong>{value.y}</strong> <span className="ml-3">({value.z}%)</span></div>;
    //     }else{

    //         return <div style={{height:23, lineHeight:1}}><strong style={{opacity:.4, fontSize:20}}>0</strong></div>;;
    //     }
    // }

    render () {

        return (

            <>

                <Grid container spacing={0} alignItems="flex-start">

                    <Grid item xs={12} sm={12}>

                        {/* <label className="cg-5 fs-16">Total: <strong className="fs-16 cg-7">{this.state.total_comments}</strong></label>
                        
                        <div className="mt-30">

                            {this.state.no_comments &&
                            
                                <NoData icon={<Chat style={{fontSize:240, color:'rgba(0,0,0,.04)'}} />} style={{margin:0}} size="small" message="Comments not available!" hide_image={true} />
                            }

                            {this.state.users.length > 0 &&
                            
                                <div>

                                    <label className="cg-5 fs-16">Comments by Employees</label>

                                    <TableContainer component={Paper} style={{padding:20, marginTop:10}}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Contact</TableCell>
                                                    <TableCell align="right">Comments</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {this.state.users.map((_user, index) => {

                                                    return (
                                                        <TableRow key={`row_${index}`}>
                                                        
                                                            <TableCell>{`${_user.first_name} ${_user.last_name}`}</TableCell>
                                                            <TableCell>{_user.contact_number}</TableCell>
                                                            <TableCell align="right">{_user.total}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            }
                        </div> */}

                        <DataTable
                                index="lead_comments"
                                label="Today's Comments"

                                columns={[
                                    {name: 'Name', column: 'leads.first_name', sortable: true, renderer: (_data) => {

                                        return <span>{_data.first_name} {_data.last_name}</span>
                                    }},
                                    {name: 'Contact', column: 'mobile_number', sortable: true},
                                    {name: 'Comment', column: 'comment', sortable: true},

                                    {name: 'Lead By', column: 'users.first_name', sortable: true, renderer: (_data) => {

                                        return <span>{_data.emp_first_name} {_data.emp_last_name}</span>
                                    }},
                                ]}

                                default_sort_by="added_on"

                                api_url="leads/user_leads_comments_today"

                                account_token={this.props.account_token}

                                post_params={this.state.post_params}
                                
                                row_id="id"
                            />
                    </Grid>

                    <Grid item xs={12} sm={1}></Grid>
                </Grid>

            </>
        )
    }

    // loadData = () => {

    //     var that = this;

    //     var formData = new FormData();
    //     formData.append('account_token', this.props.account_token);
    //     formData.append('start_date', format(this.props.start_date, "yyyy-MM-dd"));
    //     formData.append('end_date', format(this.props.end_date, "yyyy-MM-dd"));
    //     formData.append('todays', 1);

    //     this.setState({loader: true});

    //     Api.post('reports/comments/todays', formData, function(data){

    //         that.setState({loader: false});

    //         if(data.status){
            
    //             that.setState({users: data.users, total_comments: data.total_comments});

    //             if(data.total_comments <= 0){

    //                 that.setState({no_comments: true})
    //             }else{

    //                 that.setState({no_comments: false})
    //             }
    //         }else{

    //             if(data.code && data.code == 'no_account'){

    //                 window.location = Api.server_url + 'logout';
    //             }
    //         }
    //     });
    // }
}

export default TodaysComments;
