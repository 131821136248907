import React, { useEffect, useState } from 'react';

import { Grid, Box, Button, FormControl, TextField, Select, MenuItem, InputLabel, Switch, FormGroup, FormControlLabel } from '@mui/material';

// import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
// import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Api from 'api/Api';

import Loader from 'components/Loader';
import TaskRemindOptions from '../blocks/TaskRemindOptions';

function LeadTaskForm (props){ 

    const [reminder, setReminder] = useState(true);
    const [loading, setLoading] = useState(false);

    const [task_form, showTaskForm] = useState(false);

    const [remind_by, setRemindBy] = useState(['email', 'sms']);

    const [loaded, setLoaded] = useState(false);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [per_page, setPerPage] = useState(0);
    const [tasks, setTasks] = useState([]);

    const [loading_tasks, setLoadingTasks] = useState(false);

    var lead = props.lead;

    useEffect(() => {
        
        if(!loaded){
        
            if(lead){
            
                setLoaded(true)
            }
        }
    });

    const validationSchema = yup.object({
        task: yup.string().required('Task input is required!'),
        schedule_date: yup.date().required('Date is required!'),
        schedule_time: yup.string().required('Time is required!'),
    });

    const formik = useFormik({
        initialValues: {
            task: '',
            schedule_date: null,
            schedule_time: null,
            reminder: '15',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            var formData = new FormData();

            Object.keys(values).forEach((key) => {

                var value = values[key];

                if(key == 'schedule_date'){

                    value = format(values[key], 'yyyy-MM-dd');
                }

                if(key == 'schedule_time'){
    
                    value = format(values[key], 'HH:mm:ss');
                }

                formData.append(key, value);
            })
            
            formData.append('account_token', props.account_token);
            formData.append('lead_id', props.lead.row_id);
            formData.append('remind_by', JSON.stringify(remind_by));

            setLoading(true);

            Api.post('leads/tasks/create', formData, function(data){

                setLoading(false);

                if(data.status){
                
                    formik.resetForm();
                    
                    props.showTaskForm(false);

                    setRemindBy([]);
                    
                    props.success_message(data.message);
                    
                }else{

                    if(data.code && data.code == 'no_account'){

                        window.location = Api.url + 'logout';
                    }else{

                        props.error_message(data.message);
                    }
                }
            });
        },
    });

    return (

        <div className="center-form-container">
        
            <div className="center-form medium">

                <h3 className="heading">Add new task</h3>

                <form className="form" name="login_form" onSubmit={formik.handleSubmit}>

                    <Grid container spacing={5}>

                        <Grid item xs={12} sm={8}>
                                
                            <FormControl fullWidth={true} error={formik.touched.task && Boolean(formik.errors.task)}>
                                <InputLabel id="task_label">Task *</InputLabel>
                                <Select
                                    labelId="task_label"
                                    value={formik.values.task}
                                    name="task"
                                    onChange={formik.handleChange}
                                    variant='standard'
                                >
                                    {props.tasks_options && props.tasks_options.length > 0
                                        ?
                                            props.tasks_options.map((_tasks_option) => {

                                                return <MenuItem key={_tasks_option.key} value={_tasks_option.key}>{_tasks_option.task}</MenuItem>
                                            })
                                        :
                                            null
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={6}>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                <DatePicker
                                    disableToolbar
                                    variant="standard"
                                    format="MM/dd/yyyy"
                                    inputVariant="standard"
                                    // label="Valid From Date"
                                    value={formik.values.schedule_date}
                                    onChange={(e, date) => {
                                        
                                        formik.setFieldValue("schedule_date", e)
                                    }}
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item sm={6}>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>

                                <TimePicker
                                    disableToolbar
                                    variant="standard"
                                    format="hh:mm aa"
                                    inputVariant="standard"
                                    // label="Valid From Date"
                                    value={formik.values.schedule_time}
                                    onChange={(e, date) => {
                                        
                                        formik.setFieldValue("schedule_time", e)
                                    }}
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <Grid item sm={4}>

                                <FormControl fullWidth={true} error={formik.touched.schedule_date && Boolean(formik.errors.schedule_date)}>
                                    <DatePicker
                                        autoOk
                                        error={formik.touched.schedule_date && Boolean(formik.errors.schedule_date)}
                                        minDate={new Date()}
                                        name="schedule_date"
                                        format="dd/MM/yyyy"
                                        label="Date *"
                                        format="dd/MM/yyyy"
                                        value={formik.values.schedule_date}
                                        placeholder="dd/mm/yyyy"
                                        variant='standard'
                                        onChange={date => {
                                            formik.setFieldValue("schedule_date", date)
                                        }}
                                    />

                                    {(formik.touched.schedule_date && Boolean(formik.errors.schedule_date)) &&

                                        <FormHelperText>Required entry</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>

                            <Grid item sm={4}>

                                <FormControl fullWidth={true} error={formik.touched.schedule_time && Boolean(formik.errors.schedule_time)}>
                                    <TimePicker
                                        error={formik.touched.schedule_time && Boolean(formik.errors.schedule_time)}
                                        name="schedule_time"
                                        label="Time *"
                                        format="hh:mm aa"
                                        minutesStep={5}
                                        value={formik.values.schedule_time}
                                        placeholder="dd/mm/yyyy"
                                        variant='standard'
                                        onChange={time => {
                                            formik.setFieldValue("schedule_time", time)
                                        }}
                                    />

                                    {(formik.touched.schedule_time && Boolean(formik.errors.schedule_time)) &&

                                        <FormHelperText>Required entry</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                        </MuiPickersUtilsProvider> */}

                        <Grid item xs={12} sm={4}>
                                
                            <FormGroup style={{marginTop:15}}>
                                <FormControlLabel size="small"
                                    control={<Switch size="small" checked={reminder} onChange={(e) => {
                                        setReminder(e.target.checked)
                                    }} />}
                                    label="Reminder"
                                />
                            </FormGroup>
                        </Grid>

                        {reminder &&
                            <Grid item xs={12} sm={4}>
                                    
                                <FormControl fullWidth={true} error={formik.touched.reminder && Boolean(formik.errors.reminder)}>
                                    <InputLabel id="reminder_before">Before task</InputLabel>
                                    <Select
                                        disabled={!reminder}
                                        labelId="reminder_before"
                                        value={formik.values.reminder}
                                        name="reminder"
                                        onChange={formik.handleChange}
                                        variant='standard'
                                    >
                                        <MenuItem value={15}>15 minutes</MenuItem>
                                        <MenuItem value={30}>30 minutes</MenuItem>
                                        <MenuItem value={60}>1 hour</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        {reminder &&

                            <Grid item xs={12} sm={12}>

                                <TaskRemindOptions
                                    onUpdate={(e) => {

                                        setRemindBy(e)
                                    }}
                                />
                            </Grid>
                        }

                        <Grid item xs={12} sm={12}>
                                
                            <FormControl fullWidth={true}>
                                <TextField
                                    multiline
                                    rows={3}
                                    value={formik.values.details}
                                    name="details"
                                    label="Details"
                                    onChange={formik.handleChange}
                                    fullWidth={true}
                                    autoComplete="off"
                                    variant='standard'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} sm={12}>

                            <div className="action-sm">
                            
                                <Button size="small" type="button" onClick={(e) => {

                                    formik.resetForm();
                                    props.hide_form()
                                }}>Cancel</Button>
                        
                                <Button color="primary" variant="contained" size="small" type="submit">Create</Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>

                <Loader loading={loading} />
            </div>
        </div>
    )
}

export default LeadTaskForm;