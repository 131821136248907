import React, { Component } from 'react';

import { Navigate, useParams } from "react-router-dom";

export function withRouter(Children){

    return(props) => {

        const params = {params: useParams()};

        return <Children {...props} params={params} />
    }
}

class Redirector extends Component { 
    
    constructor(props) {
        super();
        this.state = {

            redirect: false
        }
    }

    componentDidMount = () => {

        const path = this.props.params.params.path;

        this.setState({redirect: path})
    }

    render () {
            
        if(this.state.redirect){
        
            return <Navigate to={"/" + this.state.redirect} />;
        }else{

            return null
        }
    }
}

export default withRouter(Redirector)