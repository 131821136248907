import React, { Component } from 'react';

import { Grid, Avatar, Drawer, IconButton, Button } from '@mui/material';

import { VictoryPie } from 'victory';

import { format, subMonths } from 'date-fns'

import Api from 'api/Api';

import NoData from 'components/blocks/NoData';
import Loader from 'components/Loader';

import { PieChart, TrainRounded, Clear } from '@mui/icons-material';

import Calls_drawer from 'components/blocks/drawers/Calls';
import Comments_drawer from 'components/blocks/drawers/Comments';
// import DateRangeComponent from '../reports/DateRangeComponent';

class EmployeePerformance extends Component { 
    constructor(props) {
        super();
        this.state = {

            assigned_leads: 0,
            open_leads: 0,
            converted_leads: 0,
            comments: 0,

            assigned_leads_formatted: 0,
            open_leads_formatted: 0,
            converted_leads_formatted: 0,
            comments_formatted: 0,

            assigned_courses: 0,
            assigned_courses_formatted: 0,

            chart_loader: false,

            end_date: new Date(),
            start_date: subMonths(new Date(), 1),
            
            post_params: [
                {key:'end_date', value: format(new Date(), "yyyy-MM-dd")},
                {key:'start_date', value: format(subMonths(new Date(), 1), "yyyy-MM-dd")},
                {key:'list_type', value: 'answered'},
            ],

            total_fees: 0,
            total_collection: 0,

            total_fees_formatted: '',
            total_collection_formatted: '',

            fee_data: [],
            no_fee_data: false,

            missed: 0,
			received: 0,
            outbound_calls: 0,
            drawer: false,

            do_reload: false,

            list_type: 'answered',

            comments_drawer: false,
        }
    }

    componentDidMount = () => {

        if(this.props.account_token && this.props.employee_id){
        
            this.load(this.props.account_token, this.props.employee_id);
            this.loadSales(this.props.account_token, this.props.employee_id)

            let post_params = this.state.post_params;

            post_params.push({key: 'employee_id', value: this.props.employee_id});

            this.setState({post_params: post_params})
        }
    }

    formatLabel = (key) => {

        let num = this.state[key];

        let num_label = '';

        if(parseInt(num) > 999){

            num = this.state[`${key}_formatted`];

            let parts = num.split(' ');
            num_label = <span className="align-center fs-18"><b>{parts[0]}</b><b className="fs-18" style={{marginLeft:2}}>{parts[1]}</b></span>
        }else{

            num_label = <span>{num}</span>
        }

        return <Avatar style={{width: 60, height: 60}}>{num_label}</Avatar>
    }

    updateKeyValue = (post_params, key, value) => {

        const _key_index = post_params.findIndex(row => row.key === key);

        if(_key_index){

            post_params[_key_index]['value'] = value;
        }else{

            post_params.push({key:key, value: value});
        }

        return post_params;
    }

    render () {
        
        return (

            <>

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>

                        <div className="align-end">
                        
                            {/* <DateRangeComponent
                                update={(start_date, end_date) => {

                                    let post_params = this.state.post_params;

                                    post_params = this.updateKeyValue(post_params, 'end_date', format(end_date, "yyyy-MM-dd"));
                                    post_params = this.updateKeyValue(post_params, 'start_date', format(start_date, "yyyy-MM-dd"));

                                    this.setState({start_date: start_date, end_date: end_date, post_params: post_params}, () => {

                                        this.loadSales(this.props.account_token, this.props.employee_id);
                                        this.load(this.props.account_token, this.props.employee_id);
                                    });
                                }}
                                start_date={this.state.start_date}
                                end_date={this.state.end_date}
                                right={true}
                            /> */}
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={5}>

                    <Grid item xs={12} sm={4}>
                
                        <div className="stats-card">
                            <div>
                                {this.formatLabel('assigned_leads')}
                            </div>
                            <div className="stats-data">
                                <h4>Assigned</h4>
                                <h5>Leads</h5>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                
                        <div className="stats-card">
                            <div>
                                {this.formatLabel('open_leads')}
                            </div>
                            <div className="stats-data">
                                <h4>Open</h4>
                                <h5>Leads</h5>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                
                        <div className="stats-card">
                            <div>
                                {this.formatLabel('converted_leads')}
                            </div>
                            <div className="stats-data">
                                <h4>Converted</h4>
                                <h5>Leads</h5>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                
                        <div className="stats-card">
                            <div>
                                {this.formatLabel('assigned_courses')}
                            </div>
                            <div className="stats-data">
                                <h4>Courses</h4>
                                <h5>Assigned</h5>
                            </div>
                        </div>

                        <div className="stats-card mt-30">
                            <div className="stats-data" style={{width:'100%'}}>

                                <Button onClick={() => {

                                    let post_params = this.state.post_params;

                                    post_params = this.updateKeyValue(post_params, 'list_type', 'answered');

                                    this.setState({list_type: 'answered', post_params: post_params}, () => {

                                        this.setState({drawer: true})
                                    })
                                }} style={{width:'100%'}}>
                                    <div className='space-between vertical' style={{width: '100%'}}>
                                        <h4 className="space-between" style={{width:'100%'}}><strong>{this.state.received}</strong> <span className="ml-5 c-g" style={{textTransform:'capitalize'}}>Answered</span></h4>
                                        <h4 className="space-between" style={{width:'100%'}}><strong>{this.state.missed}</strong> <span className="ml-5 c-r" style={{textTransform:'capitalize'}}>Missed</span></h4>
                                    </div>
                                </Button>
                                <hr className="op-4 mt-10 mb-10" />

                                <Button onClick={() => {

                                    let post_params = this.state.post_params;

                                    post_params = this.updateKeyValue(post_params, 'list_type', 'outbound');

                                    this.setState({list_type: 'outbound', post_params: post_params}, () => {

                                        this.setState({drawer: true})
                                    })
                                }} style={{width:'100%'}}>
                                    <h4 className="space-between" style={{width:'100%'}}><strong>{this.state.outbound_calls}</strong> <span className="ml-5 c-p" style={{textTransform:'capitalize'}}>Outbound</span></h4>
                                </Button>
                            </div>
                        </div>

                        <div className="stats-card mt-30">
                            <Button onClick={() => {

                                this.setState({comments_drawer: true});
                            }} style={{width:'100%'}}>
                                <div>
                                    {this.formatLabel('comments')}
                                </div>
                                <div className="stats-data">
                                    <h4>Lead</h4>
                                    <h5>Comments</h5>
                                </div>
                            </Button>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={8}>
                
                        <div className="chart-block">
                            <div className="chart-block-heading flex space-between">
                                <b>Sales</b>
                            </div>

                            <div className="chart-container flex vertical">

                                {(this.state.total_fees_formatted != '' && this.state.total_fees_formatted != '') &&
                                
                                    <div className="flex mt-10">
                                        <div className="flex">
                                            <label className="cg-5 fs-12">Revenue: </label><strong className="fs-12 cg-7 ml-3">Rs. {this.state.total_fees_formatted}</strong>
                                        </div>
                                        <div className="flex ml-10 ml-5">
                                            <label className="cg-5 fs-12">Collection: </label><strong className="fs-12 cg-7 ml-3">Rs. {this.state.total_collection_formatted}</strong>
                                        </div>
                                    </div>
                                }

                                <Loader loading={this.state.chart_loader} />

                                {(!this.state.chart_loader && !this.state.no_fee_data ) &&
                                
                                    <VictoryPie
                                        height={280}
                                        data={this.state.fee_data}
                                        colorScale={["#440a67", "#ffe3fe"]}
                                        innerRadius={30}
                                        // animate={true}
                                    />
                                }

                                {this.state.no_fee_data &&
                            
                                    <NoData icon={<PieChart style={{fontSize:170, color:'rgba(0,0,0,.04)'}} />} style={{margin:0}} size="small" message="Data not available!" hide_image={true} />
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>


                <Calls_drawer
                    account_token={this.props.account_token}
                    drawer={this.state.drawer}
                    list_type={this.state.list_type}
                    post_params={this.state.post_params}
                    closeDrawer={() => {

                        this.setState({drawer: false})
                    }}
                />

                <Comments_drawer
                    account_token={this.props.account_token}
                    comments_drawer={this.state.comments_drawer}
                    list_type={this.state.list_type}
                    post_params={this.state.post_params}
                    closeDrawer={() => {

                        this.setState({comments_drawer: false})
                    }}
                />

                {/* <Drawer anchor="right" open={this.state.comments_drawer} onClose={() => {
                    this.setState({comments_drawer: false})
                }}>
                    <div className="drawer-container">
                        <div className="bg-primary drawer-heading">
                            <h2>Lead Comments</h2>

                            <IconButton size="small" onClick={() => {
                                this.setState({comments_drawer: false})
                            }}>
                                <Clear style={{color:'rgba(0,0,0,.7)'}} />
                            </IconButton>
                        </div>
                    
                        <div className="drawer-body">

                            <div className="drawer-content">

                                <DataTable
                                    index="lead_comments"
                                    label="Lead Comments"

                                    columns={[
                                        {name: 'Name', column: 'first_name', sortable: true, renderer: (_data) => {

                                            return <span>{_data.first_name} {_data.last_name}</span>
                                        }},
                                        {name: 'Contact', column: 'mobile_number', sortable: true},
                                        {name: 'Comment', column: 'comment', sortable: true},

                                        {name: 'Added On', column: 'added_on', sortable: true, renderer: (_data) => {

                                            return <span>{_data.added_on_formatted}</span>
                                        }},
                                    ]}

                                    default_sort_by="added_on"

                                    api_url="leads/user_leads_comments"

                                    account_token={this.props.account_token}

                                    post_params={this.state.post_params}
                                    
                                    row_id="id"
                                />

                            </div>
                        </div>

                    </div>
                </Drawer> */}
            </>
        )
    }

    load = (account_token, employee_id) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);
        formData.append('employee_id', employee_id);
        formData.append('start_date', format(this.state.start_date, "yyyy-MM-dd"));
        formData.append('end_date', format(this.state.end_date, "yyyy-MM-dd"));

        Api.post('users/stats', formData, function(data){

            if(data.status){
            
                that.setState({
                    assigned_leads: data.assigned_leads,
                    converted_leads: data.converted_leads,
                    open_leads: data.open_leads,
                    assigned_courses: data.assigned_courses,
                    comments: data.comments,

                    assigned_leads_formatted: data.assigned_leads_formatted,
                    open_leads_formatted: data.open_leads_formatted,
                    converted_leads_formatted: data.converted_leads_formatted,
                    assigned_courses_formatted: data.assigned_courses_formatted,
                    comments_formatted: data.comments_formatted,

                    missed: data.missed,
                    received: data.received,

                    outbound_calls: data.outbound_calls
                });
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    loadSales = (account_token, employee_id) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);
        formData.append('employee_id', employee_id);
        formData.append('start_date', format(this.state.start_date, "yyyy-MM-dd"));
        formData.append('end_date', format(this.state.end_date, "yyyy-MM-dd"));

        this.setState({chart_loader: TrainRounded})

        Api.post('users/sales', formData, function(data){

            that.setState({chart_loader: false});

            if(data.status){
            
                if(data.total_fees > 0 || data.total_collection > 0){

                    that.setState({
                        fee_data: data.fee_data,
                        total_fees: data.total_fees,
                        total_collection: data.total_collection,
                        no_fee_data: false,
                        total_fees_formatted: data.total_fees_formatted,
                        total_collection_formatted: data.total_collection_formatted
                    });
                }else{

                    that.setState({
                        fee_data: data.fee_data,
                        total_fees: data.total_fees,
                        total_collection: data.total_collection,
                        no_fee_data: true,
                        total_fees_formatted: '',
                        total_collection_formatted: ''
                    });
                }
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default EmployeePerformance;