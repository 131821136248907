import React, { Component } from 'react';

import DataTable from 'components/blocks/data_table/DataTable';

class CollectionsList extends Component { 
    constructor(props) {
        super();
        this.state = {

            loader: true,
            
            post_params: [],

            do_reload: false,
        }
    }

    componentDidMount = () => {

        var post_params = [];

        if(this.props.employee_id != ''){

            post_params.push({key:'employee_id', value:this.props.employee_id});

            if(this.props.source){

                post_params.push({key:'source', value:this.props.source});    
            }

            if(this.props.start_date){

                post_params.push({key:'start_date', value:this.props.start_date});    
            }

            if(this.props.end_date){

                post_params.push({key:'end_date', value:this.props.end_date});    
            }
        }

        this.setState({post_params: post_params});
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(
            this.props.start_date && (this.props.start_date !== prevProps.start_date)
            ||
            this.props.employee_id && (this.props.employee_id !== prevProps.employee_id)
        ){

            var post_params = [];

            if(this.props.source){

                post_params.push({key:'source', value:this.props.source});    
            }

            if(this.props.start_date){

                post_params.push({key:'start_date', value:this.props.start_date});    
            }

            if(this.props.end_date){

                post_params.push({key:'end_date', value:this.props.end_date});    
            }

            post_params.push({key:'employee_id', value:this.props.employee_id});

            this.setState({post_params: post_params}, () => {

                this.setState({do_reload: true})
            });
        }
    }

    render () {

        return (

            <>

                {this.props.employee_id &&
                
                    <DataTable
                        index="student_details"
                        label="Student Details"

                        columns={[
                            {name: 'Student', column: 'name', sortable: true},
                            {name: 'Contact', column: 'contact_no', sortable: true},
                            {name: 'Course', column: 'course_title', sortable: true},
                            {name: 'Batch', column: 'batch_title', sortable: true},
                            {name: 'Centre', column: 'exam_centre_name', sortable: true},
                            {name: 'Amount', column: 'fees_paid', sortable: true, renderer: (_data) => {

                                return (
                                    <div className="vertical">
                                        <span>{_data.fees_paid}</span>

                                        {_data.emi_no > 0 && <span className='op-5'>EMI: {_data.emi_no}</span>}
                                        {_data.payment_ref_no !== '' && <span className='op-5'>Ref: {_data.payment_ref_no}</span>}
                                    </div>
                                )
                            }},
                            {name: 'Method', column: 'payment_mode', sortable: true, renderer: (_data) => {

                                return <span>{_data.payment_mode_label}</span>
                            }},

                            {name: 'Date', column: 'payment_date', sortable: true, renderer: (_data) => {

                                return <span>{_data.payment_date_formatted}</span>
                            }},

                        ]}

                        default_sort_by="payment_date"

                        api_url="reports/collections/employee_wise_detailed"

                        account_token={this.props.account_token}

                        do_reload={this.state.do_reload}
                        relaodDone={() => {
                            
                            this.setState({do_reload: false})
                        }}
                        
                        post_params={this.state.post_params}
                        
                        row_id="id"
                    />
                }
            </>
        )
    }
}

export default CollectionsList;
