import React, { Component } from 'react';

import { TextField, Table, TableBody, TableHead, TableCell, TableRow, Button, IconButton, Switch, FormControlLabel } from '@mui/material';

// import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Add, Clear } from '@mui/icons-material';

class InstallmentRow extends Component {
    constructor(props) {
        super();
        this.state = {

            installment_rows: [
                {'num': 1, 'amount': '', 'date' : new Date(), 'selected_date': new Date(), 'paid': 0}
            ]
        }
    }

    componentDidMount = () => {

        this.props.updateInstallments(this.state.installment_rows)
    }

    render () {

        return (

            <div>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>EMI</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderTable()}
                    </TableBody>
                </Table>

                <Button startIcon={<Add />} size="small" color="primary" style={{marginTop:10}} onClick={() => {

                    this.addNewRow()
                }}>
                    Add EMI Row
                </Button>
            </div>
        )
    }

    renderTable = () => {

        var installment_rows = this.state.installment_rows;

        var installments_html = [];

        if(installment_rows.length){

            for(var i in installment_rows){

                installments_html.push(this.renderRow(i))
            }
        }

        return installments_html;
    }

    addNewRow = () => {

        var installment_rows = this.state.installment_rows;

        installment_rows.push({'num': (installment_rows.length + 1), 'amount': '', 'date' : new Date(), 'selected_date' : new Date(), 'paid': 0});

        this.setState({installment_rows: installment_rows}, () => {

            this.props.updateInstallments(installment_rows)
        });
    }

    updateRowDate = (i, date) => {

        var installment_rows = this.state.installment_rows;

        if(installment_rows.indexOf(i)){

            installment_rows[i]['date'] = date;

            installment_rows[i]['selected_date'] = format(date, "yyyy-MM-dd");;
        }

        this.setState({installment_rows: installment_rows});

        this.props.updateInstallments(installment_rows)
    }

    updateAmount = (i, amount) => {

        var installment_rows = this.state.installment_rows;

        if(installment_rows.indexOf(i)){

            installment_rows[i]['amount'] = amount;
        }

        this.setState({installment_rows: installment_rows});

        this.props.updateInstallments(installment_rows);
    }

    renderRow = (i) => {

        var installment_rows = this.state.installment_rows;
        
        var date = new Date();
        var amount = '';

        if(installment_rows.indexOf(i)){

            date = installment_rows[i]['date'];
            amount = installment_rows[i]['amount'];
        }

        return (
            <TableRow key={"installment_row_" + i}>
                <TableCell width="15%" style={{padding:'6px 10px 6px 5px'}}>
                    <b className="align-start">{parseInt(i) + 1} {(parseInt(i) + 1) == 1 ? 'st' : (parseInt(i) + 1) == 2 ? 'nd' : ((parseInt(i) + 1) == 3 ? 'rd' : 'th')}</b>
                </TableCell>
                <TableCell width="25%" style={{padding:'6px 10px 6px 5px'}}>
                    <TextField
                        variant="outlined"
                        value={amount}
                        label="EMI *"
                        size="small"
                        onChange={(e) => {

                            if(!isNaN(e.target.value)){

                                this.updateAmount(i, e.target.value)
                            }
                        }}
                        fullWidth={true}
                        autoComplete="off"
                    />
                </TableCell>
                <TableCell width="55%" style={{padding:'6px 10px 6px 5px'}}>

                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>

                        <DatePicker
                            variant="inline"
                            autoOk
                            inputVariant="outlined"
                            // minDate={subDays(new Date(), 30)}
                            minDate={sub(new Date, {months: 1})}
                            label="Date *"
                            format="dd/MM/yyyy"
                            size="small"
                            value={date}
                            placeholder="dd/mm/yyyy"
                            onChange={date => {

                                this.updateRowDate(i, date);
                            }}
                        />
                    </MuiPickersUtilsProvider> */}
                </TableCell>
                <TableCell width="15%" style={{padding:'6px 10px 6px 5px'}}>
                    
                    {i > 0
                        ?
                            <IconButton size="small" onClick={() => {

                                var installment_rows = this.state.installment_rows;
                                installment_rows.splice(installment_rows.indexOf(i), 1);

                                this.setState({installment_rows: installment_rows});
                            }}>
                                <Clear style={{color:'#f44336'}} />
                            </IconButton>
                        :
                            <FormControlLabel
                                control={<Switch
                                    size="small"
                                    checked={this.checkStatus(i)}
                                    onChange={(e) => {
                                        
                                        var installment_rows = this.state.installment_rows;
                                        
                                        if(e.target.checked){
                                        
                                            installment_rows[i]['paid'] = 1;
                                        }else{

                                            installment_rows[i]['paid'] = 0;
                                        }

                                        this.setState({installment_rows: installment_rows});
                                    }}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label="Paid"
                                size="small"
                            />
                    }
                </TableCell>
            </TableRow>
        )
    }

    checkStatus = (i) => {

        var installment_rows = this.state.installment_rows;

        return installment_rows[i]['paid'] == 0 ? false : true;
    }
}

export default InstallmentRow;