import React, { useEffect, useState } from 'react';

import { Grid, FormControl, TextField, Button, Popover, IconButton } from '@mui/material';

import Clear from '@mui/icons-material/Clear';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Loader from 'components/Loader';
import Api from 'api/Api';

function EmailForm (props){ 

    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        subject: yup.string().required('Subject is required'),
        message: yup.string().required('Message is required!')
    });

    const formik = useFormik({
        initialValues: {
            subject: '',
            message: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            var formData = new FormData();

            Object.keys(values).forEach(key => formData.append(key, values[key]))

            formData.append('email', props.email);
            formData.append('account_token', props.account_token);

            setLoading(true);

            Api.post('misc/send_email', formData, function(data){

                setLoading(false);

                if(data.status == true){
                
                    props.setSuccessMessage(data.message);
                    props.onSuccess();
                }else{

                    if(data.code && data.code == 'no_account'){

                        window.location = Api.server_url + 'logout';
                    }else{

                        props.setErrorMessage(data.message);
                    }
                    
                }
            });
        },
    });

    return (

        <Popover
            open={props.anchorEl ? true : false}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            onClose={() => {

                props.onCancel()
            }}
        >
            <div className="popover-confirm" style={{width:600}}>

                <div className="header space-between">
                    <strong>Send Email</strong>

                    <IconButton size="small" onClick={() => {

                        setLoading(false);
                        props.onCancel()
                        formik.resetForm()
                    }}>
                        <Clear />
                    </IconButton>
                </div>
                <div className="content" style={{padding:20}}>

                    <div style={{position:'relative'}}>

                        {props.email === ''
                            ?
                        
                                <Grid container spacing={3}>

                                    <Grid item xs={12} sm={12}>
                                        
                                        <strong>Email address is missing in the lead.</strong>
                                    </Grid>
                                </Grid>
                            :
                                <form className="form" name="login_form" onSubmit={formik.handleSubmit}>

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                                                    value={formik.values.subject}
                                                    name="subject"
                                                    label="Subject *"
                                                    helperText={formik.touched.subject && formik.errors.subject}
                                                    onChange={formik.handleChange}
                                                    autoComplete="off"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>
                                                
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={formik.touched.message && Boolean(formik.errors.message)}
                                                    value={formik.values.message}
                                                    name="message"
                                                    label="Message *"
                                                    helperText={formik.touched.message && formik.errors.message}
                                                    onChange={formik.handleChange}
                                                    autoComplete="off"
                                                    multiline
                                                    rows={8}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container spacing={3}>

                                        <Grid item xs={12} sm={12}>

                                            <div className="action-sm">
                                            
                                                <Button color="primary" size="small" variant="text" type="button" onClick={() => {

                                                    props.onCancel()
                                                }}>Cancel</Button>
                                                <Button color="primary" size="small" variant="contained" type="submit">Send</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                        }

                        <Loader loading={loading} />
                    </div>
                </div>
            </div>
        </Popover>
    )
}

export default EmailForm;