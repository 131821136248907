import React, { Component } from 'react';

import { Icon } from '@mui/material';

import renderHTML from 'react-render-html';

import { CloudDownload } from '@mui/icons-material';

class HistoryTimeline extends Component { 

    render () {

        return (
            <div className="timeline">

                {this.props.history && this.props.history.length > 0
                    ?
                        this.props.history.map((_history, index) => {

                            return (

                                <div className="timeline-content" key={"history_" + index}>
                                    
                                    <div className="timeline-icon">
                                        <Icon>{_history.icon}</Icon>
                                    </div>

                                    {renderHTML(_history.action)}

                                    {_history.hasOwnProperty('call_recording') && _history.call_recording != ''
                                        ?
                                            <div className="timeline-secondary">
                                                <a className="align-start mb-5" href={_history.call_recording} target="_blank">
                                                    <CloudDownload style={{fontSize:15}} /> <span className="fs-12 ml-3">Download</span>
                                                </a>
                                            </div>
                                        :
                                            null
                                    }
                                    <span className="date">{_history.date_formatted}</span>
                                </div>
                            )
                        })
                    :
                        null
                }
            </div>
        )
    }
}

export default HistoryTimeline;