import React, { Component } from 'react';

import { Grid, Button } from '@mui/material';

import Api from 'api/Api';

import { format } from 'date-fns'

import Loader from 'components/Loader';

import { VictoryChart, VictoryBar, VictoryStack, VictoryAxis, VictoryLabel } from 'victory';

class CloseRateEmployeeWise extends Component { 
    constructor(props) {
        super();
        this.state = {

            loader: true,

            employees_wise_data: [],
            no_source_wise_data: false,

            courses_data: [],
            colors: [],

            tick_values: []
        }
    }

    componentDidMount = () => {

        this.loadData();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(
            this.props.start_date !== prevProps.start_date
            ||
            this.props.end_date !== prevProps.end_date
        ){

            this.loadData();
        }
    }

    values = (source) => {

        let source_wise_data = this.state.source_wise_data;

        const value = source_wise_data.find(node => node.c == source.key);

        if(value){
        
            return <div><strong>{value.y}</strong> <span className="ml-3">({value.p}%)</span></div>;
        }else{

            return <div style={{height:23, lineHeight:1}}><strong style={{opacity:.4, fontSize:20}}>0</strong></div>;;
        }
    }

    getColor = (index) => {

        let colors = this.state.colors;

        if(colors.length > 0){

            return colors[index];
        }
    }

    render () {

        return (

            <>

                <Grid container spacing={0}>

                    <Grid item xs={12} sm={12}>

                        <Loader loading={this.state.loader} />

                        {!this.state.loader &&
                        
                            <div style={{marginTop:-40}}>
                                <VictoryChart
                                    domainPadding={{ y: 0, x: 10 }}
                                    height={400}
                                >

                                    <VictoryBar
                                        horizontal
                                        data={this.state.employees_wise_data}
                                        barRatio={1}
                                        barWidth={8}
                                        labels={({ datum }) => datum.y > 0 ? datum.y : ''}
                                        style={{ labels: { fill: "#000", fontSize:6 } }}
                                        labelComponent={<VictoryLabel dx={3} />}
                                    />

                                    <VictoryAxis
                                        dependentAxis 
                                        style={{ 
                                            tickLabels: { fill: "#333", fontSize:6},
                                            grid: {
                                                stroke: '#000',
                                                strokeDasharray: '0',
                                                fillOpacity: '.05',
                                                opacity: '.02',
                                            }
                                        }}
                                        orientation="top"
                                        tickValues={this.state.tick_values}
                                    />
                                    <VictoryAxis
                                        dependentAxis 
                                        style={{ 
                                            tickLabels: { fill: "#333", fontSize:6},
                                        }}
                                        orientation="bottom"
                                        tickValues={this.state.tick_values}
                                    />
                                    <VictoryAxis
                                        style={{ 
                                            tickLabels: { fill: "#333", fontSize:6, padding:2},
                                            grid: {
                                                stroke: '#000',
                                                strokeDasharray: '0',
                                                fillOpacity: '.3',
                                                opacity: '.08',
                                            }
                                        }}
                                        orientation="left"
                                    />
                                </VictoryChart>
                            </div>
                        }

                    </Grid>
                </Grid>
            </>
        )
    }

    loadData = () => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('start_date', format(this.props.start_date, "yyyy-MM-dd"));
        formData.append('end_date', format(this.props.end_date, "yyyy-MM-dd"));

        this.setState({loader: true});

        Api.post('reports/close_rate_employee_wise', formData, function(data){

            that.setState({loader: false});

            if(data.status){
            
                that.setState({tick_values: data.tick_values});

                if(data.employees_wise_data.length <= 0){

                    that.setState({no_source_wise_data: true, employees_wise_data: []});
                }else{

                    that.setState({no_source_wise_data: false, employees_wise_data: data.employees_wise_data});
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default CloseRateEmployeeWise;
