import React, { Component } from 'react';

import { Button, Drawer, IconButton } from '@mui/material';

import { Contacts, Clear } from '@mui/icons-material';

import DataTable from 'components/blocks/data_table/DataTable';

import Main from 'pages/sections/Main';
import Loader from 'components/Loader';

import LayoutHelper from 'helpers/LayoutHelper';
import Api from 'api/Api';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class CentresList extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',
            error_message: '',

            loading: false,

            upading_order: false,

            sidebar: false,
            active_row: false,

            row_users: [],

            users: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        if(account_token && user){

            this.setState({account_token: account_token});
            this.users(account_token);
        }else{

            window.location.href = Api.server_url + 'logout';
        }
    }

    render () {
        
        return (

            <Main active_link="system" error_message={this.state.error_message} page="centres" title={"Centres"}>

                {this.state.account_token &&
                    <DataTable
                        label="Centres"

                        columns={[
                            {name: 'Name', column: 'name'},
                            {name: 'Contact Person', column: 'contact_person', sortable: true},
                            {name: 'Contact', column: 'contact', sortable: true, renderer: (_data) => {

                                return <span>{_data.contact}</span>
                            }},
                            {name: 'Address', column: 'address', sortable: true},
                            {name: 'State', column: 'state', sortable: true},
                            {name: 'City', column: 'city', sortable: true},
                            {name: 'Users', column: 'id', sortable: false, renderer: (_data) => {

                                return this.usersList(_data)
                            }},
                        ]}

                        default_sort_by="name"
                        default_sort_dir="asc"

                        api_url="centers/load"

                        account_token={this.state.account_token}
                        
                        row_actions={(row) => {
                            
                            return (
                                <div className="hoverable-action">

                                    <div className="align-start">

                                        <Button color="secondary" size="small" startIcon={<Contacts />} onClick={() => {

                                            this.setState({sidebar: true, active_row: row});

                                            var users = this.state.users;

                                            var agents = [];

                                            if(users.length > 0){

                                                for(var u in users){

                                                    var _user = users[u];

                                                    if(_user.exam_centre_id == row.id){

                                                        agents.push(_user);
                                                    }
                                                }
                                            }

                                            agents.sort(function(a, b) {
                                                return parseFloat(a.sort_order) > parseFloat(b.sort_order);
                                            });

                                            this.setState({row_users: agents})

                                        }}>Users</Button>
                                    </div>
                                </div>
                            )
                        }}
                        // row_id="row_id"
                        // view_url="leads/view"
                    />
                }

                <Loader loading={this.state.loading} />

                <Drawer anchor="right" variant="temporary" open={this.state.sidebar}>
                    <div className="drawer-container sm">
                        <div className="bg-primary drawer-heading">
                            <h2>{this.state.active_row ? this.state.active_row.name : ''}</h2>
                            <IconButton size="small" onClick={() => {
                                
                                this.setState({sidebar: false, active_row: false})
                            }}>
                                <Clear style={{color:'rgba(0,0,0,.5)'}} />
                            </IconButton>
                        </div>

                        <div className="drawer-body">

                            <div className="drawer-content">

                                {this.state.active_row
                                    ?

                                        <div>

                                            <p style={{marginTop:0}}>Leads will be auto assigned to the users in the order below. Drag the users in the list below to re-arrange the order.</p>

                                            {this.draggableList(this.state.active_row)}
                                        </div>
                                    :
                                        null
                                }

                                <Loader loading={this.state.upading_order} />
                            </div>
                        </div>
                    </div>
                </Drawer>
            </Main>
        )
    }

    draggableList = (row) => {

        return (

            <DragDropContext onDragEnd={(result) => {

                if(!result.destination){
                    return;
                }
              
                var row_users = this.reorder(
                    this.state.row_users,
                    result.source.index,
                    result.destination.index
                );

                // find re ordered indexes
                var indexes = {};

                for(var i in row_users){

                    indexes[row_users[i]['id']] = i;
                }

                this.updateIndexes(indexes);

                this.setState({row_users: row_users})
            }}>

                <Droppable droppableId="droppable">

                    {(provided, snapshot) => (
                        <div
                            className="users-list"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {this.state.row_users.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        className="users-list-item"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <div className="user-list-item-block">
                                            <div className="num-block">
                                                <span className="num">{index + 1}</span>
                                            </div>
                                            <div className="content">
                                                <span>{item.first_name} - {item.last_name} ({item.role_title})</span>
                                                <strong>{item.contact_number}</strong>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    }

    sortOrder = () => {

        
    }

    usersList = (row) => {

        var users = this.agents(row);

        return users.map((_user) => {

            return (

                <div key={"user_" + _user.id}>
                    <span>{_user.first_name} {_user.last_name}</span>
                </div>
            )
        })
    }

    agents = (row) => {

        var users = this.state.users;

        var agents = [];

        if(users.length > 0){

            for(var u in users){

                var _user = users[u];

                if(_user.exam_centre_id == row.id){

                    agents.push(_user);
                }
            }
        }

        agents.sort(function(a, b) {
            return parseFloat(a.sort_order) > parseFloat(b.sort_order);
        });

        return agents;
    }

    users = (account_token) => {

        var formData = new FormData();
        formData.append('account_token', account_token);

        var that = this;

        this.setState({loading: true})

        Api.post('users/list_all', formData, function(data){

            that.setState({loading: false})

            if(data.status){
            
                that.setState({users: data.users});
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    updateIndexes = (indexes) => {

        var formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('indexes', JSON.stringify(indexes));

        var that = this;

        this.setState({upading_order: true})

        Api.post('users/update_user_order', formData, function(data){

            that.setState({upading_order: false})

            if(data.status){
            
                that.setState({users: data.users});
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default CentresList;
