import React, { Component } from 'react';

import { Tabs, Tab } from '@mui/material';

import RevenueCourseWise from './RevenueCourseWise';
import RevenueChannelWise from './RevenueChannelWise';
import RevenueEmployeeWise from './RevenueEmployeeWise';
import RevenueBranchWise from './RevenueBranchWise';
import RevenueEmployeeWiseDetailed from './RevenueEmployeeWiseDetailed';

class AcquisitonPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            active_sub_tab: 'revenue_course_wise',

            sources: []
        }
    }

    render () {

        return (

            <div>

                <div className="horizontal-tabs">
                    <Tabs
                        orientation="horizontal"
                        variant="scrollable"
                        value={this.state.active_sub_tab}
                        onChange={(e, value) => {
                            this.setState({active_sub_tab: value});
                        }}
                        aria-label="Reports view tabs"
                    >
                        <Tab label="Course Wise" value="revenue_course_wise" id="horizontal-tab-revenue_course_wise" aria-controls="horizontal-tabpanel-revenue_course_wise" />
                        <Tab label="Channel Wise" value="revenue_channel_wise" id="horizontal-tab-revenue_channel_wise" aria-controls="horizontal-tabpanel-revenue_channel_wise" />
                        {/* <Tab label="Employee Wise" value="revenue_employee_wise" id="horizontal-tab-revenue_employee_wise" aria-controls="horizontal-tabpanel-revenue_employee_wise" /> */}
                        <Tab label="Employee Wise" value="revenue_employee_wise_detailed" id="horizontal-tab-revenue_employee_wise_detailed" aria-controls="horizontal-tabpanel-revenue_employee_wise_detailed" />
                        <Tab label="Branch Wise" value="revenue_branch_wise" id="horizontal-tab-revenue_branch_wise" aria-controls="horizontal-tabpanel-revenue_branch_wise" />
                        
                    </Tabs>
                </div>

                <div className="horizontal-tabs">
                    <div className="horizontal-tabs-content">

                        <div
                            value="revenue_course_wise"
                            role="tabpanel"
                            hidden={this.state.active_sub_tab != 'revenue_course_wise'}
                            id="horizontal-tabpanel-revenue_course_wise"
                            aria-labelledby="horizontal-tab-revenue_course_wise"
                        >
                            <div className="tab-content">

                                {
                                    (
                                        this.props.account_token
                                        && 
                                        this.state.active_sub_tab == 'revenue_course_wise'
                                    ) &&

                                    <RevenueCourseWise
                                        account_token={this.props.account_token}
                                        start_date={this.props.start_date}
                                        end_date={this.props.end_date}
                                        updateSources={(sources) => {

                                            this.props.updateSources(sources)
                                        }}
                                    />
                                }
                            </div>
                        </div>

                        <div
                            value="revenue_channel_wise"
                            role="tabpanel"
                            hidden={this.state.active_sub_tab != 'revenue_channel_wise'}
                            id="horizontal-tabpanel-revenue_channel_wise"
                            aria-labelledby="horizontal-tab-revenue_channel_wise"
                        >
                            <div className="tab-content">

                                {
                                    (
                                        this.props.account_token
                                        && 
                                        this.state.active_sub_tab == 'revenue_channel_wise'
                                    ) &&

                                    <RevenueChannelWise
                                        account_token={this.props.account_token}
                                        start_date={this.props.start_date}
                                        end_date={this.props.end_date}
                                        updateSources={(sources) => {

                                            this.props.updateSources(sources)
                                        }}
                                    />
                                }
                            </div>
                        </div>

                        <div
                            value="revenue_employee_wise"
                            role="tabpanel"
                            hidden={this.state.active_sub_tab != 'revenue_employee_wise'}
                            id="horizontal-tabpanel-revenue_employee_wise"
                            aria-labelledby="horizontal-tab-revenue_employee_wise"
                        >
                            <div className="tab-content">

                                {
                                    (
                                        this.props.account_token
                                        && 
                                        this.state.active_sub_tab == 'revenue_employee_wise'
                                    ) &&

                                    <RevenueEmployeeWise
                                        account_token={this.props.account_token}
                                        start_date={this.props.start_date}
                                        end_date={this.props.end_date}
                                        updateSources={(sources) => {

                                            this.props.updateSources(sources)
                                        }}
                                    />
                                }
                            </div>
                        </div>

                        <div
                            value="revenue_branch_wise"
                            role="tabpanel"
                            hidden={this.state.active_sub_tab != 'revenue_branch_wise'}
                            id="horizontal-tabpanel-revenue_branch_wise"
                            aria-labelledby="horizontal-tab-revenue_branch_wise"
                        >
                            <div className="tab-content">

                                {
                                    (
                                        this.props.account_token
                                        && 
                                        this.state.active_sub_tab == 'revenue_branch_wise'
                                    ) &&

                                    <RevenueBranchWise
                                        account_token={this.props.account_token}
                                        start_date={this.props.start_date}
                                        end_date={this.props.end_date}
                                        updateSources={(sources) => {

                                            this.props.updateSources(sources)
                                        }}
                                    />
                                }
                            </div>
                        </div>

                        <div
                            value="revenue_employee_wise_detailed"
                            role="tabpanel"
                            hidden={this.state.active_sub_tab != 'revenue_employee_wise_detailed'}
                            id="horizontal-tabpanel-revenue_employee_wise_detailed"
                            aria-labelledby="horizontal-tab-revenue_employee_wise_detailed"
                        >
                            <div className="tab-content">

                                {
                                    (
                                        this.props.account_token
                                        && 
                                        this.state.active_sub_tab == 'revenue_employee_wise_detailed'
                                    ) &&

                                    <RevenueEmployeeWiseDetailed
                                        account_token={this.props.account_token}
                                        start_date={this.props.start_date}
                                        end_date={this.props.end_date}
                                        updateSources={(sources) => {

                                            this.props.updateSources(sources)
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AcquisitonPage;
