import React, { Component } from 'react';

import { Grid  } from '@mui/material';

import Loader from 'components/Loader';
import NoData from 'components/blocks/NoData';

import Api from 'api/Api';

import { format, subMonths } from 'date-fns'

import { BarChart, PieChart } from '@mui/icons-material';
import { VictoryPie, VictoryLabel } from 'victory';

import DataRow from '../DataRow';

class CoversionOverall extends Component { 
    constructor(props) {
        super();
        this.state = {

            loader: false,

            source_wise_data: [],
            source_wise_data_colors: [],
            no_source_wise_data: false,

            color_codes: [],

            sources: [],

            total_leads: 0,

            end_date: new Date(),
            start_date: subMonths(new Date(), 1),

            period_end_date: new Date(),
            period_start_date: subMonths(new Date(), 1),

            period_wise_data_tmp: [],
            period_wise_data: [],
            
            period_tick_values: [],

            hidden_indexes: []
        }
    }

    componentDidMount = () => {

        this.loadData();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(
            this.props.start_date !== prevProps.start_date
            ||
            this.props.end_date !== prevProps.end_date
        ){

            this.loadData();
        }
    }

    values = (source) => {

        let source_wise_data = this.state.source_wise_data;

        const value = source_wise_data.find(node => node.c == source.key);

        if(value){
        
            return <div><strong>{value.y}</strong> <span className="ml-3">({value.z}%)</span></div>;
        }else{

            return <div style={{height:23, lineHeight:1}}><strong style={{opacity:.4, fontSize:20}}>0</strong></div>;;
        }
    }

    render () {

        return (

            <>
                {/* <div className="box-header" style={{borderBottom:'1px solid rgba(0,0,0,.1)'}}>

                    <div className="align-start">
                    
                        <h2 className="mr-5">Source Wise Leads</h2>

                        <DateRangeComponent
                            update={(start_date, end_date) => {

                                this.setState({start_date: start_date, end_date: end_date}, () => {

                                    this.loadData(false);
                                });
                            }}
                            start_date={this.state.start_date}
                            end_date={this.state.end_date}
                        />
                    </div>
                </div> */}

                <Grid container spacing={0}>

                    <Grid item xs={12} sm={5}>

                        <label className="cg-5 fs-16">Total Conversions: <strong className="fs-16 cg-7">{this.state.total_leads}</strong></label>
                        
                        <ul className="item-card-list">
                            {this.state.sources.map((_source) => {

                                return (

                                    <DataRow
                                        key={_source.key} 
                                        label={_source.value.toUpperCase()}
                                        color={_source.color}
                                        value={this.values(_source)}
                                    />
                                )
                            })}
                        </ul>
                    </Grid>

                    <Grid item xs={12} sm={1}></Grid>

                    <Grid item sm={5}>

                        <Loader loading={this.state.loader} />

                        {(!this.state.chart_loader && !this.state.no_source_wise_data ) &&

                            <VictoryPie
                                // width={300}
                                labels={({ datum }) => ``}
                                data={this.state.source_wise_data}
                                colorScale={this.state.source_wise_data_colors}
                                innerRadius={30}
                                labelComponent={<VictoryLabel style={[{fontSize:12}]} />}
                                animate={true}
                            />
                        }

                        {this.state.no_source_wise_data &&

                            <NoData icon={<PieChart style={{fontSize:240, color:'rgba(0,0,0,.04)'}} />} style={{margin:0}} size="small" message="Data not available!" hide_image={true} />
                        }
                    </Grid>
                </Grid>
            </>
        )
    }

    colorCode = (code) => {

        let sources = this.state.sources;

        const color_code = sources.find(source => source.key === code);

        if(color_code){
        
            return color_code.color;
        }

        return "#FFCE00";
    }

    loadData = () => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('start_date', format(this.props.start_date, "yyyy-MM-dd"));
        formData.append('end_date', format(this.props.end_date, "yyyy-MM-dd"));

        this.setState({loader: true});

        Api.post('reports/conversion', formData, function(data){

            that.setState({loader: false});

            if(data.status){
            
                that.setState({sources: data.sources, total_leads: data.total_leads});

                if(data.source_wise_data.length <= 0){

                    that.setState({no_source_wise_data: true, source_wise_data: []});
                }else{

                    that.setState({no_source_wise_data: false, source_wise_data: data.source_wise_data, source_wise_data_colors: data.source_wise_data_colors});
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default CoversionOverall;
