import React, { Component } from 'react';

import { Grid, FormControl, InputLabel, TextField, Select, MenuItem, Button, IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { Clear } from '@mui/icons-material';

class StudentsListFilters extends Component { 
    constructor(props) {
        super();
        this.state = {

            selected_course: '',
            selected_centre: '',
            selected_batch: '',
            selected_year: '',
            selected_month: '',
            selected_date: '',

            post_params: []
        }
    }

    submit = () => {

        let post_params = [];

        if(this.state.selected_course != ''){

            post_params.push({key: 'course', value: this.state.selected_course});
        }

        if(this.state.selected_batch){

            post_params.push({key: 'batch', value: this.state.selected_batch.key});
        }

        if(this.state.selected_centre != ''){

            post_params.push({key: 'centre', value: this.state.selected_centre});
        }

        if(this.state.selected_year != ''){

            post_params.push({key: 'year', value: this.state.selected_year});
        }

        if(this.state.selected_month != ''){

            post_params.push({key: 'month', value: this.state.selected_month});
        }

        if(this.state.selected_date != ''){

            post_params.push({key: 'date', value: this.state.selected_date});
        }

        this.setState({post_params: post_params});

        this.props.updatePostParams(post_params)
    }

    render () {

        return (

            <div style={{paddingBottom:20}}>
                <form onSubmit={() => {

                    this.submit()
                }}>
                    <Grid container spacing={3}>

                        <Grid item sm={2} xs={12}>

                            <FormControl size="small" fullWidth>

                                <InputLabel id="course_label">Courses</InputLabel>
                                <Select
                                    labelId="course_label"
                                    value={this.state.selected_course}
                                    onChange={(e) => {

                                        this.setState({selected_course: e.target.value});
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value="">Select Course</MenuItem>
                                    {(this.props.courses && this.props.courses.length) &&
                                    
                                        this.props.courses.map((_course, index) => {

                                            return <MenuItem key={"course_" + index} value={_course.key}>{_course.value}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={2} xs={12}>

                            <FormControl size="small" fullWidth>

                                <InputLabel id="center_label">Centres</InputLabel>
                                <Select
                                    labelId="center_label"
                                    value={this.state.selected_centre}
                                    onChange={(e) => {

                                        this.setState({selected_centre: e.target.value});
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value="">Select Centre</MenuItem>
                                    {(this.props.centres && this.props.centres.length) &&
                                    
                                        this.props.centres.map((_centre, index) => {

                                            return <MenuItem key={"centre_" + index} value={_centre.key}>{_centre.value}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={2} xs={12}>

                            <Autocomplete
                                clearOnEscape
                                options={this.renderBatches()}
                                getOptionLabel={(option) => option.value ? option.value : ''}
                                renderInput={(params) => <TextField {...params} label="Batches" variant='standard' />}
                                onChange={(event, newValue) => {
                                    this.setState({selected_batch: newValue})
                                }}
                                value={this.state.selected_batch}
                            />
                        </Grid>

                        <Grid item sm={4} xs={12} className="flex">

                            <FormControl size="small" style={{width: '30%'}}>

                                <InputLabel>Year</InputLabel>
                                <Select
                                    value={this.state.selected_year}
                                    onChange={(e) => {

                                        this.setState({selected_year: e.target.value})
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    {this.renderYears()}
                                </Select>
                            </FormControl>

                            <FormControl size="small" style={{width: '30%', marginLeft: '5%'}}>

                                <InputLabel>Month</InputLabel>
                                <Select
                                    value={this.state.selected_month}
                                    onChange={(e) => {

                                        this.setState({selected_month: e.target.value})
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value="01">January</MenuItem>
                                    <MenuItem value="02">February</MenuItem>
                                    <MenuItem value="03">March</MenuItem>
                                    <MenuItem value="04">April</MenuItem>
                                    <MenuItem value="05">May</MenuItem>
                                    <MenuItem value="06">June</MenuItem>
                                    <MenuItem value="07">July</MenuItem>
                                    <MenuItem value="08">August</MenuItem>
                                    <MenuItem value="09">September</MenuItem>
                                    <MenuItem value="00">October</MenuItem>
                                    <MenuItem value="11">November</MenuItem>
                                    <MenuItem value="12">December</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl size="small" style={{width: '30%', marginLeft: '5%'}}>

                                <InputLabel>Date</InputLabel>
                                <Select
                                    value={this.state.selected_date}
                                    onChange={(e) => {

                                        this.setState({selected_date: e.target.value})
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    {this.renderDays()}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={2} xs={12} className="flex">

                            <Button size="small" color="secondary" variant="outlined" onClick={() => {

                                this.submit()
                            }}>Search</Button>

                            {this.state.post_params.length > 0 &&
                            
                                <IconButton size="small" color="secondary" className="ml-10" onClick={() => {

                                    this.setState({
                                        post_params: [],
                                        selected_course: '',
                                        selected_batch: '',
                                        selected_year: '',
                                        selected_month: '',
                                        selected_date: '',
                                    });

                                    this.props.updatePostParams([]);
                                }}>
                                    <Clear />
                                </IconButton>    
                            }
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }

    renderDays = () => {

        let days = [];

        for(var d = 1; d <= 31; d++){

            days.push(<MenuItem key={"date_" + d} value={d}>{d}</MenuItem>)
        }

        return days;
    }

    renderYears = () => {

        let years = [];
        const _year = new Date();

        for(var i = 2019; i <= parseInt(_year.getFullYear()); i++){

            years.push(<MenuItem key={"year_" + i} value={i}>{i}</MenuItem>)
        }

        return years;
    }

    renderBatches = () => {

        let batches = [];

        if(this.props.batches && this.props.batches.length > 0){

            if(this.state.selected_course != '' && this.state.selected_centre != ''){

                this.props.batches.forEach((_batch) => {

                    if(_batch.course_id == this.state.selected_course && _batch.centre_id == this.state.selected_centre){
                    
                        batches.push(_batch);
                    }
                })
            }else if(this.state.selected_course != '' && this.state.selected_centre == ''){

                this.props.batches.forEach((_batch) => {

                    if(_batch.course_id == this.state.selected_course){
                    
                        batches.push(_batch);
                    }
                })

            }else if(this.state.selected_course == '' && this.state.selected_centre != ''){

                this.props.batches.forEach((_batch) => {

                    if(_batch.centre_id == this.state.selected_centre){
                    
                        batches.push(_batch);
                    }
                })
            }else{

                this.props.batches.forEach((_batch) => {

                    batches.push(_batch);
                })
            }
        }

        return batches;
    }
}

export default StudentsListFilters;
