import React, { Component } from 'react';

import { Grid, FormControl, TextField, Button, Avatar } from '@mui/material';

import { NavigateNext } from '@mui/icons-material';

import Api from 'api/Api';

import Loader from 'components/Loader';

class LeadCommentForm extends Component { 

    constructor(props) {
        super();
        this.state = {

            comments_count: 0,

            comment: '',

            comment_error: false,

            sending: false,

            loading: false,
            comments: [],
        }
    }

    render () {

        return (
            <div style={{position:'relative'}}>
                
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>

                        <FormControl fullWidth>

                            <TextField
                                error={this.state.comment_error}
                                variant="outlined"
                                label="Add Comment"
                                multiline
                                rows={3}
                                rowsMax={5}
                                value={this.state.comment}
                                onChange={(e) => {

                                    this.setState({comment: e.target.value})
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>

                        <div className="action-sm">
                            <Button onClick={() => {

                                if(this.state.comment != ''){

                                    this.setState({comment_error: false})
                                    this.addComment();
                                }else{

                                    this.setState({comment_error: true})
                                }
                            }} size="small" color="primary" variant="contained" endIcon={<NavigateNext />}>Submit</Button>

                            <Loader loading={this.state.sending} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }

    addComment = () => {
    
        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('comment', this.state.comment);
        formData.append('lead', this.props.lead.row_id);

        var that = this;
        this.setState({sending: true});
            
        Api.post('leads/comments/add', formData, function(data){
           
            that.setState({sending: false});

            if(data.status){

                that.setState({comment: ''});
                that.props.onSuccess(data);

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }else{

                    that.props.onError(data.message);
                }
            }
        });
    }
}

export default LeadCommentForm;