import React, { Component } from 'react';
import { Navigate } from "react-router-dom";

import { Button } from '@mui/material';

import { Sync } from '@mui/icons-material';

import DataTable from 'components/blocks/data_table/DataTable';

import Main from 'pages/sections/Main';
import Loader from 'components/Loader';

import LayoutHelper from 'helpers/LayoutHelper';
import Api from 'api/Api';

class AgentsList extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',
            error_message: '',

            loading: false,
            redirect: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
        }
    }

    render () {

        if(this.state.redirect){

            return <Navigate to="/reroute/agents" />
        }
        
        return (

            <Main active_link="system" error_message={this.state.error_message} page="agents" title={"Agents"} title_action={<Button startIcon={<Sync />} color="secondary" size="small" onClick={() => {
                
                this.syncAgents()
            }}>Sync Agents</Button>}>

                {this.state.account_token &&
                
                    <DataTable
                        label="Agents"

                        columns={[
                            {name: 'Agent ID', column: 'agent_id'},
                            {name: 'Full Name', column: 'first_name', sortable: true, renderer: (_data) => {

                                return <span>{_data.first_name} {_data.last_name}</span>
                            }},
                            {name: 'Email Address', column: 'email', sortable: true},
                            {name: 'Mobile Number', column: 'phone', sortable: true, renderer: (_data) => {

                                return <span>{_data.country_code} {_data.phone}</span>
                            }},
                            {name: 'Date', column: 'added_on', sortable: true, renderer: (_data) => {

                                return <span>{_data.added_on_formatted}</span>
                            }},
                        ]}

                        default_sort_by="added_on"

                        api_url="agents/load"

                        account_token={this.state.account_token}
                        
                        // row_id="row_id"
                        // view_url="leads/view"
                    />
                }

                <Loader loading={this.state.loading} />
            </Main>
        )
    }

    syncAgents = () => {

        var formData = new FormData();
        var that = this;

        this.setState({loading: true})

        Api.post('agents/sync', formData, function(data){

            that.setState({loading: false})

            if(data.status){
            
                that.setState({redirect: true});
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default AgentsList;
