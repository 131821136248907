import React, { Component } from 'react';

import { Grid, Button, Drawer, IconButton, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

// import DateFnsUtils from '@date-io/date-fns';
// import { format, formatDistance, formatRelative, subDays } from 'date-fns'

// import { DateTimePicker, DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import { MoodBad, EmojiEventsOutlined, FiberManualRecordOutlined, Clear } from '@mui/icons-material';

import Api from 'api/Api';
import Loader from 'components/Loader';
import NoData from 'components/blocks/NoData';

import ItemCard from 'components/blocks/ItemCard';

class StudentExams extends Component { 
    constructor(props) {
        super();
        this.state = {

            loading: false,
            exams: [],

            show_single_exam: false,

            loading_single_exam: false,

            selected_sub_exam: false,

            exam: false,
            main_exam: false,
            exam_score: 0,
            total_score: 0,
            correct_answers: 0,
            total_questions: 0,
            attempted_questions: 0,
            assigned_questions_list: [],
            case_study_wise_questions: [],

            no_exams: false
        }
    }

    componentDidMount = () => {

        if(this.props.student){
        
            this.loadExams(this.props.student.id);
        }
    }

    buttonWrap = (_sub_exam) => {

        if(this.props.user.user_type === 'super'){

            return (
                <Button className="row" onClick={() => {

                    this.setState({show_single_exam: true, selected_sub_exam: _sub_exam}, () => {

                        this.loadSingleExam(_sub_exam);
                    })
                }}>
                
                    {this.examRow(_sub_exam)}
                </Button>
            )
        }else{

            return this.examRow(_sub_exam)
        }
    }

    examRow = (_sub_exam) => {

        return (
            <>
                <div className="text">
                    <strong className="primary">{_sub_exam.name}</strong>
                    <span className="secondary">
                        {_sub_exam.exam_type_label}
                        <span className="highlight"> - ( {_sub_exam.attempted_lable} )</span>
                    </span>
                </div>

                <div className="vertical align-center">

                    {_sub_exam.result == '' &&
                    
                        <>
                            <FiberManualRecordOutlined className="op-3" />
                            <span className="fs-10">{_sub_exam.result_label}</span>
                        </>
                    }

                    {_sub_exam.result == 'fail' &&
                    
                        <>
                            <MoodBad className="c-r" />
                            <span className="fs-10 c-r">{_sub_exam.result_label}</span>
                        </>
                    }

                    {_sub_exam.result == 'pass' &&
                    
                        <>
                            <EmojiEventsOutlined className="c-g" />
                            <span className="fs-10 c-g">{_sub_exam.result_label}</span>
                        </>
                    }
                </div>
            </>
        )
    }

    render () {

        return (

            <div>

                <Grid container spacing={5}>
                
                    {this.state.exams && this.state.exams.length > 0
                        ?

                            this.state.exams.map((_exam, index) => {

                                return (
                                    <Grid item xs={12} sm={6} key={"exam_" + index}>
                                        
                                        <ItemCard
                                            title={_exam.title}
                                            sub_title={_exam.added_on_formatted}
                                        >
                                            {_exam.sub_exams && _exam.sub_exams.length > 0
                                                ?

                                                    <ul className="item-card-list">
                                                        {_exam.sub_exams.map((_sub_exam, _index) => {

                                                            return (
                                                                <li key={"sub_exam_row_" + index + '_' + _index}>

                                                                    <div className="row">
                                                                    
                                                                        {this.buttonWrap(_sub_exam)}
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                :
                                                    null
                                            }
                                        </ItemCard>
                                    </Grid>
                                )
                            })
                        :
                            null
                    }

                    {this.state.no_exams &&
                    
                        <Grid item xs={12} sm={12}>
                            <NoData size="small" message="Exams not found!" />
                        </Grid>
                    }

                    <Loader loading={this.state.loading} />
                </Grid>

                <Drawer
                    anchor="right"
                    variant="temporary" 
                    open={this.state.show_single_exam}
                    onClose={() => {

                        this.setState({show_single_exam: false})
                    }}
                >
                    <div className="drawer-container">
                        
                        {this.state.selected_sub_exam &&

                            <>
                                <div className="bg-primary drawer-heading">
                                    <h2>{this.state.selected_sub_exam.name}</h2>
                                    <IconButton size="small" onClick={() => {
                                        
                                        this.setState({show_single_exam: false})
                                    }}>
                                        <Clear style={{color:'rgba(0,0,0,.5)'}} />
                                    </IconButton>
                                </div>

                                <div className="drawer-body">

                                    <div className="drawer-content">

                                        <Grid container spacing={5}>

                                            <Grid item xs={12} sm={6}>

                                                {this.state.exam && this.state.main_exam
                                                    ?
                                                        <>
                                                            <strong className="c-p fs-13">EXAM DETAILS</strong>
                                                            <Table style={{width:'100%'}} className="mt-10" size="small">
                                                                
                                                                <TableBody>

                                                                    {this.table_row('Exam', this.state.exam.name)}
                                                                    {this.table_row('Date', this.state.exam.publish_datetime_formatted)}
                                                                    {this.table_row('Type', this.state.exam.exam_type_lable)}
                                                                    {this.table_row('Duration', this.state.exam.time_interval + ' Minutes')}
                                                                    {this.table_row('Exam Centre', this.state.main_exam.exam_center_name)}
                                                                    {this.table_row('Course', this.state.main_exam.course_title)}
                                                                    {this.table_row('Batch', this.state.main_exam.batch_title)}
                                                                </TableBody>
                                                            </Table>
                                                        </>
                                                    :
                                                        null
                                                }

                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                {this.state.selected_sub_exam &&
                                                
                                                    this.state.selected_sub_exam.attempted == 'attempted'
                                                        ?

                                                            <>
                                                                <strong className="c-p fs-13">RESULT SUMMARY</strong>

                                                                {this.state.selected_sub_exam.result == '' &&
                                                                
                                                                    <div className="flex flex-center mt-30 mb-30">
                                                                        <strong className="cg-6">Result Awaiting</strong>
                                                                    </div>
                                                                }

                                                                {this.state.selected_sub_exam.result == 'pass' &&
                                                                
                                                                    <div className="flex">

                                                                        <EmojiEventsOutlined style={{fontSize:100, color:'#4fb778'}} />
                                                                        
                                                                        <div className="flex vertical align-start">
                                                
                                                                            <strong style={{fontSize:35, color:'#4fb778'}}>PASS</strong>
                                                
                                                                            <div style={{fontSize:20, fontWeight:'bold', color:'rgba(0,0,0,.6)'}}>
                                                                                <span>{this.state.total_score}</span>
                                                                                /
                                                                                <span>{this.state.exam_score}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {this.state.selected_sub_exam.result == 'fail' &&
                                                                
                                                                    <div className="flex">

                                                                        <MoodBad style={{fontSize:100, color:'#ef466c'}} />
                                                                        
                                                                        <div className="flex vertical align-start">
                                                
                                                                            <strong style={{fontSize:35, color:'#ef466c'}}>FAIL</strong>
                                                
                                                                            <div style={{fontSize:20, fontWeight:'bold', color:'rgba(0,0,0,.6)'}}>
                                                                                <span>{this.state.total_score}</span>
                                                                                /
                                                                                <span>{this.state.exam_score}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                <Table style={{width:'100%'}} className="mt-10" size="small">
                                                                    
                                                                    <TableBody>

                                                                        {this.table_row('Total Questions', this.state.total_questions)}
                                                                        {this.table_row('Attempted Questions', this.state.attempted_questions)}
                                                                        {this.table_row('Correct Answers', this.state.correct_answers)}
                                                                    </TableBody>
                                                                </Table>
                                                            </>
                                                        :
                                                            <div>
                                                                <NoData size="small" hide_image={true}>Exam not attempted!</NoData>
                                                            </div>
                                                }
                                            </Grid>

                                            <Grid item xs={12} sm={12}>

                                                {this.state.selected_sub_exam &&
                                                
                                                    <>
                                                        {this.state.selected_sub_exam.exam_type == 'theory'
                                                            ?

                                                                this.state.assigned_questions_list && this.state.assigned_questions_list.length > 0

                                                                    ?

                                                                        <>
                                                                            <strong className="c-p fs-13 mb-20">QUESTIONS</strong>
                                                                            <Table style={{width:'100%'}} className="mt-10" size="small">

                                                                                {this.questionsHead()}
                                                                                <TableBody>

                                                                                    {this.state.assigned_questions_list.map((_question, index) => {

                                                                                        let sno = index + 1;
                                                                                        return this.questionRow(_question, index, sno);
                                                                                    })}

                                                                                    <TableRow>
                                                                                        <TableCell align="right" colSpan={4}>
                                                                                            <strong>Score</strong>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {this.state.total_score}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableBody>
                                                                            </Table>
                                                                        </>
                                                                    :
                                                                        null
                                                            :
                                                                null
                                                        }

                                                        {this.state.selected_sub_exam.exam_type == 'case_study'
                                                            ?

                                                                this.state.case_study_wise_questions && this.state.case_study_wise_questions.length > 0
                                                                    ?

                                                                        <>
                                                                            <strong className="c-p fs-13 mb-20">QUESTIONS</strong>
                                                                            <Table style={{width:'100%'}} className="mt-10" size="small">

                                                                                {this.questionsHead()}
                                                                                <TableBody>

                                                                                    {this.state.case_study_wise_questions.map((_case_study, index) => {

                                                                                        return (

                                                                                            <>
                                                                                                <TableRow key={"case_study_" + index}>
                                                                                                    <TableCell colSpan={5}>
                                                                                                        <div className="flex vertical flex-start">
                                                                                                        
                                                                                                            <strong>Case Study:</strong>
                                                                                                            <span>{_case_study.title}</span>
                                                                                                        </div>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                                {_case_study.questions && _case_study.questions.length > 0
                                                                                                    ?

                                                                                                        _case_study.questions.map((_case_study_question, _index) => {

                                                                                                            let sno = _index + 1;
                                                                                                            let __index = index + '_' + _index;
                                                                                                            return this.questionRow(_case_study_question, __index, sno);
                                                                                                        })
                                                                                                    :
                                                                                                        null
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                    <TableRow>
                                                                                        <TableCell align="right" colSpan={4}>
                                                                                            <strong>Score</strong>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {this.state.total_score}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                </TableBody>
                                                                            </Table>
                                                                        </>
                                                                    :
                                                                        null
                                                            :
                                                                null
                                                        }
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Loader loading={this.state.loading_single_exam} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Drawer>
            </div>
        )
    }

    questionsHead = () => {

        return (
            <TableHead>

                <TableRow>
                    <TableCell>S.No.</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell style={{lineHeight:1}}>Correct Answer</TableCell>
                    <TableCell style={{lineHeight:1}}>Student Answer</TableCell>
                    <TableCell>Score</TableCell>
                </TableRow>
            </TableHead>
        )
    }

    questionRow = (_question, index, sno) => {

        return (
            <TableRow key={"question_" + index}>
                <TableCell>{sno}</TableCell>
                <TableCell>{_question.title}</TableCell>
                <TableCell>{_question.answer}</TableCell>
                <TableCell>{_question.student_answer}</TableCell>
                <TableCell>{_question.student_score}</TableCell>
            </TableRow>
        )
    }

    table_row = (label, value) => {

        return (

            <TableRow>
                <TableCell component="th">{label}:</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>
        )
    }

    loadSingleExam = (sub_exam) => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('student', this.props.student.id);
        formData.append('exam_id', sub_exam.id);

        var that = this;
        this.setState({
            loading_single_exam: true,
            exam: false,
            main_exam: false,
            exam_score: 0,
            total_score: 0,
            correct_answers: 0,
            total_questions: 0,
            attempted_questions: 0,
            assigned_questions_list: [],
            case_study_wise_questions: [],
        });
            
        Api.post('students/exams/single', formData, function(data){

            that.setState({loading_single_exam: false});

            if(data.status){
            
                that.setState({
                    exam: data.exam,
                    main_exam: data.main_exam,
                    exam_score: data.exam_score,
                    total_score: data.total_score,
                    correct_answers: data.correct_answers,
                    total_questions: data.total_questions,
                    attempted_questions: data.attempted_questions,
                    case_study_wise_questions: data.case_study_wise_questions,
                    assigned_questions_list: data.assigned_questions_list
                });
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    // that.props.error_message(data.message);
                }
            }
        });
    }

    loadExams = (student_id) => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('student', student_id);

        var that = this;
        this.setState({loading: true});
            
        Api.post('students/exams/all', formData, function(data){

            that.setState({loading: false});

            if(data.status){
            
                if(data.exams && data.exams.length > 0){

                    that.setState({exams: data.exams, no_exams: false});
                }else{

                    that.setState({exams: [], no_exams: true});
                }
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.error_message(data.message);
                }
            }
        });
    }
}

export default StudentExams;