import React, { Component } from 'react';

import { Button } from '@mui/material';

class DataRow extends Component { 
    constructor(props) {
        super();
        this.state = {

            comments_drawer: false,
        }
    }

    render () {

        return (

            <li style={{border:0}}>
                
                <div className="row" style={{backgroundColor:'rgba(0,0,0,.03)', borderRadius:5, border:'1px solid rgba(0,0,0,.1)'}}>

                    {this.props.label != 'Missed'
                        ?
                            <Button style={{display:'flex', padding:'0px', justifyContent:'space-between', width:'100%'}} onClick={() => {
                                
                                if(this.props.label == 'Answered'){
                                    var _label = 'answered';
                                }else{
                                    var _label = 'outbound';
                                }

                                if(this.props.onClickRow){
                                
                                    this.props.onClickRow(_label);
                                }
                            }}>
                                <div className="text" style={{padding:12}}>
                                    <strong className="fs-12 cg-7">{this.props.label}</strong>
                                </div>

                                <div style={{width:100, flexShrink:0, padding:10, display:'flex', borderLeft:'1px solid rgba(0,0,0,.1)', borderRightWidth:10, borderRightStyle:'solid', borderRightColor:this.props.color, alignItems:'center', justifyContent:'center', color:'rgba(0,0,0,.8)', borderRadius:'0 5px 5px 0', textAlign:'center'}}>
                                    {this.props.value}
                                </div>
                            </Button>
                        :
                            <>
                                <div className="text" style={{padding:12}}>
                                    <strong className="fs-12 cg-7">{this.props.label}</strong>
                                </div>

                                <div style={{width:100, flexShrink:0, padding:10, display:'flex', borderLeft:'1px solid rgba(0,0,0,.1)', borderRightWidth:10, borderRightStyle:'solid', borderRightColor:this.props.color, alignItems:'center', justifyContent:'center', color:'rgba(0,0,0,.8)', borderRadius:'0 5px 5px 0', textAlign:'center'}}>
                                    {this.props.value}
                                </div>
                            </>
                    }
                </div>
            </li>
        )
    }
}

export default DataRow;
