import React, { Component } from 'react';

import { Grid, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@mui/material';

import Loader from 'components/Loader';
import NoData from 'components/blocks/NoData';

import Api from 'api/Api';

import { format } from 'date-fns'

import { BarChart, PieChart } from '@mui/icons-material';
import { VictoryPie, VictoryLabel } from 'victory';

import DataRow from '../DataRow';
import Calls_drawer from 'components/blocks/drawers/Calls';

class TodaysCalls extends Component { 
    constructor(props) {
        super();
        this.state = {

            loader: false,

            calls_data: [],
            calls_data_colors: [],
            no_calls_data: false,

            color_codes: [],

            sources: [],

            total_calls: 0,

            users: [],
            agents: [],

            drawer: false,
            list_type: 'answered',
            post_params: [
                {key:'end_date', value: format(new Date(), "yyyy-MM-dd")},
                {key:'start_date', value: format(new Date(), "yyyy-MM-dd")},
                {key:'list_type', value: 'answered'},
                {key:'without_employee', value: 'true'}
            ],
        }
    }

    componentDidMount = () => {

        this.loadAgents();

        if(this.props.start_date && this.props.end_date){

            var _post_params = [
                {key:'end_date', value: format(this.props.end_date, "yyyy-MM-dd")},
                {key:'start_date', value: format(this.props.start_date, "yyyy-MM-dd")},
                {key:'list_type', value: 'answered'},
                {key:'without_employee', value: 'true'}
            ];

            this.setState({post_params: _post_params});
        }
        this.loadData();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(
            this.props.start_date !== prevProps.start_date
            ||
            this.props.end_date !== prevProps.end_date
        ){

            var _post_params = [
                {key:'end_date', value: format(this.props.end_date, "yyyy-MM-dd")},
                {key:'start_date', value: format(this.props.start_date, "yyyy-MM-dd")},
                {key:'list_type', value: 'answered'},
                {key:'without_employee', value: 'true'}
            ];

            this.setState({post_params: _post_params});

            this.loadData();
        }
    }

    values = (source) => {

        let calls_data = this.state.calls_data;

        const value = calls_data.find(node => node.c == source.key);

        if(value){
        
            return <div><strong>{value.y}</strong> <span className="ml-3">({value.z}%)</span></div>;
        }else{

            return <div style={{height:23, lineHeight:1}}><strong style={{opacity:.4, fontSize:20}}>0</strong></div>;;
        }
    }

    updateKeyValue = (post_params, key, value) => {

        const _key_index = post_params.findIndex(row => row.key === key);

        if(_key_index){

            post_params[_key_index]['value'] = value;
        }else{

            post_params.push({key:key, value: value});
        }

        return post_params;
    }

    render () {

        return (

            <>

                <Grid container spacing={0} alignItems="flex-start">

                    <Grid item xs={12} sm={5}>

                        <label className="cg-5 fs-16">Total: <strong className="fs-16 cg-7">{this.state.total_calls}</strong></label>
                        
                        <ul className="item-card-list">
                            {this.state.calls_data.map((_call, index) => {

                                return (

                                    <DataRow
                                        key={`call_${index}`}
                                        label={_call.x}
                                        color={_call.color}
                                        value={_call.y}
                                        onClickRow={(_type) => {

                                            let post_params = this.state.post_params;
                                            post_params = this.updateKeyValue(post_params, 'list_type', _type);

                                            this.setState({list_type: _type, post_params: post_params}, () => {

                                                this.setState({drawer: true})
                                            })
                                        }}
                                    />
                                )
                            })}
                        </ul>

                        <div className="mt-30">

                            {this.state.users.length > 0 &&
                            
                                <div>

                                    <label className="cg-5 fs-16">Outbound Calls by Employees</label>

                                    <TableContainer component={Paper} style={{padding:20, marginTop:10}}>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Contact</TableCell>
                                                    <TableCell align="right">Calls</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {this.state.users.map((_user, index) => {

                                                    return (
                                                        <TableRow key={`row_${index}`}>
                                                        
                                                            <TableCell>{`${_user.first_name} ${_user.last_name}`}</TableCell>
                                                            <TableCell>{_user.contact_number}</TableCell>
                                                            <TableCell align="right">{_user.total}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            }
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={1}></Grid>

                    <Grid item sm={5}>

                        <Loader loading={this.state.loader} />

                        {(!this.state.chart_loader && !this.state.no_calls_data ) &&

                            <VictoryPie
                                // width={300}
                                labels={({ datum }) => ``}
                                data={this.state.calls_data}
                                colorScale={this.state.calls_data_colors}
                                innerRadius={30}
                                labelComponent={<VictoryLabel style={[{fontSize:12}]} />}
                                animate={true}
                            />
                        }

                        {this.state.no_calls_data &&

                            <NoData icon={<PieChart style={{fontSize:240, color:'rgba(0,0,0,.04)'}} />} style={{margin:0}} size="small" message="Data not available!" hide_image={true} />
                        }
                    </Grid>
                </Grid>

                <Calls_drawer
                    account_token={this.props.account_token}
                    drawer={this.state.drawer}
                    list_type={this.state.list_type}
                    agents={this.state.agents}
                    post_params={this.state.post_params}
                    closeDrawer={() => {

                        this.setState({drawer: false})
                    }}
                />
            </>
        )
    }

    colorCode = (code) => {

        let sources = this.state.sources;

        const color_code = sources.find(source => source.key === code);

        if(color_code){
        
            return color_code.color;
        }

        return "#FFCE00";
    }

    loadAgents = () => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', this.props.account_token);

        Api.post('general/leads_init', formData, function(data){

            if(data.status){

                that.setState({
                    agents: data.users,
                });
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }

    loadData = () => {

        var that = this;

        var formData = new FormData();
        formData.append('account_token', this.props.account_token);
        formData.append('start_date', format(this.props.start_date, "yyyy-MM-dd"));
        formData.append('end_date', format(this.props.end_date, "yyyy-MM-dd"));

        this.setState({loader: true});

        Api.post('reports/calls/todays', formData, function(data){

            that.setState({loader: false});

            if(data.status){
            
                that.setState({total_calls: data.total_calls, users: data.users});

                if(data.calls_data.length <= 0){

                    that.setState({no_calls_data: true, calls_data: []});
                }else{

                    that.setState({no_calls_data: false, calls_data: data.calls_data, calls_data_colors: data.colors});
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default TodaysCalls;
