import React, { Component } from 'react';
import { Navigate } from "react-router-dom";

import Main from 'pages/sections/Main';

import LeadForm from './blocks/LeadForm';

import LayoutHelper from 'helpers/LayoutHelper';

import Api from 'api/Api';

class CreateLead extends Component { 

    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: '',

            lead_created: false,

            error_message: '',
            success_message: '',
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token && user){

            user = JSON.parse(user);

            this.setState({account_token: account_token, user: user});
        }else{

            window.location = Api.server_url + 'logout';
        }
    }

    render(){

        if(this.state.lead_created){

            return <Navigate to="/leads" />
        }
    
        return (

            <Main active_link="leads" page="create_leads" title="Create new lead" format="center" error_message={this.state.error_message} success_message={this.state.success_message}>

                <div className="center-form">

                    <LeadForm 
                        account_token={this.state.account_token}

                        onSuccess={() => {

                            window.setTimeout(() => {

                                this.setState({lead_created: true})
                            }, 2000);
                        }}

                        onCancel={() => {

                            this.setState({lead_created: true})
                        }}
                        setSuccessMessage={(message) => {

                            LayoutHelper.addSuccessMessage(this, message)
                        }}
                        
                        setErrorMessage={(message) => {

                            LayoutHelper.addErrorMessage(this, message)
                        }}
                    />
                </div>
            </Main>
        )
    }
}

export default CreateLead;