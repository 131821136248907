import React, { Component } from 'react';
import { Link, Navigate } from "react-router-dom";

import { Grid, Tabs, Tab, Box, Button, Popover } from '@mui/material';

import { ChevronLeft, ArrowDropDown, Message, Email } from '@mui/icons-material';

import LeadTasks from './blocks/LeadTasks';
import LeadConversion from './blocks/LeadConversion';

import Api from 'api/Api';

import HistoryTimeline from './blocks/HistoryTimeline';
import LeadComments from './blocks/LeadComments';

import LeadForm from './blocks/LeadForm';
import EmailForm from '../misc/EmailForm';
import MsgForm from '../misc/MsgForm';

import LeadUsers from './sections/LeadUsers';
import LeadStatus from './sections/LeadStatus';

class LeadViewBlock extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',

            lead_id: '',

            lead: false,

            back: false,

            history: [],

            tasks_options: [],

            active_tab: 'history',
            
            users: [],
            selected_user: false,
            users_popover: false,
            assign_loader: false,

            sms_templates: [],

            post_assign_status_options: [],

            status_popup: false,
            selected_status: false,

            lead_form_popup: false,
            email_form_popup: false,
            msg_form_popup: false,
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            var lead_id = this.props.lead_id;

            if(lead_id){

                this.setState({account_token: account_token});
                this.loadLead(account_token, lead_id, 'all');
            }else{

                this.setState({back: true});
            }
        }else{

            window.location = Api.server_url + 'logout';
        }
    }

    render () {

        var lead = this.state.lead;

        if(this.state.back){

            return <Navigate to="/leads" />
        }

        return (

            <>
                
                <div className="section-header">
                    <Grid container spacing={6}>

                        <Grid item xs={12} sm={4}>

                            {this.props.page && this.props.page == 'employees_leads'
                                ?
                                    <Button onClick={() => {

                                        this.props.hide()
                                    }} startIcon={<ChevronLeft />}>
                                        <span>Back</span>
                                    </Button>
                                :
                                    <Link to="/leads" className="btn link">
                                        <ChevronLeft />
                                        <span>Back to leads</span>
                                    </Link>
                            }
                            
                        </Grid>
                        <Grid item xs={12} sm={8}>

                            <div className="horizontal-tabs ml-20">
                            
                                {this.props.page && this.props.page == 'employees_leads'
                                    ?
                                        <Tabs
                                            variant="scrollable"
                                            value={this.props.active_tab}
                                            onChange={(e, value) => {

                                                this.props.updateTab(value);

                                                if(value == 'history'){

                                                    this.loadLead(this.state.account_token, this.props.lead_id, 'history');
                                                }
                                            }}
                                            aria-label="Lead view tabs"
                                        >
                                            <Tab label="History" value="history" id="vertical-tab-history" aria-controls="vertical-tabpanel-history" />
                                        </Tabs>
                                    :
                                        <Tabs
                                            variant="scrollable"
                                            value={this.props.active_tab}
                                            onChange={(e, value) => {
                                                
                                                this.props.updateTab(value);

                                                if(value == 'history'){

                                                    this.loadLead(this.state.account_token, this.props.lead_id, 'history');
                                                }
                                            }}
                                            aria-label="Lead view tabs"
                                        >
                                            <Tab label="History" value="history" id="vertical-tab-history" aria-controls="vertical-tabpanel-history" />
                                            <Tab label="Tasks" value="tasks" id="vertical-tab-tasks" aria-controls="vertical-tabpanel-tasks" />
                                            <Tab label="Assign Course" value="conversation" id="vertical-tab-conversation" aria-controls="vertical-tabpanel-conversation" />
                                            <Tab label="Comments" value="comments" id="vertical-tab-comments" aria-controls="vertical-tabpanel-comments" />
                                        </Tabs>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={4}>

                        <div className="info-card-primary">

                            <div className="info-card-body">

                                {lead

                                    ?
                                        <>

                                            <h2>{lead.first_name} {lead.last_name}</h2>
                                            <span className="fs-12 cg-9">{lead.added_on_elapsed} ({lead.added_on_formatted})</span>
                                            <ul className="info-list">

                                                <li>
                                                    <label>Phone:</label>
                                                    <span>
                                                        {lead.mobile_number != '' &&
                                                        
                                                            <Button size="small" onClick={(e) => {

                                                                this.setState({msg_form_popup: e.currentTarget})

                                                                if(this.state.sms_templates.length === 0){

                                                                    this.loadSmsTemplates()
                                                                }
                                                            }} endIcon={<Message />}>{lead.mobile_number}</Button>
                                                        }
                                                    </span>
                                                </li>
                                                <li>
                                                    <label>Email:</label>
                                                    <span>
                                                        {lead.email != '' &&
                                                        
                                                            <Button size="small" onClick={(e) => {
                                                                this.setState({email_form_popup: e.currentTarget})
                                                            }} endIcon={<Email />}>{lead.email}</Button>
                                                        }
                                                    </span>
                                                </li>
                                                <li>
                                                    <label>Address:</label>
                                                    <span>{lead.address}</span>
                                                </li>
                                            </ul>

                                            {this.props.page && this.props.page == 'employees_leads'
                                                ?
                                                    null
                                                :
                                                    <div>
                                                        <Button variant="outlined" color="secondary" size="small" onClick={(e) => {

                                                            this.setState({lead_form_popup: e.currentTarget})
                                                        }}>Update</Button>
                                                    </div>
                                            }

                                            <div className="info-body-box">

                                                <ul className="info-list">
                                                    <li>
                                                        <label>Status:</label>
                                                        <span>

                                                            {this.props.page && this.props.page == 'employees_leads'
                                                                ?
                                                                    <span className="badge">{lead.lead_status_label}</span>
                                                                :
                                                                    lead.lead_status == 'converted'
                                                                        ?
                                                                            <span className="badge success">{lead.lead_status_label}</span>
                                                                        :
                                                                            lead.lead_status == 'unassigned'
                                                                                ?
                                                                                    <Button color="primary" size="small" variant="contained" endIcon={<ArrowDropDown />} onClick={(e) => {
                                                                                        this.setState({users_popover: e.currentTarget});

                                                                                    }}>{lead.lead_status_label}</Button>
                                                                                :
                                                                                    <Button color="primary" size="small" variant="contained" endIcon={<ArrowDropDown />} onClick={(e) => {
                                                                                        this.setState({status_popup: e.currentTarget});

                                                                                    }}>{lead.lead_status_label}</Button>
                                                            }
                                                        </span>
                                                    </li>

                                                    {lead.assigned_user > 0
                                                        ?
                                                            <li>
                                                                <label>Assign To:</label>
                                                                <span>

                                                                    {this.props.user && (this.props.user.job_profile == 'super_admin' || this.props.user.job_profile == 'center_admin')
                                                                        ?
                                                                            <Button className="xs mr-3" color="secondary" onClick={(e) => {

                                                                                this.setState({users_popover: e.currentTarget});
                                                                            }}>
                                                                                Change
                                                                            </Button>
                                                                        :
                                                                            null
                                                                    }

                                                                    {lead.assigned_first_name} {lead.assigned_last_name}
                                                                </span>
                                                            </li>
                                                        :
                                                            null
                                                    }

                                                    <li>
                                                        <label>Branch:</label>
                                                        <span>{lead.exam_center_name}</span>
                                                    </li>
                                                    <li>
                                                        <label>Course:</label>
                                                        <span>{lead.course_title}</span>
                                                    </li>
                                                    <li>
                                                        <label>Lead Source:</label>
                                                        <span>{lead.lead_source_label}</span>
                                                    </li>
                                                </ul>   
                                            </div>

                                            <EmailForm
                                                email={this.state.lead ? this.state.lead.email : ''}
                                                anchorEl={this.state.email_form_popup}
                                                lead={this.state.lead}
                                                account_token={this.state.account_token}
                                                setSuccessMessage={(message) => {

                                                    this.props.success_message(message)
                                                }}
                                                onSuccess={() => {
                            
                                                    this.setState({email_form_popup: false});
                                                    this.loadLead(this.state.account_token, this.props.lead_id);
                                                }}
                                                onCancel={() => {

                                                    this.setState({email_form_popup: false})
                                                }}
                                                setErrorMessage={(message) => {
                            
                                                    this.props.error_message(message)
                                                }}
                                            />

                                            <MsgForm
                                                anchorEl={this.state.msg_form_popup}
                                                title="Send SMS"
                                                lead={this.state.lead}
                                                templates={this.state.sms_templates}
                                                account_token={this.state.account_token}
                                                setSuccessMessage={(message) => {

                                                    this.props.success_message(message)
                                                }}
                                                onSuccess={() => {
                            
                                                    this.setState({msg_form_popup: false});
                                                    this.loadLead(this.state.account_token, this.props.lead_id);
                                                }}
                                                onCancel={() => {

                                                    this.setState({msg_form_popup: false})
                                                }}
                                                setErrorMessage={(message) => {
                            
                                                    this.props.error_message(message)
                                                }}
                                                lead_id={this.props.lead_id}
                                                source="leads"
                                            />

                                            <Popover
                                                open={this.state.lead_form_popup ? true : false}
                                                anchorReference="anchorPosition"
                                                anchorPosition={{ top: 200, left: 600 }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <div className="popover-confirm" style={{width:600}}>

                                                    <div className="header">
                                                        <strong>Update Lead</strong>
                                                    </div>
                                                    <div className="content" style={{padding:20}}>

                                                        <LeadForm
                                                            lead={this.state.lead}
                                                            account_token={this.state.account_token}
                                                            setSuccessMessage={(message) => {

                                                                this.props.success_message(message)
                                                            }}
                                                            onSuccess={() => {
                                        
                                                                this.setState({lead_form_popup: false});
                                                                this.loadLead(this.state.account_token, this.props.lead_id);
                                                            }}
                                                            onCancel={() => {

                                                                this.setState({lead_form_popup: false})
                                                            }}
                                                            setErrorMessage={(message) => {
                                        
                                                                this.props.error_message(message)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>

                                            {this.state.status_popup &&
                                            
                                                <LeadStatus
                                                    anchorEl={this.state.status_popup}
                                                    close={() => {

                                                        this.setState({status_popup: false})
                                                    }}
                                                    status_options={this.state.post_assign_status_options}
                                                    lead={this.state.lead}
                                                    success_message={(message) => {

                                                        this.props.success_message(message);
                                                    }}
                                                    onSuccess={(data) => {

                                                        this.setState({lead: data.lead, history: data.lead_history});
                                                        this.setState({status_popup: false})
                                                    }}
                                                />
                                            }

                                            {this.state.users_popover &&
                                            
                                                <LeadUsers
                                                    anchorEl={this.state.users_popover}
                                                    close={() => {

                                                        this.setState({users_popover: false})
                                                    }}
                                                    lead={this.state.lead}
                                                    success_message={(message) => {

                                                        this.props.success_message(message);
                                                    }}
                                                    onSuccess={(data) => {

                                                        this.setState({lead: data.lead, history: data.lead_history});
                                                        this.setState({users_popover: false})
                                                    }}
                                                />
                                            }
                                        </>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                    
                        <div className="horizontal-tabs ml-20">
                            <div className="horizontal-tabs-content">
                                
                                <div
                                    value="history"
                                    role="tabpanel"
                                    hidden={this.props.active_tab != 'history'}
                                    id="vertical-tabpanel-history"
                                    aria-labelledby="vertical-tab-history"
                                >
                                    <div className="tab-content">
                                        
                                        <Box component="div" whiteSpace="normal">
                                            
                                            <HistoryTimeline
                                                history={this.state.history}
                                            />
                                        </Box>
                                    </div>
                                </div>

                                <div
                                    value="conversation"
                                    role="tabpanel"
                                    hidden={this.props.active_tab != 'conversation'}
                                    id="vertical-tabpanel-conversation"
                                    aria-labelledby="vertical-tab-conversation"
                                >
                                    <div className="tab-content">
                                        
                                        {this.props.active_tab == 'conversation' &&
                                            <LeadConversion
                                                user={this.props.user}
                                                account_token={this.state.account_token}
                                                lead={this.state.lead}
                                                updateLead={(lead, leads_history) => {
                                                    this.setState({lead: lead, history: leads_history})
                                                }}
                                                success_message={(message) => {
                                                    
                                                    this.props.success_message(message);
                                                }}
                                                error_message={(message) => {
                                                    
                                                    this.props.error_message(message);
                                                }}
                                            />
                                        }
                                    </div>
                                </div>
                                
                                <div
                                    value="tasks"
                                    role="tabpanel"
                                    hidden={this.props.active_tab != 'tasks'}
                                    id="vertical-tabpanel-tasks"
                                    aria-labelledby="vertical-tab-tasks"
                                >
                                    <div className="tab-content">
                                        
                                        {this.props.active_tab == 'tasks' &&
                                            <LeadTasks
                                                account_token={this.state.account_token}
                                                lead={this.state.lead}
                                                tasks_options={this.state.tasks_options}
                                                success_message={(message) => {
                                                    
                                                    this.props.success_message(message);
                                                }}
                                                error_message={(message) => {
                                                    
                                                    this.props.error_message(message);
                                                }}
                                            />
                                        }
                                    </div>
                                </div>

                                <div
                                    value="comments"
                                    role="tabpanel"
                                    hidden={this.props.active_tab != 'comments'}
                                    id="vertical-tabpanel-comments"
                                    aria-labelledby="vertical-tab-comments"
                                >
                                    <div className="tab-content">
                                        
                                        {this.props.active_tab == 'comments' &&

                                            <LeadComments
                                                account_token={this.state.account_token}
                                                lead={this.state.lead}
                                                success_message={(message) => {
                                                    
                                                    this.props.success_message(message);
                                                }}
                                                error_message={(message) => {
                                                    
                                                    this.props.error_message(message);
                                                }}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </>
        )
    }

    loadLead = (account_token, lead_id, type) => {
    
        var formData = new FormData();

        formData.append('account_token', account_token);
        formData.append('lead_id', lead_id);
        formData.append('type', type);

        var that = this;
            
        Api.post('leads/single', formData, function(data){

            if(data.status){
            
                that.setState({
                    lead:                       data.lead,
                    history:                    data.leads_history,
                    tasks_options:              data.tasks_options,
                    post_assign_status_options: data.status_options
                });

                if(data.call_data_missing && data.call_data_missing.length > 0){

                    that.loadCallLogs(data.call_data_missing);
                }
            }else{

                if(data.code && data.code == 'no_lead'){

                    that.setState({back: true});
                }

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    loadCallLogs = (call_data_missing) => {
    
        var formData = new FormData();

        formData.append('account_token', this.state.account_token);
        formData.append('call_data_missing', call_data_missing);

        var that = this;
            
        Api.post('leads/fetch_call_log', formData, function(data){
           
            if(data.status){

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    loadSmsTemplates = () => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', this.state.account_token);

        Api.post('general/sms_templates', formData, function(data){

            if(data.status){
            
                that.setState({sms_templates: data.templates});
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }
}

export default LeadViewBlock;