import React, { Component } from 'react';

import { Navigate } from "react-router-dom";

export default class NotificationRouter extends Component { 
    constructor(props) {
        super();
        this.state = {
            redirect: false,
            module: ''
        }
    }

    componentDidMount = () => {
        
        var segement_one = this.props.segement_one;
        var segement_two = this.props.segement_two;
        var segement_three = this.props.segement_three;
        var segement_four = this.props.segement_four;

        var url = '/';

        if(segement_one){

            url += segement_one;
        }

        if(segement_two){

            url += `/${segement_two}`;
        }

        if(segement_three){

            url += `/${segement_three}`;
        }

        if(segement_four){

            url += `/${segement_four}`;
        }

        this.setState({redirect: url});
    }

    render () {
        if (this.state.redirect !== false) {
            return <Navigate to={this.state.redirect} />;
        }

        return (
            <div></div>
        );
    }
}