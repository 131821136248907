import React, { Component } from 'react';

import { useParams } from "react-router-dom";

import Main from 'pages/sections/Main';
import Api from 'api/Api';

import LeadViewBlock from './LeadViewBlock';

import LayoutHelper from 'helpers/LayoutHelper';

export function withRouter(Children){

    return(props) => {

        const params = {params: useParams()};

        return <Children {...props} params={params} />
    }
}

class LeadView extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: '',

            error_message: '',
            success_message: '',

            back: false,

            active_tab: 'history'
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token && user){

            const tab = this.props.params.params.tab;
            const lead_id = this.props.params.params.lead_id;

            if(lead_id){

                user = JSON.parse(user);

                this.setState({account_token: account_token, user: user});

                if(tab && (tab == 'history' || tab == 'tasks' || tab == 'conversation')){

                    this.setState({active_tab: tab})
                }
            }else{

                this.setState({back: true});
            }
        }else{

            window.location = Api.server_url + 'logout';
        }
    }

    render () {

        return (

            <Main page="lead_view" active_link="leads" error_message={this.state.error_message} success_message={this.state.success_message}>
                
                <LeadViewBlock
                    user={this.state.user}
                    lead_id={this.props.params.params.lead_id}
                    active_tab={this.state.active_tab}
                    updateTab={(tab) => {

                        this.setState({active_tab: tab});
                    }}
                    error_message={(message) => {

                        LayoutHelper.addErrorMessage(this, message)
                    }}
                    success_message={(message) => {

                        LayoutHelper.addSuccessMessage(this, message)
                    }}
                />
            </Main>
        )
    }
}

export default withRouter(LeadView);