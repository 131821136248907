import React, { Component } from 'react';

import { Popover, List, ListItem, ListItemText, ListItemIcon, Radio, Button } from '@mui/material';

import Api from 'api/Api';
import Loader from 'components/Loader';

class LeadStatus extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: false,

            assign_loader: false,

            selected_status: false,
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        if(account_token && user){

            this.setState({account_token: account_token});
            this.setState({user: JSON.parse(user)});
        }else{

            window.location.href = Api.server_url + 'logout';
        }
    }

    render () {

        return (

            <Popover
                onClose={() => {
                    this.props.close()
                }}
                open={this.props.anchorEl ? true : false}
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div className="popover-confirm">

                    <div className="header">
                        <strong>Lead Status</strong>
                    </div>
                    <div className="content" style={{maxHeight:250, minHeight:150}}>

                        {this.props.status_options && this.props.status_options.length > 0
                            ?
                                <List>
                                    {this.props.status_options.map((_status) => {

                                        var labelId = `status-checkbox-${_status.key}`;
                                        return (
                                            <ListItem button onClick={() => {

                                                this.setState({selected_status: _status.key})
                                            }} key={`status-${_status.key}`}>
                                                <ListItemIcon style={{minWidth:40}}>
                                                    <Radio
                                                        edge="start"
                                                        checked={this.state.selected_status == _status.key ? true : false}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={_status.value} />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            :
                                null
                        }
                    </div>

                    <div className="popover-actions">

                        <Button size="small" color="primary" onClick={() => {

                            this.props.close()
                        }}>Cancel</Button>
                        <Button type="button" variant="contained" color="secondary" size="small" onClick={() => {

                            this.updateStatus()
                        }}>Apply</Button>

                        <Loader loading={this.state.assign_loader} />
                    </div>
                </div>
            </Popover>
        )
    }

    updateStatus = () => {
    
        if(!this.state.selected_status){
            
            return false;
        }

        var formData = new FormData();

        formData.append('account_token', this.state.account_token);
        formData.append('status', this.state.selected_status);
        formData.append('lead', this.props.lead.row_id);
        
        var that = this;

        this.setState({assign_loader: true});
            
        Api.post('leads/update_status', formData, function(data){

            that.setState({assign_loader: false, selected_status: false});

            if(data.status){
            
                that.props.success_message(data.message);
                that.props.onSuccess(data);
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }else{

                    that.props.error_message(data.message);
                }
            }
        });
    }
}

export default LeadStatus;