import React, { Component } from 'react';

import { Tabs, Tab } from '@mui/material';

import CoversionOverall from './CoversionOverall';
import CoversionPeriodWise from './CoversionPeriodWise';
import CoversionEmployeeWise from './CoversionEmployeeWise';

class ConversionPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            active_sub_tab: 'conversion_overall',
        }
    }

    render () {

        return (

            <div>

                <Tabs
                    orientation="horizontal"
                    variant="scrollable"
                    value={this.state.active_sub_tab}
                    onChange={(e, value) => {
                        this.setState({active_sub_tab: value});
                    }}
                    aria-label="Reports view tabs"
                >
                    <Tab label="Overall" value="conversion_overall" id="horizontal-tab-conversion_overall" aria-controls="horizontal-tabpanel-conversion_overall" />
                    <Tab label="Period Wise" value="conversion_period_wise" id="horizontal-tab-conversion_period_wise" aria-controls="horizontal-tabpanel-conversion_period_wise" />
                    <Tab label="Employee Wise" value="conversion_employee_wise" id="horizontal-tab-conversion_employee_wise" aria-controls="horizontal-tabpanel-conversion_employee_wise" />
                </Tabs>

                <div className="horizontal-tabs">
                    <div className="horizontal-tabs-content">

                        <div
                            value="conversion_overall"
                            role="tabpanel"
                            hidden={this.state.active_sub_tab != 'conversion_overall'}
                            id="horizontal-tabpanel-conversion_overall"
                            aria-labelledby="horizontal-tab-conversion_overall"
                        >
                            <div className="tab-content">

                                {
                                    (
                                        this.props.account_token
                                        && 
                                        this.state.active_sub_tab == 'conversion_overall'
                                    ) &&

                                    <CoversionOverall
                                        account_token={this.props.account_token}
                                        start_date={this.props.start_date}
                                        end_date={this.props.end_date}
                                        updateSources={(sources) => {

                                            this.props.updateSources(sources)
                                        }}
                                    />
                                }
                            </div>
                        </div>
                        
                        <div
                            value="conversion_period_wise"
                            role="tabpanel"
                            hidden={this.state.active_sub_tab != 'conversion_period_wise'}
                            id="horizontal-tabpanel-conversion_period_wise"
                            aria-labelledby="horizontal-tab-conversion_period_wise"
                        >
                            <div className="tab-content">

                                {
                                    (
                                        this.props.account_token
                                        && 
                                        this.state.active_sub_tab == 'conversion_period_wise'
                                    ) &&

                                    <CoversionPeriodWise
                                        account_token={this.props.account_token}
                                        start_date={this.props.start_date}
                                        end_date={this.props.end_date}
                                        sources={this.props.sources}
                                    />
                                }
                            </div>
                        </div>

                        <div
                            value="conversion_employee_wise"
                            role="tabpanel"
                            hidden={this.state.active_sub_tab != 'conversion_employee_wise'}
                            id="horizontal-tabpanel-conversion_employee_wise"
                            aria-labelledby="horizontal-tab-conversion_employee_wise"
                        >
                            <div className="tab-content">

                                {
                                    (
                                        this.props.account_token
                                        && 
                                        this.state.active_sub_tab == 'conversion_employee_wise'
                                    ) &&

                                    <CoversionEmployeeWise
                                        account_token={this.props.account_token}
                                        start_date={this.props.start_date}
                                        end_date={this.props.end_date}
                                        sources={this.props.sources}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConversionPage;
