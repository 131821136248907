import React, { Component } from 'react';

import { Button } from '@mui/material';

import { Event } from '@mui/icons-material';

import { DateRangePicker, DateRange } from "materialui-daterange-picker";

import { format, subMonths } from 'date-fns'

class DateRangeComponent extends Component { 
    constructor(props) {
        super();
        this.state = {

            initialDateRange: {
                endDate: new Date(),
                startDate: subMonths(new Date(), 1)
            }
        }
    }

    render () {

        return (

            <div style={{position:'relative'}}>
            
                <Button startIcon={<Event className="cg-4" />} onClick={() => {

                    this.setState({date_range_picker: true})
                }} size="small" className="flex-start">

                    <div className="flex-start">
                        <span className="cg-8 fs-13">{format(this.props.start_date, "ccc d MMM, yy")}</span>
                        <span className="cg-5 ml-5 mr-5">-</span>
                        <span className="cg-8 fs-13">{format(this.props.end_date, "ccc d MMM, yy")}</span>
                    </div>
                </Button>

                {this.props.hide_sidebar
                    ?
                        <DateRangePicker
                            className="date-range-picker"
                            open={this.state.date_range_picker}
                            initialDateRange={this.state.initialDateRange}
                            toggle={() => {

                                this.setState({date_range_picker: !this.state.date_range_picker})
                            }}
                            onChange={(range) => {

                                var startDate = new Date(range.startDate);
                                
                                var endDate = new Date(range.endDate);
                                
                                this.setState({date_range_picker: false});

                                this.props.update(startDate, endDate);
                            }}
                            wrapperClassName={"date-range-wrapper " + (this.props.right ? ' right-aligned' : '')}
                            maxDate={new Date()}
                            definedRanges={[]}
                        />
                    :
                        <DateRangePicker
                            className="date-range-picker"
                            open={this.state.date_range_picker}
                            initialDateRange={this.state.initialDateRange}
                            toggle={() => {

                                this.setState({date_range_picker: !this.state.date_range_picker})
                            }}
                            onChange={(range) => {

                                var startDate = new Date(range.startDate);
                                
                                var endDate = new Date(range.endDate);
                                
                                this.setState({date_range_picker: false});

                                this.props.update(startDate, endDate);
                            }}
                            wrapperClassName={"date-range-wrapper " + (this.props.right ? ' right-aligned' : '')}
                            maxDate={new Date()}
                        />
                }
                
            </div>
        )
    }
}

export default DateRangeComponent;
