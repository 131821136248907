import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { Container, Grid, Card, CardHeader, CardContent, Avatar, Button, Popover, List, ListItem, ListItemText } from '@mui/material';

import { BarChart, PieChart, ArrowDropDown } from '@mui/icons-material';
import Skeleton from '@mui/material/Skeleton';

import Main from 'pages/sections/Main';

import Api from 'api/Api';

import { VictoryChart, VictoryAxis, VictoryBar, VictoryStack, VictoryPie, VictoryTooltip } from 'victory';

import LeadTaskRow from 'pages/leads/blocks/LeadTaskRow';

import NoData from 'components/blocks/NoData';
import Loader from 'components/Loader';

class Dashboard extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            tasks_loader: false,
            tasks: [],
            tasks_count: 0,

            leads_loader: false,
            leads: [],
            leads_count: 0,

            warm_leads: 0,
            converted_leads: 0,
            unassigned_leads: 0,

            warm_leads_formatted: 0,
            converted_leads_formatted: 0,
            unassigned_leads_formatted: 0,

            centre: '',
            centre_label: 'All Centres',
            centres_popup: false,

            centres: [],

            untouched_loaded: false,
            tasks_loaded: false,

            chart_loading: false,

            chart_loader: true,
            weekly_converted: [
                {x: 1, y: 0, label: ''},
                {x: 2, y: 0, label: ''},
                {x: 3, y: 0, label: ''},
                {x: 4, y: 0, label: ''}
            ],
            no_weekly_data: false,

            source_wise_data: [],
            source_wise_data_colors: [],
            no_source_wise_data: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        if(account_token && user){

            this.setState({account_token: account_token});
            this.setState({user: JSON.parse(user)});
            this.init(account_token)
        }else{

            window.location.href = Api.server_url + 'logout';
        }
    }

    renderCentreLabel = () => {

        const centre = this.state.centre;

        if(centre === ''){

            return 'All Centres'
        }

        const _centre = this.state.centres.find(row => row.id === centre);

        return _centre.name;
    }

    formatLabel = (key) => {

        let num = this.state[key];

        let num_label = '';

        if(parseInt(num) > 999){

            num = this.state[`${key}_formatted`];

            let parts = num.split(' ');
            num_label = <span className="align-center fs-18"><b>{parts[0]}</b><b className="fs-18" style={{marginLeft:2}}>{parts[1]}</b></span>
        }else{

            num_label = <span>{num}</span>
        }

        return <Avatar style={{width: 60, height: 60}}>{num_label}</Avatar>
    }

    render () {

        return (

            <Main page="dashboard" active_link="dashboard" className="login-main-page">
                
                <Container>

                    <Grid container spacing={3} style={{marginBottom:10}}>

                        <Grid item xs={12} sm={6}>

                            <h1 className="page-title" style={{margin:0, fontSize:30, color:'rgba(0,0,0,.3)'}}>DASHBOARD</h1>
                        </Grid>

                        <Grid item xs={12} sm={6}>

                            {(this.state.user && this.state.user.job_profile === 'super_admin') &&

                                <div className="align-end">
                                    <Button endIcon={<ArrowDropDown />} onClick={(e) => {

                                        this.setState({centres_popup: e.currentTarget})
                                    }} color="secondary">
                                        {this.renderCentreLabel()}
                                    </Button>
                                </div>
                            }

                            <Popover
                                open={this.state.centres_popup ? true : false}
                                anchorEl={this.state.centres_popup}
                                onClose={() => {

                                    this.setState({centres_popup: false})
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <div className="popover-confirm" style={{width:200}}>

                                    <List component="nav" dense={true}>

                                        <ListItem button size="small" color="primary" onClick={(e) => {

                                            this.setState({centres_popup: false, centre: ''}, () => {

                                                this.init(this.state.account_token)
                                            });
                                        }}>
                                            <ListItemText primary="All Centres" />
                                        </ListItem>

                                        {this.state.centres.map((_centre) => {

                                            return (
                                                <ListItem key={`centre_${_centre.id}`} button size="small" color="primary" onClick={(e) => {

                                                    this.setState({centres_popup: false, centre: _centre.id}, () => {

                                                        this.init(this.state.account_token)
                                                    });
                                                }}>
                                                    <ListItemText primary={_centre.name} />
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </div>
                            </Popover>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} style={{marginBottom:15}}>

                        <Grid item xs={12} sm={3}>

                            <div className="chart-block">

                                <b className="chart-block-heading">&nbsp;</b>

                                <Link to="/leads/open" className="stats-card">
                                    <div>
                                        {this.formatLabel('warm_leads')}
                                    </div>
                                    <div className="stats-data">
                                        <h4>Total open</h4>
                                        <h5>Leads</h5>
                                    </div>
                                </Link>

                                <Link to="/leads/converted" className="stats-card" style={{marginTop:20}}>
                                    <div>
                                        {this.formatLabel('converted_leads')}
                                    </div>
                                    <div className="stats-data">
                                        <h4>Converted</h4>
                                        <h5>Leads</h5>
                                    </div>
                                </Link>

                                {(this.state.user && (this.state.user.job_profile == 'super_admin'))
                                    ?

                                        <Link to="/leads/unassigned" className="stats-card" style={{marginTop:20}}>
                                            <div>
                                                {this.formatLabel('unassigned_leads')}
                                            </div>
                                            <div className="stats-data">
                                                <h4>Unassigned</h4>
                                                <h5>Leads</h5>
                                            </div>
                                        </Link>
                                    :
                                        null
                                }
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={9}>

                            <Grid container spacing={3} style={{marginBottom:20}}>

                                <Grid item xs={12} sm={6}>

                                    <div className="chart-block">

                                        <b className="chart-block-heading">Leads Converted <span>(Past 4 weeks)</span></b>

                                        <div className="chart-container">
                                        
                                            <Loader loading={this.state.tasks_loader} />

                                            {(!this.state.chart_loader && !this.state.no_weekly_data ) &&
                                            
                                                <VictoryChart
                                                    domainPadding={20}
                                                >
                                                    <VictoryAxis
                                                        tickValues={[1, 2, 3, 4]}
                                                        tickFormat={["Week 1", "Week 2", "Week 3", "Week 4"]}
                                                    />
                                                    <VictoryAxis
                                                        dependentAxis
                                                    />
                                                    <VictoryStack
                                                        colorScale={"warm"}
                                                    >
                                                        <VictoryBar
                                                            alignment="middle"
                                                            height={250}
                                                            barRatio={0.8}
                                                            barWidth={35}
                                                            style={{ data: { fill: "#ef466c" } }}
                                                            data={this.state.weekly_converted}
                                                            labelComponent={<VictoryTooltip flyoutPadding={10} flyoutStyle={{fill:'rgb(0, 0, 0, .1)', color:'#fff', stroke: 'rgb(0, 0, 0, .3)'}} />}
                                                            animate={{
                                                                duration: 2000,
                                                                onLoad: { duration: 1000 }
                                                            }}
                                                            events={[{
                                                                eventHandlers: {
                                                                    onMouseOver: () => {
                                                                        return [{
                                                                            target: "data",
                                                                            mutation: (props) => {
                                                                                return {
                                                                                    style: Object.assign({}, props.style, {fill: "rgb(255, 215, 0)"})
                                                                                };
                                                                            }
                                                                        },{
                                                                            target: "labels",
                                                                            mutation: () => ({ active: true })
                                                                        }];
                                                                    },
                                                                    onMouseOut: () => {
                                                                        return [{
                                                                            mutation: () => {
                                                                                return null;
                                                                            }
                                                                        },{
                                                                            target: "labels",
                                                                            mutation: () => ({ active: false })
                                                                        }];
                                                                    }
                                                                }
                                                            }]}
                                                        />
                                                    </VictoryStack>
                                                </VictoryChart>
                                            }

                                            {this.state.no_weekly_data &&
                                        
                                                <NoData icon={<BarChart style={{fontSize:170, color:'rgba(0,0,0,.04)'}} />} style={{margin:0}} size="small" message="Data not available!" hide_image={true} />
                                            }
                                        </div>
                                    </div>
                                    
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <div className="chart-block">

                                        <b className="chart-block-heading">Leads Sources <span>(Monthly)</span></b>

                                        <div className="chart-container">

                                            <Loader loading={this.state.tasks_loader} />

                                            {(!this.state.chart_loader && !this.state.no_source_wise_data ) &&
                                            
                                                <VictoryPie
                                                    height={280}
                                                    data={this.state.source_wise_data}
                                                    colorScale={this.state.source_wise_data_colors}
                                                    innerRadius={30}
                                                    // animate={true}
                                                />
                                            }

                                            {this.state.no_source_wise_data &&
                                        
                                                <NoData icon={<PieChart style={{fontSize:170, color:'rgba(0,0,0,.04)'}} />} style={{margin:0}} size="small" message="Data not available!" hide_image={true} />
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={6}>

                            <Card className="list-card">

                                <CardHeader
                                    title={<div><b>Upcoming Tasks</b><span>({this.state.tasks_count})</span></div>}
                                />
                                <CardContent>
                                
                                    {this.cardLoader(this.state.tasks_loader)}

                                    <div className="items-list">

                                        {this.state.tasks_loaded &&
                                        
                                            <NoData size="small" message="Records not found!" />
                                        }

                                        {this.state.tasks.length > 0 && this.state.tasks.map((_task) => {

                                            return <LeadTaskRow
                                                        task={_task}
                                                        key={"lead_task_" + _task.row_id}
                                                        link={"leads/view/" + _task.lead_id + '/tasks'}
                                                    />
                                        })}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6}>

                            <Card className="list-card">

                                <CardHeader
                                    title={<div><b>New Untouched Leads</b> <span>({this.state.leads_count})</span></div>}
                                    action={<div><Link to="/leads/untouched">View All</Link></div>}
                                />
                                <CardContent>
                                
                                    {this.cardLoader(this.state.leads_loader)}

                                    <div className="items-list">

                                        {this.state.untouched_loaded &&
                                        
                                            <NoData size="small" message="Records not found!" />
                                        }

                                        {this.state.leads.length > 0 && this.state.leads.map((_lead) => {

                                            var name = ' - - ';

                                            if(_lead.first_name != ''){

                                                name = _lead.first_name + ' ' + _lead.last_name;
                                            }
                                            return (
                                                <Link key={"lead_" + _lead.row_id} className="items-list-item" to={"leads/view/" + _lead.row_id}>

                                                    <div className="items-list-content">

                                                        <div>
                                                            <div className="primary">
                                                                <span>{name}</span>
                                                                <span className="fs-12 cg-7 ml-5 fw-n">at <b>{_lead.added_on_formatted}</b></span>
                                                            </div>

                                                            <div className="secondary">
                                                                <span><b>Source:</b> <b style={{color:'#236eaf'}}>({_lead.lead_source_label})</b></span> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Main>
        )
    }

    cardLoader = (loading) => {

        if(loading){

            return ( 
                <div>
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={50} style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={50} className="mt-10" style={{borderRadius:10}} />
                </div>
            )
        }
    }

    init = (account_token) => {

        var that = this;

        var formData = new FormData();

        this.setState({tasks_loader: true, leads_loader: true});

        formData.append('account_token', account_token);

        if(this.state.centre != ''){

            formData.append('centre', this.state.centre);
        }

        Api.post('dashboard/init', formData, function(data){

            that.setState({tasks_loader: false, leads_loader: false, chart_loader: false});

            if(data.status){
            
                that.setState({
                    tasks: data.tasks,
                    tasks_count: data.tasks_count,
                    leads: data.leads,
                    leads_count: data.leads_count,
                    source_wise_data_colors: data.source_wise_data_colors,
                    centres: data.centres,
                    
                    unassigned_leads: data.unassigned_leads,
                    warm_leads: data.warm_leads,
                    converted_leads: data.converted_leads,

                    unassigned_leads_formatted: data.unassigned_leads_formatted,
                    warm_leads_formatted: data.warm_leads_formatted,
                    converted_leads_formatted: data.converted_leads_formatted,
                });

                if(data.source_wise_data.length <= 0){

                    that.setState({no_source_wise_data: true});
                }else{

                    that.setState({no_source_wise_data: false, source_wise_data: data.source_wise_data});
                }

                if(data.weekly_converted.length <= 0){

                    that.setState({no_weekly_data: true});
                }else{

                    that.setState({no_weekly_data: false, weekly_converted: data.weekly_converted});
                }

                if(data.leads.length <= 0){

                    that.setState({untouched_loaded: true});
                }else{

                    that.setState({untouched_loaded: false});
                }

                if(data.tasks_count == 0){

                    that.setState({tasks_loaded: true});
                }else{

                    that.setState({tasks_loaded: false});
                }

            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }
}

export default Dashboard;