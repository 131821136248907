import React, { Component } from 'react';

import CloseRateEmployeeWise from './CloseRateEmployeeWise';

class AcquisitonPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            active_sub_tab: 'acquisition_overall',

            sources: []
        }
    }

    render () {

        return (

            <div>

                <div className="horizontal-tabs">
                    <div className="horizontal-tabs-content">

                        <div className="tab-content">

                            {
                                (
                                    this.props.account_token
                                ) &&
                                <CloseRateEmployeeWise
                                    account_token={this.props.account_token}
                                    start_date={this.props.start_date}
                                    end_date={this.props.end_date}
                                    updateSources={(sources) => {

                                        this.props.updateSources(sources)
                                    }}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AcquisitonPage;
