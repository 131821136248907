import React, { Component } from 'react';

import { Tabs, Tab, Grid } from '@mui/material';

import Main from 'pages/sections/Main';

import Api from 'api/Api';

import { format, subMonths } from 'date-fns'

import AcquisitonPage from './acquisition/AcquisitonPage';
import ConversionPage from './conversion/ConversionPage';
import LeadCloseRatePage from './lead_close_rate/LeadCloseRatePage';
import RevenuePage from './revenue/RevenuePage';
import TodaysCalls from './calls/TodaysCalls';
import TodaysComments from './comments/TodaysComments';
import CollectionsPage from './collections/CollectionsPage';

import DateRangeComponent from './DateRangeComponent';

class ReportsPage extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',
            error_message: '',

            active_tab: 'acquisition',

            active_sub_tab: 'acquisition_overall',

            end_date: new Date(),
            start_date: subMonths(new Date(), 1),

            sources: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
        }else{

            window.location = Api.url + 'logout';
        }
    }

    render () {

        return (

            <Main active_link="reports" error_message={this.state.error_message} page="reports">

                <div className="section-header mb-20" style={{paddingBottom:5}}>
                    <Grid container spacing={0}>

                        <Grid item xs={12} sm={2}>

                            <div className="main-title-block"><h1>Reports</h1></div>
                        </Grid>

                        <Grid item xs={12} sm={10}>

                            <DateRangeComponent
                                update={(start_date, end_date) => {

                                    this.setState({start_date: start_date, end_date: end_date});
                                }}
                                start_date={this.state.start_date}
                                end_date={this.state.end_date}
                            />
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={5}>

                    <Grid item xs={12} sm={2}>

                        <div className="vertical-tabs">

                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={this.state.active_tab}
                                onChange={(e, value) => {
                                    this.setState({active_tab: value});

                                    if(value == 'acquisition'){

                                        this.setState({active_sub_tab: 'acquisition_overall'});
                                    }

                                    if(value == 'conversion'){

                                        this.setState({active_sub_tab: 'conversion_overall'});
                                    }
                                }}
                                aria-label="Reports view tabs"
                            >
                                <Tab label="Acquisition" value="acquisition" id="vertical-tab-acquisition" aria-controls="vertical-tabpanel-acquisition" />
                                <Tab label="Conversion" value="conversion" id="vertical-tab-conversion" aria-controls="vertical-tabpanel-conversion" />
                                <Tab label="Lead Close Rate" value="lead_close_rate" id="vertical-tab-lead_close_rate" aria-controls="vertical-tabpanel-lead_close_rate" />
                                <Tab label="Revenue" value="revenue" id="vertical-tab-revenue" aria-controls="vertical-tabpanel-revenue" />
                                <Tab label="Call Details" value="todays_calls" id="vertical-tab-todays_calls" aria-controls="vertical-tabpanel-todays_calls" />
                                <Tab label="Today's Comments" value="todays_comments" id="vertical-tab-todays_comments" aria-controls="vertical-tabpanel-todays_comments" />
                                <Tab label="Collections" value="collections" id="vertical-tab-collections" aria-controls="vertical-tabpanel-collections" />
                            </Tabs>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={10}>

                        <div className="vertical-tabs">
                            <div className="vertical-tabs-content">

                                <div
                                    value="acquisition"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'acquisition'}
                                    id="vertical-tabpanel-acquisition"
                                    aria-labelledby="vertical-tab-acquisition"
                                >

                                    {this.state.active_tab == 'acquisition' &&
                                    
                                        <AcquisitonPage
                                            account_token={this.state.account_token}
                                            start_date={this.state.start_date}
                                            end_date={this.state.end_date}
                                            updateSources={(sources) => {

                                                this.setState({sources: sources})
                                            }}
                                            sources={this.state.sources}
                                        />
                                    }
                                </div>

                                <div
                                    value="conversion"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'conversion'}
                                    id="vertical-tabpanel-conversion"
                                    aria-labelledby="vertical-tab-conversion"
                                >
                                    
                                    {this.state.active_tab == 'conversion' &&
                                    
                                        <ConversionPage
                                            account_token={this.state.account_token}
                                            start_date={this.state.start_date}
                                            end_date={this.state.end_date}
                                            updateSources={(sources) => {

                                                this.setState({sources: sources})
                                            }}
                                            sources={this.state.sources}
                                        />
                                    }
                                </div>
                                
                                <div
                                    value="lead_close_rate"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'lead_close_rate'}
                                    id="vertical-tabpanel-lead_close_rate"
                                    aria-labelledby="vertical-tab-lead_close_rate"
                                >
                                    
                                    {this.state.active_tab == 'lead_close_rate' &&
                                    
                                        <LeadCloseRatePage
                                            account_token={this.state.account_token}
                                            start_date={this.state.start_date}
                                            end_date={this.state.end_date}
                                            updateSources={(sources) => {

                                                this.setState({sources: sources})
                                            }}
                                            sources={this.state.sources}
                                        />
                                    }
                                </div>
                                
                                <div
                                    value="revenue"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'revenue'}
                                    id="vertical-tabpanel-revenue"
                                    aria-labelledby="vertical-tab-revenue"
                                >
                                    
                                    {this.state.active_tab == 'revenue' &&
                                    
                                        <RevenuePage
                                            account_token={this.state.account_token}
                                            start_date={this.state.start_date}
                                            end_date={this.state.end_date}
                                            updateSources={(sources) => {

                                                this.setState({sources: sources})
                                            }}
                                            sources={this.state.sources}
                                        />
                                    }
                                </div>

                                <div
                                    value="todays_calls"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'todays_calls'}
                                    id="vertical-tabpanel-todays_calls"
                                    aria-labelledby="vertical-tab-todays_calls"
                                >
                                    
                                    {this.state.active_tab == 'todays_calls' &&
                                    
                                        <TodaysCalls
                                            account_token={this.state.account_token}
                                            start_date={this.state.start_date}
                                            end_date={this.state.end_date}
                                            updateSources={(sources) => {

                                                this.setState({sources: sources})
                                            }}
                                            sources={this.state.sources}
                                        />
                                    }
                                </div>
                                
                                <div
                                    value="todays_comments"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'todays_comments'}
                                    id="vertical-tabpanel-todays_comments"
                                    aria-labelledby="vertical-tab-todays_comments"
                                >
                                    
                                    {this.state.active_tab == 'todays_comments' &&
                                    
                                        <TodaysComments
                                            account_token={this.state.account_token}
                                            start_date={this.state.start_date}
                                            end_date={this.state.end_date}
                                            updateSources={(sources) => {

                                                this.setState({sources: sources})
                                            }}
                                            sources={this.state.sources}
                                        />
                                    }
                                </div>

                                <div
                                    value="collections"
                                    role="tabpanel"
                                    hidden={this.state.active_tab != 'collections'}
                                    id="vertical-tabpanel-collections"
                                    aria-labelledby="vertical-tab-collections"
                                >
            
                                    {this.state.active_tab == 'collections' &&
                                    
                                        <CollectionsPage
                                            account_token={this.state.account_token}
                                            start_date={this.state.start_date}
                                            end_date={this.state.end_date}
                                            updateSources={(sources) => {

                                                this.setState({sources: sources})
                                            }}
                                            sources={this.state.sources}
                                            source="collections"
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Main>
        )
    }
}

export default ReportsPage;
