import React, { Component } from 'react';

import DataTable from 'components/blocks/data_table/DataTable';

import Main from 'pages/sections/Main';
import Loader from 'components/Loader';

import Api from 'api/Api';

import StudentsListFilters from './blocks/StudentsListFilters';

class StudentsList extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',
            error_message: '',

            centres: [],
            statuses: [],
            courses: [],
            batches: [],

            post_params: [],

            loading: false,

            do_reload: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        
        if(account_token){

            this.setState({account_token: account_token});
            this.init(account_token);
        }else{

            window.location = Api.url + 'logout';
        }
    }

    render () {

        return (

            <Main active_link="students" error_message={this.state.error_message} page="students" title={"Students"}>

                {this.state.account_token &&
                    <DataTable
                        index="students"
                        label="Students"

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false})
                        }}

                        afterUpperToolbar={<StudentsListFilters centres={this.state.centres} courses={this.state.courses} batches={this.state.batches} updatePostParams={(post_params) => {

                            this.setState({post_params: post_params}, () => {

                                this.setState({do_reload: true})
                            });
                        }} />}
                        
                        post_params={this.state.post_params}

                        columns={[
                            {name: 'Full Name', column: 'name', sortable: true, field: 'students.name'},
                            {name: 'Email Address', column: 'email', sortable: true},
                            {name: 'Mobile Number', column: 'contact_no', sortable: true},
                            {name: 'Centre', column: 'centre', search_type: 'match', search_input: 'dropdown', search_data: this.state.centres, renderer: (_data) => {

                                return <span className="badge bg-green">{_data.exam_center}</span>
                            }},
                            {name: 'Member Since', column: 'added_on', search_input: 'date', field: 'students.added_on', search_type: 'date', sortable: true, renderer: (_data) => {

                                return <span>{_data.added_on_formatted}</span>
                            }},
                            {name: 'Status', column: 'status', search_type: 'match', search_input: 'dropdown', search_data: this.state.statuses, renderer: (_data) => {

                                return <span className="badge bg-green">{_data.status_label}</span>
                            }},
                        ]}

                        default_sort_by="added_on"

                        api_url="students/load"

                        account_token={this.state.account_token}
                        
                        row_id="id"
                        view_url="students/view"
                    />
                }

                <Loader loading={this.state.loading} />
            </Main>
        )
    }

    init = () => {

        var formData = new FormData();
        var that = this;

        Api.post('general/students_init', formData, function(data){

            if(data.status){
            
                that.setState({
                    centres: data.centers,
                    statuses: data.statuses,
                    courses: data.courses,
                    batches: data.batches
                });
            }
        });
    }
}

export default StudentsList;
