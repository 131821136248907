import React, { Component } from 'react';

import { Box, IconButton, Table, TableRow, TableHead, TableBody, TableCell, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, FormControl, TextField, InputLabel, Select, MenuItem, Popover, Chip } from '@mui/material';

import Alert from '@mui/material/Alert';

// import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'

// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Help, ArrowDropDown } from '@mui/icons-material';

import Api from 'api/Api';
import Loader from 'components/Loader';
import NoData from 'components/blocks/NoData';
import Confirm from 'components/Confirm';

class StudentView extends Component { 
    constructor(props) {
        super();
        this.state = {

            show: true,

            loading: false,

            selected_emi_row: false,
            emi_popup: false,

            emi_amount: '',

            emi_input_error: false,
            emi_input_error_message: '',

            emi_low_message: '',

            payment_mode: '',
            payment_mode_error: false,

            payment_ref: '',
            payment_date: new Date(),

            emi_details_popup: false,

            emi_loader: false,
            emis_data: [],
            no_emis_data: false,
        }
    }

    payEMI = () => {

        var emi_amount = this.state.emi_amount;
        var payment_date = this.state.payment_date;
        var payment_mode = this.state.payment_mode;

        if(emi_amount == '' || isNaN(emi_amount) || emi_amount == 0){

            this.setState({emi_input_error: true, emi_input_error_message: 'Please enter valid amount'});
        }else{

            this.setState({emi_input_error: false, emi_input_error_message: ''});
        }

        if(payment_mode == ''){

            this.setState({payment_mode_error: true});
        }else{

            this.setState({payment_mode_error: false});
        }

        if(!isNaN(emi_amount) && emi_amount > 0 && this.state.selected_emi_row && payment_mode != ''){

            var payment_date = format(payment_date, "yyyy-MM-dd");

            var formData = new FormData();
    
            formData.append('emi_amount', emi_amount);
            formData.append('payment_date', payment_date);
            formData.append('account_token', this.props.account_token);
            formData.append('student_id', this.props.student_id);
            formData.append('emi_row', this.state.selected_emi_row.id);
            formData.append('course_id', this.props.course.id);
            formData.append('payment_ref', this.state.payment_ref);
            formData.append('payment_mode', payment_mode);

            var that = this;
            this.setState({loading: true});
            this.setState({emi_popup: false, emi_amount: '', emi_input_error: false, emi_input_error_message: '', payment_date: new Date(), payment_ref: '', payment_mode: ''})
                
            Api.post('students/add_emi', formData, function(data){
    
                that.setState({loading: false});

                if(data.status){
                
                    that.props.updateCourseInstaments(that.props.course, data.installments);
                    that.props.success_message(data.message);
                }else{
    
                    if(data.code && data.code == 'no_account'){
    
                        window.location = Api.url + 'logout';
                    }else{

                        that.props.error_message(data.message);
                    }
                }
            });
        }
    }

    course_item_row = (label, value) => {

        return (

            <TableRow>
                <TableCell component="th" style={{width:'40%'}}>{label}:</TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>
        )
    }

    render () {

        var course = this.props.course;

        if(course){
        
            return (

                <Box component="div" whiteSpace="normal">
                    <div style={{marginBottom:15, borderRadius:10}}>

                        <div onClick={() => {

                            this.setState({show: !this.state.show})
                        }} className="space-between" style={{padding:20, cursor:'pointer', backgroundColor:'rgba(0,0,0,.04)', borderRadius:'10px 10px 0 0'}}>
                            <div>
                                <h4 style={{margin:0, fontSize:12}} className="c-p">{course.title}</h4>
                                <span className="fs-12 cg-7">{course.added_on_formatted}</span>
                            </div>

                            <IconButton size="small" onClick={() => {

                                this.setState({show: !this.state.show})
                            }}>
                                <ArrowDropDown />
                            </IconButton>
                        </div>

                        {this.state.show &&
                        
                            <div style={{padding:20, backgroundColor:'rgba(255, 255, 255, 0.9)'}}>
                                <Table style={{width:'100%'}} size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{width:'20%', border:'0 none'}} component="th" align="left" className="c-p">Course:</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{border:'0 none'}}>
                                                <div className="space-between">
                                                    <span>{course.title}</span>
                                                    
                                                    {course.status === '1' &&
                                                    
                                                        <Confirm
                                                            color="primary"
                                                            size="small"
                                                            variant="contained"
                                                            onOk={() => {

                                                                this.activeCourse(course)
                                                            }}
                                                        >
                                                            Activate Course
                                                        </Confirm>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{border:'0 none'}}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" align="left" valign="top" style={{border:'0 none'}} className="c-p">Details:</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{border:'0 none'}}>
                                                <Table style={{width:'100%'}} size="small">
                                                    <TableBody>

                                                        {this.course_item_row('Batch', course.batch_title)}
                                                        {this.course_item_row('Exam Center', course.exam_center_name)}
                                                        {this.course_item_row('Fees', course.fee)}
                                                        {this.course_item_row('ACE Fee', course.ace_fee)}

                                                        {course.coupon_code != '' &&
                                                            this.course_item_row('Coupon Applied', course.coupon_code)
                                                        }

                                                        {course.discount > 0 &&

                                                            this.course_item_row('Coupon Discount', course.discount)
                                                        }

                                                        {course.ask_extra_discount == 1 &&
                                                        
                                                            this.course_item_row('Extra Discount', course.extra_discount)
                                                        }

                                                        {course.ask_extra_discount == 1 &&
                                                        
                                                            this.course_item_row('Extra Discount Status', course.extra_discount_approval == 0 ? <Chip label="Pending" size="small" /> : (course.extra_discount_approval == 1 ? <Chip label="Accepted" size="small" color="primary" /> : <Chip label="Rejected" size="small" color="secondary" />))
                                                        }

                                                        {this.course_item_row('Final Amount', course.final_amount > 0 ? course.final_amount : course.paid_fee)}

                                                        {this.course_item_row('Course Status', course.status_label)}

                                                    </TableBody>
                                                </Table>    
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{border:'0 none'}}></TableCell>
                                        </TableRow>

                                        {course.installments && course.installments.length > 0
                                            ?
                                                <>
                                                    <TableRow>
                                                        <TableCell component="th" align="left" valign="top" style={{border:'0 none'}} className="c-p">Installments:</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{border:'0 none'}}>

                                                            <Table style={{width:'100%'}} size="small">

                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="left">Date</TableCell>
                                                                        <TableCell align="right" style={{lineHeight:1}}>EMI Amount</TableCell>
                                                                        <TableCell align="left" style={{lineHeight:1}}>Paid Amount</TableCell>
                                                                        <TableCell align="left">Status</TableCell>
                                                                        <TableCell align="left"></TableCell>
                                                                        <TableCell align="left"></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>

                                                                    {course.installments.map((_installment, index) => {

                                                                        return (
                                                                            <TableRow key={"installment_" + index}>
                                                                                <TableCell width={130}>{_installment.emi_date_formatted}</TableCell>
                                                                                <TableCell width={100} align="right">{_installment.emi_amount}</TableCell>
                                                                                <TableCell width={100} align="center">{_installment.amount_paid == 0 ? '--' : _installment.amount_paid}</TableCell>
                                                                                <TableCell width={130}>
                                                                                    {_installment.installment_status_label}

                                                                                    {_installment.paid_on_date_formatted != '' &&
                                                                                        <span style={{display:'block'}} className="cg-7">{_installment.paid_on_date_formatted}</span>
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell width={30}>
                                                                                    <IconButton size="small" color="primary" onClick={(e) => {

                                                                                        this.setState({emi_details_popup: e.currentTarget});
                                                                                        this.loadEmiPayments(_installment.id);
                                                                                    }}>
                                                                                        <Help style={{color:'rgba(0,0,0,.4)'}} />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                                <TableCell width={40} align="left">
                                                                                    {_installment.payment_status != 1 &&
                                                                                        <Button onClick={() => {

                                                                                            this.setState({emi_popup: true, selected_emi_row: _installment})
                                                                                        }} size="small" color="primary">Pay</Button>
                                                                                    }
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            :
                                                null
                                        }
                                        <TableRow>
                                            <TableCell style={{border:'0 none'}}></TableCell>
                                        </TableRow>
                                        <TableRow size="small">
                                            <TableCell component="th" align="left" valign="top" style={{border:'0 none'}} className="c-p">Payment Details:</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{border:'0 none'}}>

                                                <Table style={{width:'100%'}} size="small">

                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Date</TableCell>
                                                            <TableCell align="left">Amount</TableCell>
                                                            <TableCell align="left">Amount Paid</TableCell>
                                                            <TableCell align="left">Payment Mode</TableCell>
                                                            <TableCell align="left">Payment Date</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {course.fee_details && course.fee_details.length > 0
                                                            ?

                                                                course.fee_details.map((_fee_detail, index) => {

                                                                    if(_fee_detail.payment_mode !== ''){
                                                                    
                                                                        return (
                                                                            <React.Fragment key={"fee_" + index}>
                                                                                <TableRow>
                                                                                    <TableCell>{_fee_detail.added_on_formatted}</TableCell>
                                                                                    <TableCell>{_fee_detail.amount}</TableCell>
                                                                                    <TableCell>{_fee_detail.fees_paid}</TableCell>
                                                                                    <TableCell>{_fee_detail.payment_mode}</TableCell>
                                                                                    <TableCell>{_fee_detail.payment_date_formatted}</TableCell>
                                                                                </TableRow>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                })
                                                            :
                                                                null
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align='right'>

                                                {course.status === '0' &&

                                                    <Confirm
                                                        color="secondary"
                                                        size="small"
                                                        onOk={() => {

                                                            this.cancelCourse(course)
                                                        }}
                                                    >
                                                        Cancel Course
                                                    </Confirm>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        }
                    </div>

                    <Loader loading={this.state.loading} />
                    <Popover
                        onClose={() => {
                            this.setState({emi_details_popup: false})
                        }}
                        open={this.state.emi_details_popup ? true : false}
                        anchorEl={this.state.emi_details_popup}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div className="popover-confirm large">

                            <div className="header">
                                <strong>EMI Payments Details</strong>
                            </div>
                            <div className="content" style={{minHeight:250, maxHeight:400}}>

                                <Loader loading={this.state.emi_loader} />

                                {this.state.emis_data && this.state.emis_data.length
                                    ?
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell component="th">Amount Paid</TableCell>
                                                    <TableCell component="th">Payment Mode</TableCell>
                                                    <TableCell component="th">Amount Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    this.state.emis_data.map((_emi_data, index) => {

                                                        return (
                                                            <TableRow key={"emi_row_" + index}>
                                                                <TableCell>{_emi_data.fees_paid}</TableCell>
                                                                <TableCell>{_emi_data.payment_mode_formatted}</TableCell>
                                                                <TableCell>{_emi_data.payment_date_formatted}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    :
                                        null
                                }

                                {this.state.no_emis_data &&
                                    <NoData message="EMI payments data not found!" size="small"></NoData>
                                }
                            </div>

                            <div className="popover-actions">

                                <Button size="small" color="primary" onClick={() => {

                                    this.setState({emi_details_popup: false})
                                }}>Cancel</Button>
                            </div>
                        </div>
                    </Popover>

                    <Dialog
                        open={this.state.emi_popup}
                        onClose={() => {
                            this.setState({emi_popup: false, emi_amount: '', emi_input_error: false, emi_input_error_message: '', payment_date: new Date(), payment_ref: '', payment_mode: ''})
                        }}
                        fullWidth={true}
                        maxWidth="sm"
                        scroll='paper'
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                    >
                        <DialogTitle id="form-dialog-title">Add Course Installment</DialogTitle>
                        <DialogContent>
                            <div>
                                <DialogContentText>Enter the installment amount in the below marked field</DialogContentText>

                                <Grid container spacing={1}>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={5}>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth={true}>
                                                    <div className="input-view">
                                                        <InputLabel>EMI Amount</InputLabel>
                                                        <span>{this.state.selected_emi_row.emi_amount}</span>
                                                    </div>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>

                                            <Grid item xs={12} sm={6}>
                                        
                                                <FormControl fullWidth={true} error={this.state.emi_input_error}>
                                                    <TextField
                                                        error={this.state.emi_input_error}
                                                        value={this.state.emi_amount}
                                                        label="Installment Amount *"
                                                        helperText={this.state.emi_input_error ? this.state.emi_input_error_message : 'Enter EMI Amount'}
                                                        onChange={(e) => {

                                                            if(!isNaN(e.target.value)){
                                                            
                                                                this.setState({emi_amount: e.target.value});

                                                                if(parseInt(e.target.value) > parseInt(this.state.selected_emi_row.emi_amount)){

                                                                    this.setState({emi_input_error: true, emi_input_error_message: 'You entered higher amount!', emi_low_message: ''});
                                                                }else{

                                                                    this.setState({emi_input_error: false, emi_input_error_message: ''});

                                                                    if(parseInt(e.target.value) < parseInt(this.state.selected_emi_row.emi_amount)){

                                                                        this.setState({emi_low_message: 'Partial paid EMI'});
                                                                    }else{

                                                                        this.setState({emi_low_message: ''});
                                                                    }
                                                                }
                                                            }else{

                                                                this.setState({emi_amount: ''});
                                                            }
                                                        }}
                                                        fullWidth={true}
                                                        autoComplete="off"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>

                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                <Grid item xs={12} sm={3}>
                                                        
                                                    <FormControl fullWidth={true}>
                                                        
                                                        <DatePicker
                                                            variant="inline"
                                                            autoOk
                                                            label="Date of payment *"
                                                            format="dd/MM/yyyy"
                                                            size="small"
                                                            value={this.state.payment_date}
                                                            placeholder="dd/mm/yyyy"
                                                            onChange={date => {

                                                                this.setState({payment_date: date})
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </MuiPickersUtilsProvider> */}
                                        </Grid>

                                        <Grid container spacing={3}>

                                            <Grid item xs={12} sm={6}>
                                                    
                                                <FormControl fullWidth={true} error={this.state.payment_mode_error}>
                                                            
                                                    <InputLabel id="payment_mode">Payment Mode</InputLabel>
                                                    <Select
                                                        name="payment_mode"
                                                        error={this.state.payment_mode_error}
                                                        helperText={this.state.payment_mode_error ? 'This is required' : ''}
                                                        labelId="payment_mode"
                                                        value={this.state.payment_mode}
                                                        onChange={(e) => {

                                                            this.setState({payment_mode: e.target.value})
                                                        }}
                                                    >
                                                        <MenuItem value=''>Select Payment Mode</MenuItem>
                                                            
                                                        {this.props.payment_modes && this.props.payment_modes.length && this.props.payment_modes.map((payment_mode) => {

                                                            return <MenuItem key={"payment_mode_" + payment_mode.key} value={payment_mode.key}>{payment_mode.label}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>

                                            <Grid item xs={12} sm={6}>
                                        
                                                <FormControl fullWidth={true}>
                                                    <TextField
                                                        value={this.state.payment_ref}
                                                        label="Payment Reference"
                                                        onChange={(e) => {
                                                            this.setState({payment_ref: e.target.value})
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={5}>

                                            <Grid item xs={12} sm={12}>
                                                {this.state.emi_low_message != '' &&
                                                    
                                                    <Alert severity="warning">{this.state.emi_low_message}</Alert>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button color="secondary" size="small" onClick={() => {
                                this.setState({emi_popup: false, emi_amount: '', emi_input_error: false, emi_input_error_message: '', payment_date: new Date(), payment_ref: '', payment_mode: ''})
                            }}>
                                Cancel
                            </Button>
                            <Button color="primary" size="small" onClick={() => {
                                this.payEMI()
                            }}>
                                Submit Payment
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )
        }else{

            return null;
        }
    }

    cancelCourse = (course) => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('course', course.id);

        var that = this;
        this.setState({loading: true});
            
        Api.post('students/cancel_course', formData, function(data){

            that.setState({loading: false});

            if(data.status){
            
                that.props.success_message(data.message);
                that.props.removeActive(course);
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.error_message(data.message);
                }
            }
        });
    }

    activeCourse = (course) => {

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('course_id', course.id);

        var that = this;
        this.setState({loading: true});
            
        Api.post('students/active_course', formData, function(data){

            that.setState({loading: false});

            if(data.status){
            
                that.props.success_message(data.message);
                that.props.reload();
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }else{

                    that.props.error_message(data.message);
                }
            }
        });
    }

    loadEmiPayments = (emi_row_id) => {
    
        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append('emi_row_id', emi_row_id);

        var that = this;
        this.setState({no_emis_data: false, emis_data: [], emi_loader: true});
            
        Api.post('students/emi_payments', formData, function(data){

            that.setState({emi_loader: false});

            if(data.status){
            
                if(data.emis_data.length > 0){
                    
                    that.setState({emis_data: data.emis_data});
                }else{

                    that.setState({no_emis_data: true});
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }
}

export default StudentView;