import React, { Component } from 'react';

import { Drawer, IconButton } from '@mui/material';

import { Edit } from '@mui/icons-material';

import DataTable from 'components/blocks/data_table/DataTable';
import Api from 'api/Api';

import Loader from 'components/Loader';
import LeadViewBlock from '../leads/LeadViewBlock';

class EmployeeLeads extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',

            sources: [],
            statuses: [],
            agents: [],

            sidebar: false
        }
    }

    componentDidMount = () => {

        if(this.props.account_token){

            this.init(this.props.account_token)
        }
    }

    render () {

        return (

            <div>
                {this.props.account_token &&

                    <DataTable
                        label="Leads"

                        columns={[
                            {name: 'Full Name', column: 'first_name', sortable: true, renderer: (_data) => {

                                return <span>{_data.first_name} {_data.last_name}</span>
                            }},
                            {name: 'Email', column: 'email', sortable: true},
                            {name: 'Mobile', column: 'mobile_number', sortable: true, width: 100},
                            {name: 'Source', column: 'lead_source', search_type: 'match', search_input: 'dropdown', search_data: this.state.sources, renderer: (_data) => {

                                return (
                                    <>
                                        <span className="badge bg-green">{_data.lead_source_label}</span>
                                        {_data.query_source_label != '' &&

                                            <>
                                                <br />
                                                <span className="cg-7">{_data.query_source_label}</span>
                                            </>
                                        }
                                    </>
                                )
                            }},
                            {name: 'Date', column: 'added_on', sortable: true, renderer: (_data) => {

                                return <span>{_data.added_on_formatted}</span>
                            }},
                            {name: 'Status', column: 'lead_status', search_type: 'match', search_input: 'dropdown', search_data: this.state.statuses, renderer: (_data) => {

                                return <span className="badge bg-green">{_data.lead_status_label}</span>
                            }},
                        ]}
                        default_sort_by="added_on"

                        api_url="leads/load"

                        account_token={this.props.account_token}
                        
                        row_id="row_id"
                        view_url="leads/view"

                        post_params={[{key: 'assigned_user', value: this.props.employee_id}]}

                        row_actions={(row) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">
                                    
                                        <IconButton size="small" color="primary" onClick={() => {

                                            this.setState({sidebar: true, active_lead: row})
                                        }}>
                                            <Edit />
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        }}
                    />
                }

                <Drawer anchor="right" variant="temporary" open={this.state.sidebar}>
                    <div className="drawer-container">
                        
                        <div className="drawer-body">

                            <div className="drawer-content">

                                {this.state.active_lead
                                    ?

                                        <LeadViewBlock
                                            lead_id={this.state.active_lead.row_id}
                                            page="employees_leads"
                                            active_tab="history"
                                            hide={() => {

                                                this.setState({sidebar: false, active_lead: false})
                                            }}
                                        />
                                    :
                                        null
                                }

                                <Loader loading={this.state.upading_order} />
                            </div>
                        </div>
                    </div>
                </Drawer>
            </div>
        )
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('general/leads_init', formData, function(data){

            if(data.status){
            
                that.setState({sources: data.sources, statuses: data.statuses, agents: data.agents});
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }
}

export default EmployeeLeads;