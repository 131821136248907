import React, { Component } from 'react';

import DataTable from 'components/blocks/data_table/DataTable';

import { Button, Dialog, DialogActions, DialogTitle, IconButton, Popover, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';

import { MoreVert, PostAdd, Done, CloudDownload } from '@mui/icons-material';

import Main from 'pages/sections/Main';
import Loader from 'components/Loader';

import LayoutHelper from 'helpers/LayoutHelper';
import Api from 'api/Api';

class CertificatesList extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: '',
            error_message: '',

            loading: false,

            anchorEl: false,
            active_row: '',
            confirm_dialog: false,
            certificate_row: false,
            row_id: false,
            temp_status: false,
            users: [],

            do_reload: false
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');
        
        if(account_token && user){

            this.setState({account_token: account_token});
            this.setState({user: JSON.parse(user)});
            this.init(account_token)
        }else{

            window.location = Api.url + 'logout';
        }
    }

    render () {

        return (

            <Main active_link="certificates" error_message={this.state.error_message} page="certificates" title={"Certificates"}>

                {this.state.account_token &&

                    <DataTable
                        index="certificates"
                        label="Certificates"

                        do_reload={this.state.do_reload}
                        relaodDone={() => {

                            this.setState({do_reload: false})
                        }}

                        columns={[
                            {name: 'ID', column:'id', field:'certificates.id', sortable: true},

                            {name: 'Student Name', column:'student_name', field:'students.name', sortable: true, renderer: (_data) => {

                                return <span>{_data.student_name}</span>
                            }},
                            {name: 'Phone Number', column: 'contact_no', field:'students.contact_no', sortable: true},
                            {name: 'Email', column: 'email', field:'students.email', sortable: true},
                            {name: 'Certificate No', column: 'certificate_number', field:'certificate_number', sortable: true},
                            {name: 'Course Name', column:'course', field:'courses.title', sortable: true},
                            
                            {name:'Viewed', column:'desktop_view', field:'desktop_view', sortable:true, search_type:'match', search_input:'dropdown', search_data: [{key: 0, value: 'No'}, {key: 1, value: 'Yes'}], renderer: (_data) => {

                                let html = [];

                                if(_data.desktop_viewed == 1){

                                    html.push(<span title={_data.desktop_viewed_on} className="flex"><Done className="fs-16 mr-3" /> Desktop</span>)
                                }
                                if(_data.mobile_viewed == 1){

                                    html.push(<span title={_data.mobile_viewed_on} className="flex"><Done className="fs-16 mr-3" /> Mobile</span>)
                                }
                                if(_data.is_downloaded == 1){

                                    html.push(<span title={_data.downloaded_on} className="flex"><CloudDownload className="fs-16 mr-3 op-4" /> Downloaded</span>)
                                }

                                return <div>{html}</div>
                            }},

                            {name:'Assigned By', column:'issued_by', field:'issued_by', sortable:true, search_type:'match', search_input:'dropdown', search_data:this.state.users, renderer: (_data) => {

                                if(_data.issued_by == 0){

                                    return <span>Admin</span>
                                }else{

                                    return <span>{_data.first_name} {_data.last_name}</span>
                                }
                            }},

                            {name:'Assigned Date', column:'date_of_issue', search_input: 'date_range', search_type: 'date_range', sortable: true, field:'certificates.date_of_issue', renderer: (_data) => {

                                return <span>{_data.date_of_issue_formatted}</span>
                            }},

                            {name:'Status', column:'status', sortable:true, search_type:'match', search_input:'dropdown', search_data:[{key:'0', 'value':'Published'}, {key:'1', 'value':'Deleted'}], field:'certificates.status', renderer: (_data) => {

                                return <span>{_data.status_label}</span>
                            }},
                        ]}

                        default_sort_by="date_of_issue"

                        api_url="certificates/load"

                        account_token={this.state.account_token}
                        
                        row_id="id"
                        // view_url="certificates/view"

                        row_actions={(row, row_index) => {

                            return (

                                <div className="hoverable-action">
                                    <div className="align-start">

                                        <IconButton size="small" color="primary" onClick={(e) => {

                                            this.setState({anchorEl:e.currentTarget, active_row:row_index, certificate_row:row})
                                        }}>
                                            <MoreVert />
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        }}
                    />
                }

                <Popover
                    className="button-menu"
                    open={this.state.anchorEl ? true : false}
                    anchorEl={this.state.anchorEl}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={() => {

                        this.setState({anchorEl: false, active_row: ''})
                    }}
                >
                    <div className="popover-confirm" style={{width:200}}>

                        <div className="">
                        
                            <List component="nav" dense={true}>

                                {this.state.user && (this.state.user.job_profile == 'super_admin')
                                    ?

                                        this.state.certificate_row
                                            ?

                                                <ListItem button size="small" color="primary" onClick={(e) => {

                                                    if(this.state.certificate_row.status == 0){

                                                        this.setState({confirm_dialog:true, row_id:this.state.certificate_row.id, temp_status:1});
                                                    }else{

                                                        this.setState({confirm_dialog:true, row_id:this.state.certificate_row.id, temp_status:0});
                                                    }
                                                }}>
                                                    <ListItemIcon>
                                                        <PostAdd />
                                                    </ListItemIcon>
                                                    <ListItemText primary={(this.state.certificate_row.status == 0) ? 'Delete Certificate' : 'Publish Certificate'} />
                                                </ListItem>
                                            :
                                                null
                                    :
                                        null
                                }

                            </List>
                        </div>
                    </div>
                </Popover>

                <Dialog
                    open={this.state.confirm_dialog}
                    onClose={() => {

                        this.setState({confirm_dialog: false});
                    }}
                    maxWidth='md'
                >
                    <DialogTitle>{"Are you sure?"}</DialogTitle>
                    <DialogActions>

                        <Button size='small' onClick={() => {

                            this.setState({confirm_dialog: false});
                        }}>Cancel</Button>
                        
                        <Button color="secondary" size='small' onClick={() => {
                            
                            this.setState({confirm_dialog: false});

                            this.updateStatus();
                        }} autoFocus>Yes</Button>
                    </DialogActions>
                </Dialog>

                <Loader loading={this.state.loading} />
            </Main>
        )
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('users/list_all', formData, function(data){

            if(data.status){

                var users = that.state.users;

                var _users = data.users;

                users.push({key: 0, value: 'Admin'});
                for(var r in _users){

                    users.push({key: _users[r]['id'], value: _users[r]['first_name']+' '+_users[r]['last_name']});
                }

                that.setState({users: users});
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    updateStatus = () => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', this.state.account_token);
        formData.append('certificate_id', this.state.row_id);
        formData.append('status', this.state.temp_status);

        Api.post('certificates/update_status', formData, function(data){

            if(data.status){

                that.setState({anchorEl:false, row_id:false, certificate_row:false, temp_status:false, do_reload:true});
                LayoutHelper.addSuccessMessage(that, data.message);
            }else{

                LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default CertificatesList;