import React, { Component } from 'react';

import { Grid, Button, FormControl, TextField, Select, MenuItem, InputLabel, Switch, FormControlLabel, FormHelperText } from '@mui/material';
import Alert from '@mui/material/Alert';

// import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'

// import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Loader from 'components/Loader';
import Api from 'api/Api';

import ValidatorHelper from 'helpers/ValidatorHelper';

import InstallmentRow from './InstallmentRow';

class CourseForm extends Component {

    constructor(props) {
        super();
        this.state = {

            loading: false,

            lead_sources: [],
            sources_loaded: false,
         
            courses: [],

            centers: [],
            batches: [],
            course_materials: [],

            sel_course_materials: [],

            course_data: false,
        
            loading_leads: false,

            first_name: '',
            last_name: '',
            email: '',
            city: '',
            address: '',
            mobile_number: '',

            selected_course: '',
            selected_centre: '',
            selected_batch: '',
            course_fee: '',
            ace_fees: '',
            payment_mode: '',
            payment_ref_no: '',
            payment_date: new Date(),

            extra_discount: false,
            extra_discount_amount: 0,

            coupon_code: '',
            coupon_discount: 0,

            installment_switch: false,

            installments: [],

            first_name_error: false,
            last_name_error: false,
            mobile_number_error: false,
            email_error: false,
            selected_centre_error: false,
            selected_batch_error: false,
            course_error: false,
            course_fee_error: false,
            ace_fees_error: false,

            installment_error: false,

            extra_discount_error: false,

            errors: []
        }
    }

    componentDidMount = () => {
        
        this.loadSources();

        if(this.props.lead && this.props.lead.course != '0'){
        
            this.setState({selected_course: this.props.lead.course});
        }

        if(this.props.lead){

            this.setState({
                first_name: this.props.lead.first_name,
                last_name: this.props.lead.last_name,
                email: this.props.lead.email,
                city: this.props.lead.city,
                address: this.props.lead.address,
                mobile_number: this.props.lead.mobile_number,
            })
        }
    }

    submitCourse = () => {

        if(!this.validate()){

            var formData = new FormData();

            formData.append('account_token', this.props.account_token);

            let payment_date = this.state.payment_date;

            if(payment_date){
                    
                payment_date = format(payment_date, "yyyy-MM-dd");
            }else{

                payment_date = format(new Date(), "yyyy-MM-dd");
            }

            formData.append('payment_date', payment_date);

            if(this.props.lead){
            
                formData.append('lead_id', this.props.lead.row_id);

                formData.append('first_name', this.state.first_name);
                formData.append('last_name', this.state.last_name);
                formData.append('mobile_number', this.state.mobile_number);
                formData.append('email', this.state.email);
                formData.append('address', this.state.address);
                formData.append('lead_source', this.props.lead.lead_source);
                formData.append('city', this.state.city);
            }

            if(this.props.student_id){
            
                formData.append('student_id', this.props.student_id);
            }

            formData.append('installments', JSON.stringify(this.state.installments));

            // formData.append('course_materials', JSON.stringify(sel_course_materials));

            formData.append('course', this.state.selected_course);
            formData.append('exam_centre_id', this.state.selected_centre);
            formData.append('batch_id', this.state.selected_batch);
            formData.append('course_fee', this.state.course_fee);
            formData.append('ace_fees', this.state.ace_fees);
            formData.append('payment_mode', this.state.payment_mode);
            formData.append('payment_ref_no', this.state.payment_ref_no);
            formData.append('payment_date', this.state.payment_date);

            formData.append('coupon_code', this.state.coupon_code);

            let coupon_discount = this.state.coupon_discount;

            if(coupon_discount && coupon_discount > 0){

                formData.append('coupon_discount', coupon_discount);
            }

            if(this.state.extra_discount){

                formData.append('ask_extra_discount', 1);
            }

            if(!isNaN(this.state.extra_discount_amount) && this.state.extra_discount_amount > 0){

                formData.append('extra_discount', this.state.extra_discount_amount);
            }

            this.setState({loading: true});

            var url = 'students/add_course';

            if(this.props.lead){

                url = 'leads/convert_lead';
            }

            var that = this;

            Api.post(url, formData, function(data){

                that.setState({loading: true});

                if(data.status == true){
                
                    that.props.showLeadForm(false);
                    
                    if(that.props.lead){
                    
                        that.props.updateLead(data.lead, data.lead_history);
                    }

                    that.props.success_message(data.message);
                    
                }else{

                    if(data.code && data.code == 'no_account'){

                        window.location = Api.url + 'logout';
                    }else{

                        that.props.error_message(data.message);
                    }
                }
            });
        }
    }

    calculateTotal = () => {

        var ace_fees = this.state.ace_fees;
        var _coupon_discount = this.state.coupon_discount;

        var final_fees = 0;

        if(this.state.course_data){

            final_fees = parseInt(this.state.course_data.fees);
        }

        if(ace_fees != '' && !isNaN(ace_fees)){

            final_fees = parseInt(ace_fees) + parseInt(final_fees);
        }
        
        if(!isNaN(_coupon_discount) && _coupon_discount > 0){

            if(final_fees > 0){

                final_fees = parseInt(final_fees) - parseInt(_coupon_discount);
            }
        }

        return final_fees;
    }

    render(){
    
        return (

            <div style={{width:'100%', position:'relative'}}>
                <div name="login_form" style={{width:'100%', position:'relative'}}>

                    {this.props.lead
                        ?
                            <>
                                <Grid container spacing={5}>

                                    <Grid item xs={12} sm={6}>
                                
                                        <FormControl fullWidth={true}>

                                            <TextField
                                                error={this.state.first_name_error}
                                                value={this.state.first_name}
                                                name="first_name"
                                                label="First Name *"
                                                helperText={this.state.first_name_error ? "This is required entry!" : ''}
                                                onChange={(e) => {

                                                    this.setState({first_name: e.target.value, first_name_error: false})
                                                }}
                                                fullWidth={true}
                                                autoComplete="off"
                                                variant='standard'
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth
                                                error={this.state.last_name_error}
                                                value={this.state.last_name}
                                                name="last_name"
                                                label="Last Name *"
                                                helperText={this.state.last_name_error ? "This is required entry!" : ''}
                                                onChange={(e) => {

                                                    this.setState({last_name: e.target.value, last_name_error: false})
                                                }}
                                                autoComplete="off"
                                                variant='standard'
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth
                                                error={this.state.mobile_number_error}
                                                value={this.state.mobile_number}
                                                name="mobile_number"
                                                label="Mobile Number *"
                                                helperText={this.state.mobile_number_error ? 'Please enter valid mobile number!' : ''}
                                                onChange={(e) => {

                                                    this.setState({mobile_number: e.target.value, mobile_number_error: false})
                                                }}
                                                autoComplete="off"
                                                variant='standard'
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                            
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth
                                                error={this.state.email_error}
                                                value={this.state.email}
                                                name="email"
                                                label="Email *"
                                                helperText={this.state.email_error ? 'Please enter valid email address!' : ''}
                                                onChange={(e) => {

                                                    this.setState({email: e.target.value, email_error: false})
                                                }}
                                                autoComplete="off"
                                                variant='standard'
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                            
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth
                                                value={this.state.address}
                                                name="address"
                                                label="Address"
                                                onChange={(e) => {
                                                    
                                                    this.setState({address: e.target.value})
                                                }}
                                                autoComplete="off"
                                                variant='standard'
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth
                                                value={this.state.city}
                                                name="city"
                                                label="City"
                                                onChange={(e) => {

                                                    this.setState({city: e.target.value})
                                                }}
                                                autoComplete="off"
                                                variant='standard'
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>

                                        <div className="form-sub-heading">
                                            <h3>Course Details</h3>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        :
                            null
                    }

                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12}>
                                
                            <FormControl fullWidth={true} error={this.state.course_error}>
                                        
                                <InputLabel id="course_id">Courses</InputLabel>
                                <Select
                                    name="course"
                                    labelId="course_id"
                                    value={this.state.selected_course}
                                    onChange={(e) => {

                                        this.setState({selected_course: e.target.value, course_error: false})

                                        this.loadBatches(e.target.value, false);
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value=''>Select Course</MenuItem>
                                    {this.state.courses && this.state.courses.length > 0
                                        ?
                                            this.state.courses.map((_course) => {

                                                return <MenuItem key={_course.id} value={_course.id}>{_course.title}</MenuItem>
                                            })
                                        :
                                            null
                                    }
                                </Select>

                                {this.state.course_error &&
                                
                                    <FormHelperText>This is required entry!</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                                
                            <FormControl fullWidth={true} error={this.state.selected_centre_error}>
                                        
                                <InputLabel id="exam_centre_id_field">Exam Center</InputLabel>
                                <Select
                                    name="exam_centre_id"
                                    error={this.state.selected_centre_error}
                                    labelId="exam_centre_id_field"
                                    value={this.state.selected_centre}
                                    onChange={(e) => {

                                        this.setState({selected_centre: e.target.value, selected_centre_error: false});

                                        this.loadBatches(false, e.target.value);
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value=''>Select Center</MenuItem>
                                    {this.state.centers && this.state.centers.length > 0
                                        ?
                                            this.state.centers.map((_center) => {

                                                return <MenuItem key={_center.id} value={_center.id}>{_center.name}</MenuItem>
                                            })
                                        :
                                            null
                                    }
                                </Select>

                                {this.state.selected_centre_error &&
                                
                                    <FormHelperText>This is required entry!</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                                
                            <FormControl fullWidth={true} error={this.state.selected_batch_error}>
                                        
                                <InputLabel id="batch_id">Batches</InputLabel>
                                <Select
                                    name="batch_id"
                                    error={this.state.selected_batch_error}
                                    labelId="batch_id"
                                    value={this.state.selected_batch}
                                    onChange={(e) => {

                                        this.setState({selected_batch: e.target.value, selected_batch_error: false})
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value=''>Select Batch</MenuItem>
                                    {this.state.batches && this.state.batches.length > 0
                                        ?
                                            this.state.batches.map((_batch) => {

                                                return <MenuItem key={"batch_" + _batch.id} value={_batch.id}>{_batch.batch_title}</MenuItem>
                                            })
                                        :
                                            null
                                    }
                                </Select>

                                {this.state.selected_batch_error &&
                                
                                    <FormHelperText>This is required entry!</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                                
                            <FormControl fullWidth={true}>
                                <TextField
                                    error={this.state.course_fee_error}
                                    fullWidth
                                    value={this.state.course_fee !== '' ? this.state.course_fee : ''}
                                    name="course_fee"
                                    label="Course Fee"
                                    onChange={(e) => {
                                        
                                        if(!isNaN(e.target.value)){

                                            this.setState({course_fee: e.target.value, course_fee_error: false});
                                        }
                                    }}
                                    variant='standard'
                                    autoComplete="off"
                                    helperText={this.state.course_fee_error ? 'Please enter valid fee!' : ''}
                                />
                            </FormControl>
                        </Grid>

                        {this.state.course_fee && this.state.course_fee > 0
                            ?
                                <>
                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true}>
                                            <TextField
                                                fullWidth
                                                value={this.state.coupon_code}
                                                name="coupon_code"
                                                label="Coupon Code"
                                                onChange={(e) => {
                                                    
                                                    this.setState({coupon_code: e.target.value});
                                                }}
                                                onBlur={(e) => {

                                                    this.checkDiscount(e.target.value);
                                                }}
                                                autoComplete="off"
                                                variant='standard'
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                            
                                        <FormControl fullWidth={true}>
                                            <div className="input-view">
                                                <InputLabel>Coupon Discount</InputLabel>
                                                <span>{this.state.coupon_discount}</span>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                </>
                            :
                                null
                        }

                        {this.props.user && (this.props.user.job_profile == 'sales' || this.props.user.job_profile == 'center_admin')
                            ?
                                <>

                                    <Grid item xs={12} sm={12}>

                                        <FormControlLabel
                                            control={<Switch color="primary" checked={this.state.extra_discount} onChange={(e) => {

                                                this.setState({extra_discount: e.target.checked})
                                            }} name="ask_extra_discount" />}
                                            label="Request for extra discount"
                                        />
                                    </Grid>

                                    {this.state.extra_discount &&
                                    
                                        <Grid item xs={12} sm={6}>
                                        
                                            <FormControl fullWidth={true}>
                                                <TextField
                                                    fullWidth
                                                    error={this.state.extra_discount_error}
                                                    value={this.state.extra_discount_amount}
                                                    name="extra_discount"
                                                    label="Extra Discount"
                                                    onChange={(e) => {
                                                        
                                                        if(!isNaN(e.target.value)){

                                                            this.setState({extra_discount_amount: e.target.value, extra_discount_error: false})
                                                        }
                                                    }}
                                                    variant='standard'
                                                    autoComplete="off"
                                                    helperText={this.state.extra_discount_error ? 'Please enter valid amount!' : ''}
                                                />
                                            </FormControl>
                                        </Grid>
                                    }
                                </>
                            :
                                null
                        }

                    
                        <Grid item xs={12} sm={6}>
                                
                            <FormControl fullWidth={true} error={this.state.ace_fees_error}>
                                <TextField
                                    fullWidth
                                    error={this.state.ace_fees_error}
                                    value={this.state.ace_fees}
                                    name="ace_fees"
                                    label="ACE Fees"
                                    onChange={(e) => {
                                        
                                        if(!isNaN(e.target.value)){

                                            this.setState({ace_fees: e.target.value, ace_fees_error: false});
                                        }
                                    }}
                                    autoComplete="off"
                                    helperText={this.state.ace_fees_error ? 'Please enter valid fee!' : ''}
                                    variant='standard'
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                                
                            <FormControl fullWidth={true}>
                                <div className="input-view">
                                    <InputLabel>Total</InputLabel>
                                    <span>{this.calculateTotal()}</span>
                                </div>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>

                            <FormControlLabel
                                control={<Switch
                                    checked={this.state.installment_switch}
                                    onChange={(e) => {
                                        
                                        this.setState({installment_switch: e.target.checked})
                                    }}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />}
                                label="Installments"
                            />
                        </Grid>

                        {this.state.installment_switch &&
                        
                            <Grid item xs={6} sm={12}>

                                <InstallmentRow
                                    updateInstallments={(installments) => {

                                        this.setState({installments: installments})
                                    }}
                                />

                                {this.state.installment_error &&
                                
                                    <p style={{color: '#f44336', fontSize: 12}}>All fields are required!</p>
                                }
                            </Grid>
                        }

                        <Grid item xs={12} sm={6}>
                                
                            <FormControl fullWidth={true}>
                                        
                                <InputLabel id="payment_mode">Payment Mode</InputLabel>
                                <Select
                                    name="payment_mode"
                                    labelId="payment_mode"
                                    value={this.state.payment_mode}
                                    onChange={(e) => {

                                        this.setState({payment_mode: e.target.value})
                                    }}
                                    variant='standard'
                                >
                                    <MenuItem value=''>Select Payment Mode</MenuItem>
                                        
                                    {[
                                        {key: 'cash', value: 'Cash'},
                                        {key: 'bank_transfer', value: 'Bank Transfer'},
                                        {key: 'credit_card', value: 'Credit Card'},
                                        {key: 'cheque', value: 'Cheque'},
                                        {key: 'goggle_pay', value: 'Google Pay'},
                                        {key: 'paytm', value: 'PayTM'},
                                    ].map((payment_mode) => {

                                        return <MenuItem key={"payment_mode_" + payment_mode.key} value={payment_mode.key}>{payment_mode.value}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                                
                            <FormControl fullWidth={true}>
                                <TextField
                                    fullWidth
                                    value={this.state.payment_ref_no}
                                    name="payment_ref_no"
                                    label="Payment Ref Number"
                                    onChange={(e) => {

                                        this.setState({payment_ref_no: e.target.value})
                                    }}
                                    autoComplete="off"
                                    variant='standard'
                                />
                            </FormControl>
                        </Grid>

                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <Grid item xs={12} sm={6}>

                                <FormControl fullWidth={true}>
                                    
                                    <DatePicker
                                        variant="inline"
                                        autoOk
                                        label="Date of payment *"
                                        format="dd/MM/yyyy"
                                        size="small"
                                        minDate={sub(new Date, {months: 1})}
                                        value={this.state.payment_date}
                                        placeholder="dd/mm/yyyy"
                                        onChange={(date) => {

                                            this.setState({payment_date: date})
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </MuiPickersUtilsProvider> */}

                        <Grid item xs={6} sm={12}>
                            
                            {this.formErrors()}
                        </Grid>

                        <Grid item xs={6} sm={12}>

                            <div className="action-sm">
                            
                                <Button size="small" type="button" onClick={(e) => {

                                    this.props.showLeadForm(false)
                                }}>Cancel</Button>

                                <Button color="primary" variant="contained" size="small" type="button" onClick={() => {

                                    this.submitCourse()
                                }}>Assign Course</Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <Loader loading={this.state.loading} />
            </div>
        )
    }

    checkDiscount = (code) => {

        var that = this;

        var formData = new FormData();
        var course_fees = this.state.course_fee;

        if(code && (course_fees && !isNaN(course_fees))){
        
            formData.append('coupon_code', code);
            formData.append('amount', course_fees);
            formData.append('account_token', this.props.account_token);
            
            Api.post('students/coupon_discount', formData, function(data){

                if(data.status){

                    that.setState({coupon_discount: data.discount});
                }else{

                    that.setState({coupon_discount: 0});
                    that.setState({coupon_code: ''});
                    that.props.error_message(data.message);
                }
            });
        }
    }

    formErrors = () => {

        let errors = this.state.errors;

        if(errors.length > 0){

            return (
                <Alert severity="error">
                    {errors}
                </Alert>
            )
        }
    }

    loadBatches = (_course, centre) => {

        let that = this;

        var formData = new FormData();

        var _center_id = false;
        var _course_id = false;

        if(!_course){

            if(this.state.selected_course){

                _course_id = this.state.selected_course;
            }

            _center_id = centre;
        }
        
        if(!centre){

            if(this.state.selected_centre){

                _center_id = this.state.selected_centre;
            }

            _course_id = _course;
        }

        if(_center_id && _course_id){
        
            formData.append('course', _course_id);
            formData.append('centre', _center_id);

            Api.post('students/batches', formData, function(data){

                console.log(data.course_data);

                that.setState({batches: data.batches})
                that.setState({course_materials: data.course_materials})
                that.setState({course_data: data.course_data, course_fee: data.course_data.fees})
            });
        }
    }

    loadSources = () => {
    
        let that = this;

        var formData = new FormData();
            
        Api.post('general/sources', formData, function(data){

            that.setState({sources: data.sources, courses: data.courses, centers: data.centers, sources_loaded: true})
        });
    }

    validate = () => {

        let errors = [];

        var _has_error = false;

        if(this.state.extra_discount){

            if(isNaN(this.state.extra_discount_amount)){

                this.setState({extra_discount_error: true})
                _has_error = true;
                errors.push(<span style={{display:'block'}}>Invalid extra discount amount!</span>)

            }else{

                this.setState({ace_fees_error: false});
                _has_error = (!_has_error) ? false : true;
            }
        }

        // if(this.state.ace_fees === '' || this.state.ace_fees === '0' || isNaN(this.state.ace_fees)){

        //     this.setState({ace_fees_error: true});
        //     _has_error = true;
        //     errors.push(<span style={{display:'block'}}>Invalid ace fees amount!</span>)

        // }else{

        //     this.setState({ace_fees_error: false});
        //     _has_error = (!_has_error) ? false : true;
        // }

        if(this.state.course_fee === '' || this.state.course_fee === '0' || isNaN(this.state.course_fee)){

            this.setState({course_fee_error: true});
            _has_error = true;
            errors.push(<span style={{display:'block'}}>Invalid course fees amount!</span>)

        }else{

            this.setState({course_fee_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(this.state.selected_batch === ''){

            this.setState({selected_batch_error: true});
            _has_error = true;
            errors.push(<span style={{display:'block'}}>Batch not selected!</span>)

        }else{

            this.setState({selected_batch_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(this.state.selected_course === ''){

            this.setState({course_error: true});
            _has_error = true;
            errors.push(<span style={{display:'block'}}>Course not selected!</span>)

        }else{

            this.setState({course_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(this.state.selected_centre === ''){

            this.setState({selected_centre_error: true});
            _has_error = true;
            errors.push(<span style={{display:'block'}}>Centre not selected!</span>)

        }else{

            this.setState({selected_centre_error: false});
            _has_error = (!_has_error) ? false : true;
        }

        if(this.props.lead){

            if(this.state.first_name === ''){

                this.setState({first_name_error: true});
                _has_error = true;
                errors.push(<span style={{display:'block'}}>First name is required!</span>)

            }else{

                this.setState({first_name_error: false});
                _has_error = (!_has_error) ? false : true;
            }

            if(this.state.last_name === ''){

                this.setState({last_name_error: true});
                _has_error = true;
                errors.push(<span style={{display:'block'}}>Last name is required!</span>)

            }else{

                this.setState({last_name_error: false});
                _has_error = (!_has_error) ? false : true;
            }

            if(this.state.mobile_number === '' || isNaN(this.state.mobile_number) || this.state.mobile_number.length < 10){

                this.setState({mobile_number_error: true});
                _has_error = true;
                errors.push(<span style={{display:'block'}}>Please enter valid mobile number!</span>)

            }else{

                this.setState({mobile_number_error: false});
                _has_error = (!_has_error) ? false : true;
            }

            if(this.state.email === '' || !ValidatorHelper.validEmail(this.state.email)){

                this.setState({email_error: true});
                _has_error = true;
                errors.push(<span style={{display:'block'}}>Please enter valid email!</span>)

            }else{

                this.setState({email_error: false});
                _has_error = (!_has_error) ? false : true;
            }
        }

        let installment_error = false;

        let installments = this.state.installments;
        
        if(installments.length > 0){

            installments.forEach((_row) => {

                if(_row.amount === '' || isNaN(_row.amount)){

                    installment_error = true;
                }
            })
        }

        if(installment_error){

            this.setState({installment_error: true});
            _has_error = true;
        }

        this.setState({errors: errors})

        return _has_error;
    }
}

export default CourseForm;