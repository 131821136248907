import React, { Component } from 'react';

import { Popover, Button, IconButton } from '@mui/material';

class Confirm extends Component { 
    constructor(props) {
        super();

        this.state = {
            show: false,

            element: null
        }
    }

    render () {
        
        return (

            <>
                <Popover
                    open={this.state.show}
                    anchorEl={this.state.element}
                    onClose={() => {

                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <div className="popover-confirm">

                        <div className="content" style={{padding:'10px 15px'}}>
                        
                            <strong>Alert!</strong>
                            <p className="fs-14 cg-6">{this.props.message ? this.props.message : 'Are you sure?'}</p>
                        </div>

                        <div className="popover-actions">
                            <Button size="small" color="primary" onClick={(e) => {

                                this.setState({show: false, element: null})

                                if(this.props.onOk){
                                
                                    this.props.onOk();
                                }

                                e.stopPropagation();

                            }}>Yes</Button>
                            <Button size="small" color="secondary" onClick={(e) => {

                                this.setState({show: false, element: null})

                                if(this.props.onCancel){

                                    this.props.onCancel()
                                }

                                e.stopPropagation();
                            }}>No</Button>
                        </div>
                    </div>
                </Popover>

                {this.renderButton()}
            </>
        )
    }

    renderButton = () => {

        if(this.props.type && this.props.type == 'icon_button'){

            return <IconButton onClick={(e) => {

                this.setState({element: e.currentTarget, show: true});
                e.stopPropagation();

            }} {...this.props}>{this.props.children}</IconButton>
        }

        return <Button onClick={(e) => {

            this.setState({element: e.currentTarget, show: true})

        }} {...this.props}>{this.props.children}</Button>
    }
}

export default Confirm;