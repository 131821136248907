import React, { Component } from 'react';

import { Popover, List, ListSubheader, ListItem, ListItemText, ListItemIcon, Radio, Button } from '@mui/material';

import Api from 'api/Api';
import Loader from 'components/Loader';

class LeadUsers extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: false,

            assign_loader: false,

            selected_user: false,
            users: []
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        if(account_token && user){

            this.setState({account_token: account_token});
            this.setState({user: JSON.parse(user)});
            this.loadUsers(account_token)
        }else{

            window.location.href = Api.server_url + 'logout';
        }
    }

    render () {

        return (

            <Popover
                onClose={() => {
                    this.props.close()
                }}
                open={this.props.anchorEl ? true : false}
                anchorEl={this.props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div className="popover-confirm">

                    <div className="header">
                        <strong>Team Members</strong>
                    </div>
                    <div className="content" style={{maxHeight:250, minHeight:250}}>

                        {this.state.users && this.state.users.length > 0
                            ?
                                <List subheader={<li />}>
                                    {this.state.users.map((users) => (

                                        <li key={`users-heading-${users.job_profile}`}>
                                        
                                            <ul>
                                                <ListSubheader>{users.profile_title.toUpperCase()}</ListSubheader>

                                                {users.users.map((user) => {
                                                    
                                                    var labelId = `checkbox-${users.job_profile}-${user.id}`;
                                                    return (
                                                        <ListItem button onClick={() => {

                                                            this.setState({selected_user: user.id})
                                                        }} key={`users-${users.job_profile}-${user.id}`}>
                                                            <ListItemIcon style={{minWidth:40}}>
                                                                <Radio
                                                                    edge="start"
                                                                    checked={this.state.selected_user == user.id ? true : false}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={labelId} primary={user.first_name + ' ' + user.last_name} />
                                                        </ListItem>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                    ))}
                                </List>
                            :
                                null
                        }
                    </div>

                    <div className="popover-actions">

                        <Button size="small" color="primary" onClick={() => {

                            this.props.close()
                        }}>Cancel</Button>
                        <Button type="button" variant="contained" color="secondary" size="small" onClick={() => {

                            this.assignUser()
                        }}>Apply</Button>

                        <Loader loading={this.state.assign_loader} />
                    </div>
                </div>
            </Popover>
        )
    }

    loadUsers = (account_token) => {
    
        var formData = new FormData();

        formData.append('account_token', account_token);

        var that = this;
            
        Api.post('users/list', formData, function(data){

            if(data.status){
            
                that.setState({users: data.all_users});
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }
            }
        });
    }

    assignUser = () => {

        let leads = [];
        
        var formData = new FormData();

        if(this.props.select_all){

            formData.append('select_all', Boolean(this.props.select_all));

            if(Object.keys(this.props.filters).length > 0){

                formData.append('search', JSON.stringify(this.props.filters));
            }
        }else{

            if(this.props.selected_bulk_action){

                if(this.props.selected_data.length > 0){
    
                    this.props.selected_data.forEach(_data => {
                        
                        leads.push(_data.row_id);
                    });
                }else{
    
                    return false;
                }
            }else{
    
                leads.push(this.props.lead.row_id);
            }
        }

        if(!this.state.selected_user){
            
            return false;
        }

        formData.append('account_token', this.state.account_token);
        formData.append('user', this.state.selected_user);
        formData.append('leads', leads);
        
        var that = this;

        this.setState({assign_loader: true});
            
        Api.post('leads/assign_user', formData, function(data){

            that.setState({assign_loader: false, selected_user: false});

            if(data.status){
            
                that.props.success_message(data.message);
                that.props.onSuccess(data);
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.server_url + 'logout';
                }else{

                    that.props.error_message(data.message);
                }
            }
        });
    }
}

export default LeadUsers;