import React, { Component } from 'react';

import { CircularProgress } from '@mui/material';

class Loader extends Component { 
    constructor(props) {
        super();
    }

    render () {
        
        return (

            <>
                {this.props.loading === true
                    ?
                        <div className="loader flex-center">
                            <CircularProgress size={this.props.size || 30} />
                        </div>
                    :
                        null
                }
            </>
        )
    }
}

export default Loader;