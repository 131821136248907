import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import { Paging } from 'actions/paging';

import { Table, TableBody, TableCell, TableContainer, TableSortLabel, Button, IconButton, TablePagination, TableHead, TableRow, CircularProgress, Checkbox, Menu, Popover, MenuItem, FormControlLabel, TextField } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Edit, FilterList, Clear, ExpandMore, CalendarToday, CheckBox, CheckBoxOutlineBlank, Check } from '@mui/icons-material';

import DateHelper from 'helpers/DateHelper';

import NoData from 'components/blocks/NoData';

import Api from 'api/Api';

import '../../../assets/styles/datatable.css';

class DataTable extends Component { 
    constructor(props) {
        super();

        this.state = {

            page: 0,

            loading: true,
            fadeOut: false,
            show_loader: false,

            hide_search: false,

            per_page: 10,
            total_records: 0,

            data: [],

            no_data: false,

            column_sort_by: null,
            sort_by_dir: 'desc',

            search: {},

            range_popover: false,

            range_init: false,
            range_start: false,
            range_end: false,
            range_selected_column: false,

            main_checked: false,
            select_all: false,
            checked_rows: [],
            checked_rows_data: [],

            bulk_action: false,
            selected_bulk_action: "",

            bulk_selection: false,
        }

        this.search_interval = null;
    }

    componentDidMount = () => {

        var page = this.state.page;
        var per_page = this.state.per_page;

        var paging = this.props.paging;

        if(this.props.index && paging.hasOwnProperty(this.props.index)){

            if(paging[this.props.index].hasOwnProperty('page')){

                page = paging[this.props.index]['page'];
                this.setState({page: page});
            }

            if(paging[this.props.index].hasOwnProperty('per_page')){

                per_page = paging[this.props.index]['per_page'];
                this.setState({per_page: per_page});
            }
        }

        if(this.props.default_sort_by){

            this.setState({column_sort_by: this.props.default_sort_by});

            var that = this;

            window.setTimeout(() => {
                
                that.loadData(that.state.page, that.props.account_token, false, true);
            }, 500);
            
        }else{

            this.loadData(this.state.page, this.props.account_token, false, true);
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(
            this.props.do_reload
            &&
            this.props.do_reload !== prevProps.do_reload
            &&
            this.props.do_reload === true
        ){

            this.loadData(this.state.page, this.props.account_token, false, false);
        }

        if(
            this.props.close_bulk_action
            &&
            this.props.close_bulk_action !== prevProps.close_bulk_action
            &&
            this.props.close_bulk_action === true
        ){

            this.setState({bulk_action: false, select_all: false, main_checked: false})
            this.props.onBulkActionClose();
            this.unCheckAll();
        }
    }

    componentWillUnmount = () => {

        clearTimeout(this.search_interval);
    }

    updatePage = (_props) => {

        var paging = this.props.paging;
        
        if(this.props.index){

            if(!paging.hasOwnProperty(this.props.index)){

                paging[this.props.index] = {};
            }
            
            if(_props.hasOwnProperty('page')){
            
                paging[this.props.index]['page'] = _props.page;
            }

            if(_props.hasOwnProperty('per_page')){
            
                paging[this.props.index]['per_page'] = _props.per_page;
            }
            
            this.props.Paging(paging);
        }
    }

    bulkActionTitle = () => {

        if(this.state.selected_bulk_action){

            const _action = this.props.bulk_actions.find(a => a.key === this.state.selected_bulk_action);
            
            if(_action){

                return _action.title;
            }
        }

        if(this.state.select_all){

            return <div>Bulk Actions <span className="op-7">({this.state.total_records} selected)</span></div>
        }else{
        
            return this.state.checked_rows.length > 0 ? <div>Bulk Actions <span className="op-7">({this.state.checked_rows.length} selected)</span></div> : "Bulk Actions";
        }
    }

    render () {
        
        return (

            <div className="datatable">

                {this.props.beforeUpperToolbar}

                <div className="toolbar upper">

                    {this.props.bulk_actions &&
                    
                        <>
                            <Button disabled={this.state.checked_rows_data.length > 0 ? false : true} className='mr-5' size="small" endIcon={<ExpandMore />} aria-controls="bulk-actions" aria-haspopup="true" onClick={(e) => {

                                this.setState({bulk_action: e.currentTarget})
                            }}>
                                {this.bulkActionTitle()}
                            </Button>

                            <Menu
                                id="bulk-actions"
                                anchorEl={this.state.bulk_action}
                                keepMounted
                                open={Boolean(this.state.bulk_action)}
                                onClose={() => {

                                    this.setState({bulk_action: false, main_checked: false, select_all: false})
                                    this.props.onBulkActionClose();
                                    this.unCheckAll();
                                }}
                            >
                                {this.props.bulk_actions}
                            </Menu>
                        </>
                    }

                    <Menu
                        id="bulk-selection"
                        anchorEl={this.state.bulk_selection}
                        keepMounted
                        open={Boolean(this.state.bulk_selection)}
                        onClose={() => {

                            this.setState({bulk_selection: false})
                        }}
                    >
                        <MenuItem key="select_visible" value="select_visible" style={{padding:0}}>

                            <FormControlLabel
                                style={{padding:5, width:'100%', margin:0}}
                                label="Select Visible"
                                control={
                                    <Checkbox
                                        color="primary"
                                        size="small"
                                        checked={this.state.main_checked}
                                        onChange={(e) => {
                                            if(e.target.checked){

                                                this.checkAll();
                                                this.setState({main_checked: true, select_all: false, bulk_selection: false})
                                            }else{

                                                this.setState({checked_rows: [], checked_rows_data: [], main_checked: false, bulk_selection: false});
                                            }

                                            if(this.props.onSelectAll){

                                                this.props.onSelectAll(false, {})
                                            }
                                        }}
                                    />
                                }
                            />
                        </MenuItem>
                        <MenuItem key="select_all" value="select_all" style={{padding:0}}>

                            <FormControlLabel
                                style={{padding:5, width:'100%', margin:0}}
                                label="Select All"
                                control={
                                    <Checkbox
                                        color="primary"
                                        size="small"
                                        checked={this.state.select_all}
                                        onChange={(e) => {
                                            if(e.target.checked){

                                                this.checkAll();
                                                this.setState({select_all: true, main_checked: false, bulk_selection: false});
                                                
                                                if(this.props.onSelectAll){

                                                    this.props.onSelectAll(true, this.state.search)
                                                }
                                            }else{

                                                this.setState({checked_rows: [], checked_rows_data: [], select_all: false, bulk_selection: false});
                                                
                                                if(this.props.onSelectAll){

                                                    this.props.onSelectAll(false, {})
                                                }
                                            }
                                        }}
                                    />
                                }
                            />
                        </MenuItem>
                    </Menu>

                    <div className="flex">
                        <strong>Total Records:</strong> <span className="ml-10">{this.state.total_records}</span>
                    </div>

                    <div className="toolbar-paging">

                        <TablePagination
                            style={{marginLeft:20}}
                            rowsPerPageOptions={this.props.hide_rows_per_page ? [] : [10, 20, 50, 100]}
                            component="div"
                            count={this.state.total_records}
                            rowsPerPage={parseInt(this.state.per_page)}
                            page={this.state.page}
                            onPageChange={(e, page) => {
                                this.setState({page: page});

                                this.updatePage({page: page});
                                this.loadData(page, this.props.account_token, true);
                            }}
                            onRowsPerPageChange={(e) => {

                                this.setState({per_page: e.target.value}, () => {

                                    this.setState({page: 0});

                                    this.updatePage({page: 0, per_page: e.target.value});
                                    this.loadData(0, this.props.account_token, true);
                                });
                            }}
                        />

                        <div className="search-button">
                        
                            {this.props.hide_search_bar
                                ?
                                    null
                                :
                                    <IconButton onClick={() => {
                                        this.setState({hide_search: !this.state.hide_search})
                                    }}>
                                        <FilterList />
                                    </IconButton>
                            }
                        </div>
                    </div>
                </div>

                {this.props.afterUpperToolbar}

                <TableContainer style={{position:'relative'}}>

                    <Table aria-label="simple table" className="data-table">

                        <TableHead>
                            {this.renderHeaders()}
                        </TableHead>

                        <TableBody>

                            {this.props.hide_search_bar
                                ?
                                    null
                                :
                                    this.state.hide_search
                                        ?
                                            null
                                        :
                                            this.renderSearch()
                            }
                            
                            {this.state.no_data
                                ?
                                    this.noData()
                                :
                                    <>
                                        {this.renderData()}
                                    </>
                            }
                            
                        </TableBody>
                    </Table>

                    {this.state.loading &&
                        this.renderSkeleton()
                    }

                    {this.state.show_loader &&

                        <div style={{position:'absolute', width:'100%', height:'calc(100% - 120px)', backgroundColor:'rgba(255,255,255,.1)', zIndex:999, top:120, left:0, display:'flex', alignItems:'flex-start', justifyContent:'center'}}>
                            <div style={{width:100, height:100, marginTop:30, textAlign:'center'}}>
                                <CircularProgress size="40" color="primary" />
                                <strong>Loading</strong>
                            </div>
                        </div>
                    }
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={this.props.hide_rows_per_page ? [] : [10, 20, 50, 100]}
                    component="div"
                    count={this.state.total_records}
                    rowsPerPage={parseInt(this.state.per_page)}
                    page={this.state.page}
                    onPageChange={(e, page) => {
                        this.setState({page: page});

                        this.updatePage({page: page});
                        this.loadData(page, this.props.account_token, true);
                    }}
                    onRowsPerPageChange={(e) => {

                        this.setState({per_page: e.target.value}, () => {

                            this.setState({page: 0});

                            this.updatePage({page: 0, per_page: e.target.value});
                            this.loadData(0, this.props.account_token, true);
                        });
                    }}
                />

                <Popover
                    onClose={() => {
                        this.setState({range_popover: false})
                    }}
                    open={this.state.range_popover ? true : false}
                    anchorEl={this.state.range_popover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <div className="popover-confirm" style={{width:360}}>

                        <div className="content" style={{width:340, padding:20}}>

                            {this.renderDateRangeView()}

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            
                                <DateCalendar
                                    disableToolbar
                                    variant="static"
                                    inputFormat="MM/dd/yyyy"
                                    inputVariant="standard"
                                    minDate={this.minDate()}
                                    value={this.state.range_start != false ? this.state.range_start : null}
                                    onChange={(e, date) => {

                                        if(this.state.range_init){

                                            this.setState({range_start: e, range_init: false})
                                        }else{

                                            this.setState({range_end: e, range_popover: false}, () => {

                                                this.gridSearch(this.state.range_selected_column, [this.state.range_start, this.state.range_end]);
                                            });                                            
                                        }
                                    }}
                                    autoOk={true}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }

    renderDateRangeView = () => {

        return (
            this.state.range_start != false &&
                                    
                <div className="flex-center mb-10">
                    {DateHelper.formattedDate(new Date(this.state.range_start))} -  
                    
                    {this.state.range_end != false &&
                    
                        DateHelper.formattedDate(new Date(this.state.range_end))
                    }
                </div>
        )
    }

    minDate = () => {

        if(this.state.range_init){

            let date = new Date();
            date.setFullYear( date.getFullYear() - 5 );

            return date;
        }else{

            const _date = new Date(this.state.range_start);
            return _date;
        }
    }

    noData = () => {

        var columns = this.props.columns;

        return (
            <TableRow className="data-table-row-spacer">
                <TableCell colSpan={(columns.length) + 1}>
                    <NoData size="small" message={this.props.label ? this.props.label + " not found!" : "Records not found!"} />
                </TableCell>
            </TableRow>
        )
    }

    renderHeaders = () => {

        var columns = this.props.columns;
        if(columns.length > 0){

            var cols = [];

            for(var i in columns){

                var column = columns[i];
                
                if(column && typeof column == 'object' && column.hasOwnProperty('column')){
                
                    cols.push(

                        this.renderHeaderCell(column)
                    )
                }
            }

            return (
                <TableRow className="card">
                    {cols}
                    <TableCell></TableCell>
                </TableRow>
            )
        }
    }

    renderSearch = () => {

        var columns = this.props.columns;
        if(columns.length > 0){

            var cols = [];

            for(var i in columns){

                var column = columns[i];

                if(column && typeof column == 'object' && column.hasOwnProperty('column')){

                    cols.push(
                        <TableCell key={"search_" + column.column} style={{padding:0}}>

                            {this.renderSearchDropdown(column)}
                        </TableCell>
                    )
                }
            }

            return (
                <>
                    <TableRow className="data-table-row-search">
                        {cols}
                        <TableCell style={{padding:0}}>
                            {this.clearSearch()}
                        </TableCell>
                    </TableRow>
                    <TableRow className="data-table-row-spacer">
                        <TableCell colSpan={(cols.length) + 1}></TableCell>
                    </TableRow>
                </>
            )
        }
    }

    clearSearch = () => {
        
        var search = this.state.search;
        
        if(Object.keys(search).length > 0){

            return (
                <IconButton size="small" color="secondary" style={{color:'#ff0000'}} onClick={() => {
                    this.setState({search: {}, page: 0});
                    var data_table_select = document.querySelectorAll('.data-table-input');
                    data_table_select.forEach(_select => {
                        _select.value = '';
                    });

                    let columns = this.props.columns;
                    if(columns.length > 0){

                        columns.forEach((_column) => {

                            if(_column.search_input && _column.search_input == 'date'){

                                this.setState({[_column.column]: null})
                            }

                            if(_column.search_input && _column.search_input == 'date_range'){

                                this.setState({range_start: false, range_end: false, range_selected_column: false})
                            }
                        })
                    }
                    
                    window.setTimeout(() => {
                        this.loadData(0, this.props.account_token, false, true)
                    }, 200)
                }}>
                    <Clear />
                </IconButton>
            )
        }
    }

    getData = () => {

        var data = this.state.data;

        if(this.props.data){

            data = this.props.data;
        }

        return data;
    }

    renderData = () => {

        var columns = this.props.columns;
        if(columns.length > 0){

            var data = this.getData();

            if(data && data.length > 0){

                var rows = [];

                for(var n in data){

                    var _row = data[n];

                    var cols = [];

                    columns.forEach(column => {

                        if(column && typeof column == 'object' && column.hasOwnProperty('column')){
                        
                            var _row_data = _row;
                            var row_data = _row[column.column];

                            var row_index = "data_" + "_" + n + "_" + column.column;

                            if(column.selectable){

                                var check_index = "data_" + n + "_check";
                                var _column = column.column;
                                
                                cols.push(
                                    <TableCell key={check_index} style={{padding:4}}>
                                        <Checkbox
                                            color="primary"
                                            size="small"
                                            checked={this.state.checked_rows && this.state.checked_rows.indexOf(row_data) > -1 ? true : false}
                                            onChange={(e) => {
                                                var checked_rows = this.state.checked_rows;
                                                var checked_rows_data = this.state.checked_rows_data;

                                                if(checked_rows.indexOf(row_data) > -1){

                                                    checked_rows.splice(checked_rows.indexOf(row_data), 1);
                                                    
                                                    if(checked_rows_data && checked_rows_data.length > 0){

                                                        for(var i in checked_rows_data){

                                                            if(checked_rows_data[i][_column] == row_data){

                                                                checked_rows_data.splice(checked_rows_data.indexOf(i), 1);
                                                            }
                                                        }
                                                    }
                                                }else{

                                                    checked_rows.push(row_data);
                                                    checked_rows_data.push(_row_data);
                                                }
                                                
                                                this.setState(state => ({checked_rows: checked_rows, checked_rows_data: checked_rows_data}), () => {

                                                    this.returnCheckedRows()
                                                });
                                            }}
                                        />
                                    </TableCell>
                                )
                            }else if(column.renderer){

                                if(typeof column.renderer == 'function'){

                                    cols.push(
                                        <TableCell key={row_index}>
                                            {column.renderer(_row)}
                                        </TableCell>
                                    )
                                }else{
                                    cols.push(
                                        <TableCell key={row_index}>
                                            {this.props[column.renderer](_row)}
                                        </TableCell>
                                    )
                                }
                            }else{
                            
                                cols.push(
                                    <TableCell key={row_index}>{row_data}</TableCell>
                                )
                            }
                        }
                    });

                    rows.push(
                        <React.Fragment key={"row_" + "_" + n}>
                            <TableRow className={((((this.props.active_row) == (n)) ? 'card has-hoverable-action active-row' : 'card has-hoverable-action'))}>
                                {cols}
                                <TableCell>

                                    {this.props.row_actions
                                        ?
                                            this.props.row_actions(_row, n)
                                        :
                                            <>
                                                {this.props.view_url && this.props.row_id
                                                    ?
                                                        <>
                                                            <div className="hoverable-action">
                                                                <Link to={"/" + this.props.view_url + "/" + _row[this.props.row_id]}>
                                                                    <IconButton color="primary" size="small">
                                                                        <Edit />
                                                                    </IconButton>
                                                                </Link>
                                                            </div>
                                                        </>
                                                    :
                                                        null
                                                }
                                            </>
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow className="data-table-row-spacer">
                                <TableCell colSpan={(cols.length) + 1}></TableCell>
                            </TableRow>
                        </React.Fragment>
                    )
                }

                return rows
            }
        }
    }

    renderSkeleton = () => {

        return (
            <div style={{width:'100%', minHeight:400, top:102, left:0, zIndex:999}} className={this.state.fadeOut ? 'fade-out' : null}>
                <div>
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={51} style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={51} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={51} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={51} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={51} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={51} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={51} className="mt-10" style={{borderRadius:10}} />
                    <Skeleton animation="wave" variant="rect" width={'100%'} height={51} className="mt-10" style={{borderRadius:10}} />
                </div>
            </div>
        )
    }

    loadData = (page, account_token, paging_request, loader) => {

        this.setState({loading: loader});

        if(!loader){
            this.setState({show_loader: true})
        }

        var formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('page', page);
        formData.append('per_page', this.state.per_page);

        if(this.state.column_sort_by != ''){

            formData.append('sort_by', this.state.column_sort_by);
            formData.append('sort_dir', this.state.sort_by_dir);
        }

        var search = this.state.search;
        
        if(Object.keys(search).length > 0){

            formData.append('search', JSON.stringify(this.state.search));
        }

        if(this.props.post_params && this.props.post_params.length > 0){

            var post_params = this.props.post_params;

            for(var i in post_params){

                formData.append(post_params[i]['key'], post_params[i]['value']);
            }
        }

        var that = this;

        Api.post(this.props.api_url, formData, function(data){
            
            that.setState({fadeOut: true});
            
            window.setTimeout(() => {
                that.setState({loading: false})
            }, 500);

            if(that.props.relaodDone){

                that.props.relaodDone();
            }

            that.setState({show_loader: false});

            if(data.status){
                
                if(data.records && data.records.length > 0){

                    if(that.props.updateData){

                        that.props.updateData(data)
                    }else{

                        that.setState({data: data.records});
                    }

                    that.setState({no_data: false});
                }else{

                    that.setState({no_data: true})
                }

                // if(!paging_request){

                    that.setState({total_records: data.total, per_page: data.per_page});
                // }
            }else{

                if(data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }

    renderSearchDropdown = (column) => {

        if(column.hide_search || column.selectable){

            return false;
        }

        if(column.search_input && column.search_input == 'dropdown'){

            var options = [];

            options.push(
                <option key={"search_select_empty_" + column.column} value="">Search {column.name}</option>
            )

            if(column.search_data){

                var search_data = column.search_data;
                
                for(var i in search_data){

                    options.push(
                        <option key={"search_select_" + search_data[i]['key'] + "_" + column.column} value={search_data[i]['key']}>{search_data[i]['value']}</option>
                    )
                }
            }

            return <select className="data-table-input" onChange={(event) => {
                this.gridSearch(column, event.target.value)
            }}>{options}</select>
            
        }else if(column.search_input && column.search_input == 'date'){

            return (
                <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <DatePicker
                            disableToolbar
                            variant="standard"
                            format="MM/dd/yyyy"
                            inputVariant="standard"
                            // label="Valid From Date"
                            value={this.state[column.column] ? this.state[column.column] : null}
                            onChange={(e, date) => {
                                this.setState({[column.column]: e});
                                this.gridSearch(column, e)
                            }}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </LocalizationProvider>
                </>
            )
        
        }else if(column.search_input && column.search_input == 'date_range'){

            return (
                <Button onClick={(e) => {

                    this.setState({range_popover: e.currentTarget, range_init: true, range_selected_column: column})
                }}>
                    {this.state.range_start != false
                        ?           
                            <div className="flex-center vertical" style={{width:75}}>
                                <span className="fs-11" style={{lineHeight:1.1}}>{DateHelper.formattedDate(new Date(this.state.range_start))}</span>
                                
                                {this.state.range_end != false &&
                                
                                    <span className="fs-11" style={{lineHeight:1.1}}>{DateHelper.formattedDate(new Date(this.state.range_end))}</span>
                                }
                            </div>
                        :
                            <CalendarToday className="fs-16" />
                    }
                </Button>
            )
        }else{
        
            return <input className="data-table-input" onKeyUp={(event) => {
                this.gridSearch(column, event.target.value)
            }} type="text" placeholder={"Search " + column.name} />
        }
    }

    gridSearch = (column, keyword) => {

        if(this.search_interval){
            clearTimeout(this.search_interval);
        }

        var search = this.state.search;
        
        var _column = column.field || column.column;

        if(column.table){

            _column = column.table + '.' + _column;
        }

        if(keyword == ''){

            delete search[_column];
        }else{
            
            search[_column] = {'keyword': keyword, 'type': column.search_type || 'like'};
        }
        
        this.setState({search: search, page: 0});
        
        var that = this;

        this.search_interval = setTimeout(() => {
            that.loadData(0, this.props.account_token, true, false);
        }, 500);
    }

    renderHeaderCell = (column) => {

        if(column.selectable){

            return (
                <TableCell key={"heading_check_all"} style={{padding:'8px 4px'}}>
                    <Button size="small" style={{padding:'5px 5px', minWidth:10}} onClick={(e) => {

                        if(this.state.select_all || this.state.main_checked){

                            this.setState({checked_rows: [], checked_rows_data: [], select_all: false, bulk_selection: false, main_checked: false});
                            if(this.props.onSelectAll){

                                this.props.onSelectAll(false, {})
                            }

                            if(this.props.onBulkActionClose){

                                this.props.onBulkActionClose();
                            }
                        }else{

                            this.setState({bulk_selection: e.currentTarget})
                        }
                    }}>
                        {this.state.select_all || this.state.main_checked
                            ?
                                <CheckBox className='fs-18' style={{color:'#3f51b5'}} />
                            :
                                <>
                                    <CheckBoxOutlineBlank className='fs-18 op-6' />
                                    <ExpandMore className='fs-18 op-6' />
                                </>
                        }
                    </Button>
                </TableCell>
            )
        }else if(column.sortable){

            return (
                <TableCell key={column.column}>
                    <TableSortLabel
                        active={this.state.column_sort_by == (column.field || column.column)}
                        direction={this.state.sort_by_dir}
                        onClick={(e) => {
                            this.sortColumn(column)
                        }}
                    >
                        {column.name}
                    </TableSortLabel>
                </TableCell>
            )
        }else{

            return (
                <TableCell key={column.column}>{column.name}</TableCell>
            )
        }
    }

    sortColumn = (column) => {

        var sort_dir = this.state.sort_by_dir;

        this.setState({
            column_sort_by: column.field || column.column,
            sort_by_dir: (sort_dir == 'asc' ? 'desc' : 'asc'),
            page: 0
        });

        var that = this;

        window.setTimeout(() => {
            that.loadData(that.state.page, that.props.account_token, false, false);
        }, 500);
    }

    checkAll = () => {

        var checked_rows = [];
        var checked_rows_data = [];

        var data = this.getData();
        var columns = this.props.columns;

        data.forEach(_row => {

            columns.forEach(column => {

                if(column.selectable){

                    checked_rows.push(_row[column.column]);
                    checked_rows_data.push(_row);
                }
            })
        })

        this.setState({checked_rows: checked_rows, checked_rows_data: checked_rows_data}, () => {

            this.returnCheckedRows()
        });
    }

    unCheckAll = () => {

        this.setState({checked_rows: [], checked_rows_data: [], main_checked: false}, () => {

            this.returnCheckedRows()
        });
    }

    returnCheckedRows = () => {

        if(this.props.onRowCheck){

            var checked_rows_data = this.state.checked_rows_data;
            this.props.onRowCheck(checked_rows_data);
        }
    }
}

const mapStateToProps = state => {
	return {
        paging: state.paging.paging
	}
}

export default connect(mapStateToProps, { Paging } )(DataTable);
