import React from 'react';

import './assets/styles/theme.css';

import { useIdleTimer } from 'react-idle-timer'

import { ThemeProvider, createTheme } from '@mui/material/styles'

import {BrowserRouter, Route, Routes as Switch} from 'react-router-dom';

import Api from 'api/Api';

import Signin from 'pages/auth/Signin';
import Logout from 'pages/auth/Logout';

import Redirector from 'pages/sections/Redirector';
import NotificationRouter from 'pages/NotificationRouter';

import Dashboard from 'pages/Dashboard';

import LeadsList from 'pages/leads/LeadsList';
import LeadView from 'pages/leads/LeadView';
import CreateLead from 'pages/leads/CreateLead';

import TasksList from 'pages/tasks/TasksList';

import EmployeesList from 'pages/employees/EmployeesList';
import EmployeeView from 'pages/employees/EmployeeView';

import StudentsList from 'pages/students/StudentsList';
import StudentView from 'pages/students/StudentView';

import AgentsList from 'pages/agents/AgentsList';
import CentresList from 'pages/centre/CentresList';
import InteraktTemplates from 'pages/interakt/InteraktTemplates';

import CertificatesList from 'pages/certificates/CertificatesList';

import ReportsPage from 'pages/reports/ReportsPage';

import ScrollToTop from 'components/ScrollToTop';

import WdFormRouter from 'pages/WdFormRouter';

function App() {

    const handleOnIdle = event => {
        
        window.location = Api.server_url + 'logout/1';
    }
    
    const handleOnActive = event => {

    }
    
    const handleOnAction = event => {

    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 30,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    const theme = createTheme({
        body: {
            fontSize: 12
        },
        typography: {
            fontFamily: [
                'Noto Sans JP'
            ],
            fontSize: 12,
            sizeSmall: {
                fontStyle: 'italic'
            },
        }
    })
    
    return (
        
        <ThemeProvider theme={theme}>
            
            <BrowserRouter basename={'/'}>

                <Switch>

                    {/* <ScrollToTop /> */}

                    <Route exact={true} path='/' element={<Signin />} />

                    <Route exact={true} path="/logout" element={<Logout />}>
                            
                        <Route path=':auto_logout' element={<Logout />} />
                    </Route>

                    <Route exact={true} path='/dashboard' element={<Dashboard />} />

                    <Route exact={true} path='/agents' element={<AgentsList />} />

                    <Route exact={true} path='/agents' element={<AgentsList />} />

                    <Route exact={true} path='/centres' element={<CentresList />} />

                    <Route exact={true} path='/certificates' element={<CertificatesList />} />

                    <Route exact={true} path='/employees' element={<EmployeesList />} />

                    <Route exact={true} path='/employees/view' element={<EmployeeView />}>

                        <Route path=':employee_id' element={<EmployeeView />} />
                    </Route>

                    <Route exact={true} path='/interakt' element={<InteraktTemplates />} />

                    <Route exact={true} path='/leads' element={<LeadsList />}>

                        <Route path=':status' element={<LeadsList />} />
                    </Route>

                    <Route exact={true} path='/leads/view' element={<LeadView />}>

                        <Route path=':lead_id' element={<LeadView />}>
                        
                            <Route path=':tab' element={<LeadView />} />
                        </Route>
                    </Route>

                    <Route exact={true} path='/create-lead' element={<CreateLead />} />

                    <Route exact={true} path='/tasks' element={<TasksList />} />

                    <Route exact={true} path='/students' element={<StudentsList />} />

                    <Route exact={true} path="/students/view" element={<StudentView />}>
                            
                        <Route path=':student_id' element={<StudentView />} />
                    </Route>

                    <Route exact={true} path="/reports" element={<ReportsPage />} />

                    <Route exact={true} path="/reroute" element={<Redirector />}>
                            
                        <Route path=':path' element={<Redirector />} />
                    </Route>

                    <Route exact={true} path='/notification-route' element={<NotificationRouter />}>
                        <Route path=':segement_one' element={<NotificationRouter />}>
                            <Route path=':segement_two' element={<NotificationRouter />}>
                                <Route path=':segement_three' element={<NotificationRouter />}>
                                    <Route path=':segement_four' element={<NotificationRouter />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route exact={true} path='/edit' element={<WdFormRouter />}>
                        <Route path=':main_route' element={<WdFormRouter />}>
                            <Route path=':module' element={<WdFormRouter />}>
                                <Route path=':action' element={<WdFormRouter />}>
                                    <Route path=':row_id' element={<WdFormRouter />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route exact={true} path='/edit' element={<WdFormRouter />}>
                        <Route path=':main_route' element={<WdFormRouter />}>
                            <Route path=':module' element={<WdFormRouter />}>
                                <Route path=':action' element={<WdFormRouter />}>
                                    <Route path=':sub_action' element={<WdFormRouter />}>
                                        <Route path=':row_id' element={<WdFormRouter />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                </Switch>

            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
